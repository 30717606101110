import {
  Box,
  Container,
  Link,
  List,
  ListItem,
  Typography,
} from "@mui/material";

const Privacy = () => {
  return (
    <div style={{ margin: "16px 0", marginBottom: 100 }}>
      <Container maxWidth="md">
        <Typography variant="h4" gutterBottom>
          Privacy Policy
        </Typography>

        <Box mb={4}>
          <Typography variant="h6" gutterBottom>
            Personal Information
          </Typography>
          <Typography paragraph>
            Rajdhani Retail (“RR”), the licensed owner of the Urban Bazaar
            brand, respects your privacy. This Privacy Policy outlines how your
            data is collected and used by RR on our website. By accessing and
            using our website, you agree to the collection and use of your data
            as described in this Privacy Policy.
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant="h6" gutterBottom>
            Information We Collect
          </Typography>
          <Typography paragraph>
            When you register on our website, RR may collect the following
            personally identifiable information:
          </Typography>
          <List>
            <ListItem>Name (first and last)</ListItem>
            <ListItem>Alternate email address</ListItem>
            <ListItem>Mobile phone number and contact details</ListItem>
            <ListItem>Postal code</ListItem>
            <ListItem>
              Demographic profile (age, gender, occupation, education, address,
              etc.)
            </ListItem>
            <ListItem>
              Browsing information (pages visited, links clicked, page access
              frequency)
            </ListItem>
          </List>
        </Box>

        <Box mb={4}>
          <Typography variant="h6" gutterBottom>
            How We Collect Information
          </Typography>
          <Typography paragraph>
            We collect personally identifiable information through voluntary
            registration, online surveys, or similar means. Our website may
            contain links to other websites that are not controlled by RR. We
            are not responsible for the privacy practices of these external
            sites.
          </Typography>
          <Typography paragraph>
            Our website and third-party vendors, including Google, use cookies
            (e.g., Google Analytics cookie, DoubleClick cookie) to serve ads
            based on previous visits. You can opt out of Google Analytics and
            customize ads using the Ads Preferences Manager.
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant="h6" gutterBottom>
            How We Use Your Information
          </Typography>
          <Typography paragraph>
            We use your personal information to:
          </Typography>
          <List>
            <ListItem>
              Provide personalized features and promotional offers on our
              website
            </ListItem>
            <ListItem>
              Communicate with you through our business associates and partners
            </ListItem>
            <ListItem>Maintain transaction history as required by law</ListItem>
            <ListItem>
              Improve products and services, conduct surveys, and send
              promotional materials
            </ListItem>
            <ListItem>
              Display ads through the Google Ad network and third-party websites
            </ListItem>
          </List>
          <Typography paragraph>
            Aggregate customer data may be shared with partners for developing
            features, creating merchandising, and conducting marketing research.
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant="h6" gutterBottom>
            Sharing Your Information
          </Typography>
          <Typography paragraph>
            We do not rent, sell, or share your personal information except:
          </Typography>
          <List>
            <ListItem>
              To complete transactions and provide requested services
            </ListItem>
            <ListItem>
              To our business associates and partners with your permission
            </ListItem>
            <ListItem>
              For promotional offers and investigating unlawful activities
            </ListItem>
            <ListItem>
              To comply with legal requirements or defend against claims
            </ListItem>
          </List>
        </Box>

        <Box mb={4}>
          <Typography variant="h6" gutterBottom>
            Your Choices
          </Typography>
          <Typography paragraph>
            We have implemented appropriate procedures to protect your
            information. However, Internet transmissions cannot be fully secure.
            By using our website, you agree that RR is not liable for
            unauthorized disclosure of your information.
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant="h6" gutterBottom>
            Policy Updates
          </Typography>
          <Typography paragraph>
            RR reserves the right to change or update this Privacy Policy at any
            time. Changes are effective immediately upon posting to the website.
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant="h6" gutterBottom>
            Contact Information
          </Typography>
          <Typography paragraph>
            Rajdhani Retail
            <br />
            Ground Floor, Parinay Complex
            <br />
            Manik Baug, Sinhagad Road
            <br />
            Pune 411051, INDIA
            <br />
            Tel: <Link href="tel:+918378909909">+91 8378909909</Link>
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant="h6" gutterBottom>
            Services Overview
          </Typography>
          <Typography paragraph>
            During registration, RR may collect the following information:
          </Typography>
          <List>
            <ListItem>Name</ListItem>
            <ListItem>Alternate email address</ListItem>
            <ListItem>Mobile phone number</ListItem>
          </List>
        </Box>

        <Box mb={4}>
          <Typography variant="h6" gutterBottom>
            Eligibility
          </Typography>
          <Typography paragraph>
            Services of the website are available only in select geographies in
            India. Persons who are "incompetent to contract" as per the Indian
            Contract Act, 1872, including un-discharged insolvents, are not
            eligible to use the website. Minors (under 18 years of age) may use
            the website only under the supervision of a parent or legal guardian
            who agrees to these Terms of Use. Parents or legal guardians can
            transact on behalf of minors if they are registered users. Purchase
            of adult materials by minors is prohibited.
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant="h6" gutterBottom>
            License & Website Access
          </Typography>
          <Typography paragraph>
            RR grants a limited sub-license to access and make personal use of
            this website and not to download (other than page caching) or modify
            it without express written consent. Unauthorized use terminates the
            license granted by RR.
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant="h6" gutterBottom>
            Account & Registration Obligations
          </Typography>
          <Typography paragraph>
            All shoppers must register and log in to place orders. You must keep
            your account details current. By agreeing to the terms and
            conditions, you agree to receive promotional communications. You can
            opt-out by unsubscribing in "My Account" or by contacting customer
            service.
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant="h6" gutterBottom>
            Pricing
          </Typography>
          <Typography paragraph>
            Products are sold at MRP unless otherwise specified. Prices may
            change, but no additional charges or refunds will be processed based
            on price changes at the time of delivery.
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant="h6" gutterBottom>
            Cancellation by Website / Customer
          </Typography>
          <Typography paragraph>
            Customers can cancel orders up to the cut-off time by contacting
            customer service. RR may cancel orders at its discretion, especially
            in cases of suspected fraud.
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant="h6" gutterBottom>
            Return & Refunds
          </Typography>
          <Typography paragraph>
            We offer a "no questions asked" return and refund policy, allowing
            you to return products at delivery if unsatisfied. We will issue a
            credit note for returned products.
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant="h6" gutterBottom>
            You Agree and Confirm
          </Typography>
          <Typography paragraph>
            By using our services, you agree to various terms including
            responsibility for incorrect delivery details, compliance with laws,
            provision of authentic information, and understanding of risks. You
            also agree to the conditions of sale included in the product
            description.
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant="h6" gutterBottom>
            Prohibited Uses
          </Typography>
          <Typography paragraph>
            You may not use the website for unlawful purposes, to gain
            unauthorized access, disrupt other users, or to make copies of
            copyrighted materials without permission.
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant="h6" gutterBottom>
            Modification of Terms & Conditions
          </Typography>
          <Typography paragraph>
            RR may modify the Terms & Conditions at any time without prior
            notification. You should review the Terms regularly. Continued use
            of the service implies acceptance of modified terms.
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant="h6" gutterBottom>
            Governing Law and Jurisdiction
          </Typography>
          <Typography paragraph>
            This agreement is governed by Indian laws. The courts at Pune have
            exclusive jurisdiction. Disputes will be referred to arbitration in
            Bangalore.
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant="h6" gutterBottom>
            Reviews, Feedback, Submissions
          </Typography>
          <Typography paragraph>
            Reviews, feedback, and submissions are welcomed. RR has the right to
            use them as needed. By submitting feedback, you grant RR a
            perpetual, irrevocable license to use and modify it.
          </Typography>
        </Box>
      </Container>
    </div>
  );
};

export default Privacy;
