import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ShoppingBasketOutlinedIcon from "@mui/icons-material/ShoppingBasketOutlined";
import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import OrderPlacedDone from "../../../../assets/order-placed-error.png";
import server from "../../../server";

const EditButton = { backgroundColor: "#F37A20", margin: "5px 5px" };

const OrderTitle = {
  fontSize: "14px",
  color: "#37474F",
  fontFamily: "Poppins",
  fontWeight: "600",
};

const ShippingStatusDeliver = {
  color: "#5EC401",
  fontSize: "14px",
  fontFamily: "Poppins",
  fontWeight: "500",
};

const OrderDate = {
  fontSize: "13px",
  fontWeight: "400",
  fontFamily: "Poppins",
  color: "#868889",
};

const salePriceText = {
  color: "#F37A20",
  fontWeight: "500",
  fontSize: "18px",
  fontFamily: "Poppins",
  textAlign: "end",
  right: 5,
};

const buttonProductCard = {
  color: "#fff",
  backgroundColor: "#5EC401",
  display: "flex",
  //   width: "100px",
  height: "40px",
  alignItems: "center",
  justifyContent: "center",
  // marginRight: "20px",
  padding: "8px 2px",
  textTransform: "capitalize",
  borderRadius: "7px",
  position: "relative",
  bottom: 0,
  // right: 5,
  "&.MuiButtonBase-root:hover": {
    background: "#64cf00",
  },
  maxWidth: "80px",
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    borderRadius: "18px",
  },
};

const OrderDoneText = {
  fontSize: "22px",
  fontWeight: "600",
  fontFamily: "quicksand",
  textAlign: "center",
  padding: "0px 10px",
  color: "#181725",
  margin: "10px 0px",
};

const OrderPlacedText = {
  fontSize: "14px",
  fontWeight: "600",
  fontFamily: "quicksand",
  textAlign: "center",
  padding: "0px 10px",
  color: "#7C7C7C",
  margin: "10px 0px",
};

const ButtonCart = {
  backgroundColor: "#5EC401",
  color: "#fff",
  textTransform: "none",
  padding: "12px 10px",
  fontSize: "14px",
  fontWeight: "500",
  width: "100%",
  borderRadius: "19px",
  fontFamily: "quicksand",
  "&.MuiButtonBase-root:hover": {
    background: "#64cf00",
  },
};

const ButtonCartSecond = {
  backgroundColor: "#fff",
  color: "#181725",
  textTransform: "none",
  padding: "12px 10px",
  fontSize: "14px",
  fontWeight: "600",
  width: "100%",
  borderRadius: "19px",
  fontFamily: "quicksand",
  "&.MuiButtonBase-root:hover": {
    background: "#e9e8e8",
  },
};
const OrdersCard = (props) => {
  const navigate = useNavigate();
  const [RWseries, setSeries] = useState();
  const [modalIsOpen, setIsOpen] = useState(false);

  const NotExpired = () => {
    const timeDifferenceInMillis =
      new Date() - new Date(props.orderdetails.updatedAt);
    const timeDifferenceInHours = timeDifferenceInMillis / (1000 * 60 * 60);
    // console.log(new Date()  - new Date(props.orderdetails.updatedAt))
    // console.log( new Date(props.orderdetails.updatedAt))
    // console.log(timeDifferenceInHours)
    if (timeDifferenceInHours < 168) {
      // console.log("time not expired for " + props.orderdetails._id + " with time" + timeDifferenceInHours)
      return true;
    } else return false;
  };

  const fetchSeries = async () => {
    const currentRWSeries = await axios.get(
      `${server}/admin/orders/get/series`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );

    setSeries(currentRWSeries.data.series);
  };

  useEffect(() => {
    fetchSeries();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "1px solid #E1E1E1",
        paddingBottom: "20px",
        marginRight: "2px",
        marginTop: "10px",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
        onClick={(e) => {
          e.preventDefault();
          navigate(`/vieworder/${props.orderdetails._id}`, {
            state: { value: props.value },
          });
        }}
      >
        <IconButton sx={EditButton}>
          <ShoppingBasketOutlinedIcon sx={{ color: "#fff" }} />
        </IconButton>
        {/* <Link to={`/vieworder/${props.orderdetails._id}`}
          style={{ textDecoration: "none" }}> */}
        <Box
          sx={{ margin: "0px 0px 0px 10px" }}
          onClick={(e) => {
            e.preventDefault();
            navigate(`/vieworder/${props.orderdetails._id}`, {
              state: { value: props.value },
            });
          }}
        >
          <Typography sx={OrderTitle}>
            Order #
            {String(RWseries) +
              String(props.orderdetails.orderId).padStart(6, "0")}
          </Typography>

          <Typography sx={ShippingStatusDeliver}>
            {props.orderdetails.status}
          </Typography>
          <Typography sx={OrderDate}>
            {props.orderdetails.createdAt.toString().substring(0, 10)}
          </Typography>
        </Box>
        {/* </Link> */}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
        }}
      >
        <Typography sx={salePriceText}>
          ₹
          {props.orderdetails.totalAmount !== null
            ? props.orderdetails.totalAmount?.toFixed(2)
            : props.orderdetails.returnAmount?.toFixed(2)}
        </Typography>
        {props.orderdetails.status === "delivered" ? (
          <Button
            disabled={
              props.orderdetails.returnPlaced ||
              (!NotExpired() ? true : false) ||
              !props.orderdetails.status == "delivered"
            }
            //
            sx={buttonProductCard}
            onClick={(e) => {
              e.preventDefault();
              navigate(`/returnorder/${props.orderdetails._id}`, {
                state: { value: props.value },
              });
            }}
          >
            Return
          </Button>
        ) : (
          <>
            <Modal
              open={modalIsOpen}
              onRequestClose={() => setIsOpen(false)}
              style={customStyles}
              contentLabel="Example Modal"
            >
              <Box>
                <CloseRoundedIcon onClick={() => setIsOpen(false)} />
                <img
                  src={OrderPlacedDone}
                  alt="Order Done"
                  className="error-order-icon"
                />
                <Typography sx={OrderDoneText}>Oops! Order Failed</Typography>
                <Typography sx={OrderPlacedText}>
                  Something went terribly wrong.
                </Typography>
                <Box
                  sx={{
                    padding: "0px 10px",
                    margin: "30px 0px 10px 0px",
                  }}
                >
                  <Button sx={ButtonCart}>Please Try Again</Button>
                </Box>
                <Box sx={{ padding: "0px 10px", margin: "10px 0px" }}>
                  <Link to="/home">
                    <Button sx={ButtonCartSecond}>Back to home</Button>
                  </Link>
                </Box>
              </Box>
            </Modal>
            {props.orderdetails.status !== "cancelled" && (
              <Button
                sx={buttonProductCard}
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/cancel/${props.orderdetails._id}`, {
                    state: { value: props.value },
                  });
                }}
                //   onClick={(e) => {
                //     // e.preventDefault();
                //     setIsOpen(true);
                //   }}
              >
                Cancel
              </Button>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default OrdersCard;
