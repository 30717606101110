import { Box, Typography } from "@mui/material";
import DefaultImage from "../../../../assets/defaultimg.jpg";

const categoryText = {
  textAlign: "center",
  fontFamily: "Poppins",
  fontWeight: "500",
  fontSize: "13px",
  color: "black",
};

const CategoryCardDiv = {
  background: "#fff",
  boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25);",
  borderRadius: "5px",
  width: "fit-content",
  boxShadow: "rgba(0, 0, 0, 0.05) 0px 4px 4px",
  borderRadius: "8px",
  height: "100%",
};

const CategoryCard = (props) => {
  return (
    <>
      <Box sx={CategoryCardDiv}>
        <img
          src={props.imgsrc === "noimage" ? DefaultImage : props.imgsrc}
          alt="CategoryImages"
          className="category-card-images"
        />
      </Box>
      <Typography variant="subtitle2" sx={categoryText}>
        {" "}
        {props.name}
      </Typography>
    </>
  );
};

export default CategoryCard;
