import CloseIcon from "@mui/icons-material/Close";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  InputBase,
  Modal,
  Radio,
  RadioGroup,
  Skeleton,
  styled,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import server from "../../../server";

import FavoriteIcon from "@mui/icons-material/Favorite";
import { ReactComponent as Bag } from "../../../../assets/cart.svg";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DefaultImage from "../../../../assets/defaultimg.jpg";
import { weightvariants } from "../../../../assets/variants";
import { topRightToastStyles } from "../../../../constants/styles";
import { NavBarContext } from "../../Navbar/Navbar";

const buttonPlusMinus = {
  padding: "8px 18px",
  color: "#fff",
};

const mainCardDiv = {
  display: "flex",
  borderBottom: "2px solid #F0F0F0",
  marginTop: "10px",
  padding: "10px",
  // width: "100%",
  overflow: "hidden",
  maxHeight: "40vh",
};

const InventoryInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    color: "#000000",
    marginTop: "10px",
  },
  "& .MuiInputBase-input": {
    color: "#808080",
    backgroundColor: "#ffffff",
    borderRadius: "3px",
    border: "1px solid #E0E0E0",
    fontSize: "12px",
    padding: "0px 5px",
  },
}));

const cardTitle = {
  color: "#37474F",
  fontSize: "18px",
  fontWeight: "500",
  fontFamily: "Quicksand",
};

const priceText = {
  fontWeight: "600",
  color: "rgba(55, 71, 79, 0.54);",
  textDecoration: "line-through",
  margin: "0",
  fontSize: "16px",
  fontFamily: "Quicksand",
};

const salePriceText = {
  color: "#F37A20",
  fontWeight: "600",
  margin: "0",
  fontSize: "22px",
  fontFamily: "Quicksand",
};

const buttonProductCard = {
  color: "#fff",
  backgroundColor: "#5EC401",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "10px 20px",
  // margin: "0px 0px",

  // width: "30vw",
  textTransform: "capitalize",
  borderRadius: "7px",
  "&.MuiButtonBase-root:hover": {
    background: "#64cf00",
  },
};

const salePercentage = {
  fontFamily: "quicksand",
  color: "#fff",
  backgroundColor: "#F37A20",
  borderRadius: "50%",
  position: "absolute",
  padding: "5px 12px",
  fontSize: "14px",
  top: "0",
  left: "10px",
};

const favoriteIcon = { color: "red", minWidth: "8px" };

const WishlistProduct = ({ prodId, setRemove }) => {
  const items = useContext(NavBarContext);
  //states
  const [isLoading, setIsLoading] = useState(true);
  const [productDetails, setProductDetails] = useState({});
  const [variants, setVariants] = useState([]);

  const [properties, setProperties] = useState([]);
  const [selectedVariant, setSelectedVariant] = useState(1);

  const [quantityButton, setQuantityButton] = useState(false);
  const [quantity, setQuantity] = useState();
  const [wishStatus, setWish] = useState(true);

  const [weights, setweights] = useState(weightvariants.weights);

  //modal states
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getProductDetails = async () => {
    try {
      const result = await axios.get(`${server}/products/${prodId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      setProductDetails(result.data.product);
      if (result.data.variants) {
        setVariants(result.data.variants);
        result.data.variants.forEach((variant) =>
          setProperties([...properties, variant.properties]),
        );
      }

      // console.log(result.data.product)
      // console.log(result.data.variants)

      if (localStorage.getItem("cart")) {
        //check for prexisting product
        let cartArr = JSON.parse(localStorage.getItem("cart"));
      }
    } catch (err) {}

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  const addToCart = async (e) => {
    e.preventDefault();
    //july 22 code below
    let cartarr = JSON.parse(localStorage.getItem("cart"));
    if (localStorage.getItem("cart")) {
      //check for prexisting product
      const exists = cartarr.some(function (element) {
        if (element[0] == prodId) {
          if (element.length === 3) {
            element[2] = (parseInt(element[2]) + 1).toString();
            if (selectedVariant >= 100) {
              // divide by 1000
              localStorage.setItem(
                "cartprice",
                JSON.parse(localStorage.getItem("cartprice")) +
                  productDetails.price * (selectedVariant / 1000),
              );
            } else
              localStorage.setItem(
                "cartprice",
                JSON.parse(localStorage.getItem("cartprice")) +
                  productDetails.price * selectedVariant,
              );
          } else {
            element[1] = (parseInt(element[1]) + 1).toString();
            localStorage.setItem(
              "cartprice",
              JSON.parse(localStorage.getItem("cartprice")) +
                productDetails.price,
            );
          }
          // console.log(element[1])
          setQuantityButton(true);
          setQuantity(1);
          items.setItems((current) => current + 1);
          return true;
        }
      });
      //increment the quantity

      //update the cart
      if (!exists) {
        let temparr;
        if (productDetails?.subCategoryId?.name?.includes("Loose")) {
          temparr = [prodId, selectedVariant, 1];
          if (selectedVariant >= 100) {
            // divide by 1000
            localStorage.setItem(
              "cartprice",
              JSON.parse(localStorage.getItem("cartprice")) +
                productDetails.price * (selectedVariant / 1000),
            );
          } else
            localStorage.setItem(
              "cartprice",
              JSON.parse(localStorage.getItem("cartprice")) +
                productDetails.price * selectedVariant,
            );
        } else {
          temparr = [prodId, 1];
          localStorage.setItem(
            "cartprice",
            JSON.parse(localStorage.getItem("cartprice")) +
              productDetails.price,
          );
        }
        cartarr.push(temparr);
        setQuantity(1);
        setQuantityButton(true);
        items.setItems((current) => current + 1);
      }
    }

    localStorage.setItem("cart", JSON.stringify(cartarr));

    toast.success("Added to bag. ", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const changeQuantity = (e, i) => {
    e.preventDefault();

    // console.log('quant change')
    let cartarr = JSON.parse(localStorage.getItem("cart"));

    //july 22 code below

    if (i == 1) {
      //plus button
      // props.proddetail[1] = 6
      if (
        productDetails.buyinglimit === quantity &&
        productDetails.buyinglimit !== 0
      ) {
        toast.warning("Buying Limit Exceeded", topRightToastStyles);
        return;
      }

      setQuantity((current) => current + 1);
      //change in localstorage also

      //check if variant or product FIRST then proceed
      //it is a product not a variant
      const exists = cartarr.some(function (element) {
        if (element[0] == prodId) {
          // console.log("found prod for increase")
          if (productDetails?.subCategoryId?.name?.includes("Loose")) {
            element[2] = (parseInt(element[2]) + 1).toString();

            if (selectedVariant >= 100) {
              // divide by 1000
              localStorage.setItem(
                "cartprice",
                JSON.parse(localStorage.getItem("cartprice")) +
                  productDetails.price * (selectedVariant / 1000),
              );
            } else
              localStorage.setItem(
                "cartprice",
                JSON.parse(localStorage.getItem("cartprice")) +
                  productDetails.price * selectedVariant,
              );
          } else {
            localStorage.setItem(
              "cartprice",
              JSON.parse(localStorage.getItem("cartprice")) +
                productDetails.price,
            );
            element[1] = (parseInt(element[1]) + 1).toString();
          }

          return true;
        }
      });

      // toast.success("Quantity Increased", {
      //   position: "top-right",
      //   autoClose: 80,
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    } else {
      setQuantity((current) => current - 1);

      //it is a product not a variant
      const exists = cartarr.some(function (element) {
        if (element[0] == prodId) {
          if (productDetails?.subCategoryId?.name?.includes("Loose")) {
            element[2] = (parseInt(element[2]) - 1).toString();

            if (selectedVariant >= 100) {
              // divide by 1000
              localStorage.setItem(
                "cartprice",
                JSON.parse(localStorage.getItem("cartprice")) -
                  productDetails.price * (selectedVariant / 1000),
              );
            } else
              localStorage.setItem(
                "cartprice",
                JSON.parse(localStorage.getItem("cartprice")) -
                  productDetails.price * selectedVariant,
              );

            if (element[2] == 0) {
              //remove from cart
              // console.log('0 ro')
              setQuantityButton(false);
              items.setItems((current) => current - 1);
              return false;
            }
          } else {
            localStorage.setItem(
              "cartprice",
              JSON.parse(localStorage.getItem("cartprice")) -
                productDetails.price,
            );
            element[1] = (parseInt(element[1]) - 1).toString();
            if (element[1] == 0) {
              //remove from cart
              // console.log('0 ro')
              setQuantityButton(false);
              items.setItems((current) => current - 1);
              return false;
            }
          }
          return true;
        }
      });

      // toast.success("Quantity Decreased", {
      //   position: "top-right",
      //   autoClose: 80,
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    }
    localStorage.setItem("cart", JSON.stringify(cartarr));
  };

  const displayButton = () => {
    //22 july code below
    let cartarr = JSON.parse(localStorage.getItem("cart"));
    cartarr.some(function (element) {
      if (element[0] == prodId) {
        // console.log('changing button' + element[1])
        if (element.length === 3) {
          if (parseInt(element[2]) !== 0) {
            setQuantityButton(true);
            setQuantity(parseInt(element[2]));
            setSelectedVariant(element[1].toString());
            return true;
          }
        } else {
          if (parseInt(element[1]) !== 0) {
            setQuantityButton(true);
            setQuantity(parseInt(element[1]));
            return true;
          }
        }
      }
      // else setQuantityButton(false)
    });
  };

  const changeStatus = async (e) => {
    e.preventDefault();
    //call delete wishlist axios call
    const result = await axios.post(
      `${server}/user/wishlist/delete`,
      {
        userId: localStorage.getItem("user"),
        productId: prodId,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );
    // console.log({ productId: prodId });

    setWish((prev) => !prev);
    // props.functionget(props.prodId)
    setRemove(prodId);
  };

  const handleChange = (event) => {
    event.preventDefault();
    let cartarr = JSON.parse(localStorage.getItem("cart"));

    //july 22 code below
    const exists = cartarr.some(function (element) {
      if (element[0] == prodId) {
        if (element[1] >= 100) {
          // divide by 1000
          localStorage.setItem(
            "cartprice",
            JSON.parse(localStorage.getItem("cartprice")) -
              productDetails.price * (element[1] / 1000) * element[2],
          );
        } else
          localStorage.setItem(
            "cartprice",
            JSON.parse(localStorage.getItem("cartprice")) -
              productDetails.price * element[1] * element[2],
          );

        element[1] = event.target.value;

        if (element[1] >= 100) {
          // divide by 1000
          localStorage.setItem(
            "cartprice",
            JSON.parse(localStorage.getItem("cartprice")) +
              productDetails.price * (element[1] / 1000) * element[2],
          );
        } else
          localStorage.setItem(
            "cartprice",
            JSON.parse(localStorage.getItem("cartprice")) +
              productDetails.price * element[1] * element[2],
          );

        return true;
      }

      // else setQuantityButton(false)
    });

    localStorage.setItem("cart", JSON.stringify(cartarr));

    setSelectedVariant(event.target.value);
  };

  useEffect(() => {
    getProductDetails();
  }, []);

  useEffect(() => {
    displayButton();
  }, [productDetails]);

  useEffect(() => {}, [
    variants,
    properties,
    selectedVariant,
    quantityButton,
    quantity,
    isLoading,
    weights,
  ]);

  if (!(Object.keys(productDetails).length || isLoading)) return null;

  return (
    <Box>
      {/* <Link to="/productdetail" style={{ textDecoration: "none" }}> */}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            // top: '30vh',
            // left: '50%',
            // transform: 'translate(-50%, -50%)',
            width: "100vw",
            // height: "70vh",
            bgcolor: "background.paper",
            borderTopLeftRadius: "5%",
            borderTopRightRadius: "5%",

            // borderRadius: "5%",
            boxShadow: 24,
            // padding: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontFamily: "quicksand",
                fontWeight: 400,
                fontSize: "16px",
                padding: "10px",
              }}
            >
              Choose an available size
            </Typography>
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                handleClose();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <Box sx={{ overflow: "scroll" }}>
            <FormControl sx={{ width: "100%" }}>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                onChange={(e) => handleChange(e)}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "space-around",
                    overflow: "scroll",
                  }}
                >
                  {weights &&
                    weights.map((currweight, i) => {
                      return (
                        <div
                          key={i}
                          style={{
                            width: "40%",
                            border: "1px solid #EAEAEA",
                            padding: "5px",
                            marginBottom: "5px",
                            // maxHeight:"10vh"
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: "quicksand",
                                fontWeight: 600,
                                fontSize: "14px",
                              }}
                            >
                              {" "}
                              {currweight >= 100 ? (
                                <>
                                  {currweight}
                                  gm
                                </>
                              ) : (
                                <>
                                  {currweight}
                                  kg
                                </>
                              )}{" "}
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontFamily: "quicksand",
                                  fontWeight: 600,
                                  fontSize: "14px",
                                  color: "rgba(243, 122, 32, 1)",
                                }}
                              >
                                ₹{" "}
                                {currweight >= 100 ? (
                                  <>
                                    {" "}
                                    {(
                                      productDetails?.price *
                                      (currweight / 1000)
                                    ).toFixed(2)}{" "}
                                  </>
                                ) : (
                                  <>{productDetails?.price * currweight}</>
                                )}
                              </Typography>
                              <FormControlLabel
                                value={currweight}
                                control={<Radio />}
                                sx={{
                                  margin: "0px",
                                }}
                              />
                            </Box>
                          </Box>
                        </div>
                      );
                    })}
                  <Box sx={{ width: "40%", padding: "5px" }}></Box>
                </Box>
              </RadioGroup>
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "stretch",
              width: "100%",
            }}
          >
            <Button
              sx={buttonProductCard}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
              onClick={(e) => {
                e.preventDefault();
                handleClose();
                addToCart(e);
              }}
            >
              <Box></Box>
              <Typography> Add to Bag</Typography>

              <Bag
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "14px",
                  height: "14px",
                  margin: "-3px 5px 0px 0px",
                }}
              />
            </Button>
          </Box>
        </Box>
      </Modal>

      <Box sx={mainCardDiv}>
        <Box
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minWidth: "25%", // Adjust as needed
            height: "25vw", // Ensure square shape
            padding: "8px",
          }}
          onClick={() => {
            navigate(`/productdetail/${props.prodid}`, {
              state: {
                name: props.name,
                id: productDetails?.categoryId?._id,
                subid: productDetails.subCategoryId,
              },
            });
          }}
        >
          {isLoading ? (
            <Skeleton variant="rectangular" width={130} height={118} />
          ) : (
            <img
              src={
                productDetails.imageUrl === "noimage"
                  ? DefaultImage
                  : productDetails.imageUrl
              }
              style={{
                width: "30vw", // Fill container
                height: "100%", // Maintain aspect ratio
                objectFit: "contain", // Ensure the image covers the container without distortion
              }}
              onError={(e) => {
                e.target.src = DefaultImage;
              }}
            />
          )}
          {parseInt(productDetails.mrpPrice - productDetails.price, 10) > 0 && (
            <Typography sx={salePercentage}>
              Save ₹
              {parseInt(productDetails.mrpPrice - productDetails.price, 10)}
              <br />
            </Typography>
          )}
        </Box>

        <Box
          sx={{
            padding: "0 6px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "8px",
            width: "100%",
            height: "100%",
          }}
        >
          <Typography
            sx={cardTitle}
            onClick={() => {
              navigate(`/productdetail/${props.prodid}`, {
                state: {
                  name: props.name,
                  id: productDetails?.categoryId?._id,
                  subid: productDetails.subCategoryId,
                },
              });
            }}
          >
            {productDetails?.name}
          </Typography>

          {productDetails?.subCategoryId?.name?.includes("Loose") && (
            <FormControl
              size="small"
              sx={{ width: "100%", margin: "10px 0px" }}
            >
              <Box
                sx={{
                  width: "50%",
                  border: "1px solid #EAEAEA",
                  padding: "6px 10px",
                  textAlign: "left",
                }}
                onClick={handleOpen}
              >
                {selectedVariant >= 100
                  ? `${selectedVariant}gm`
                  : `${selectedVariant}kg`}
              </Box>
            </FormControl>
          )}

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%", // Adjust to fit content
            }}
          >
            {productDetails?.subCategoryId?.name?.includes("Loose") ? (
              <Box
                sx={{
                  width: "100%",
                  padding: "6px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {selectedVariant >= 100 ? (
                  <Typography sx={salePriceText}>
                    ₹
                    {(productDetails.price * (selectedVariant / 1000)).toFixed(
                      2,
                    )}
                  </Typography>
                ) : (
                  <Typography sx={salePriceText}>
                    ₹{(productDetails.price * selectedVariant).toFixed(2)}
                  </Typography>
                )}
              </Box>
            ) : (
              <>
                <Box sx={{ width: "100%" }}>
                  {productDetails.mrpPrice !== productDetails.price && (
                    <Typography sx={priceText}>
                      ₹{productDetails.mrpPrice?.toFixed(2)}
                    </Typography>
                  )}
                  <Typography sx={salePriceText}>
                    ₹{productDetails.price?.toFixed(2)}
                  </Typography>
                </Box>
              </>
            )}

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "60%",
                gap: "10px",
              }}
            >
              <Button
                style={{ minWidth: "10px" }}
                onClick={(e) => changeStatus(e)}
              >
                {wishStatus ? (
                  <FavoriteIcon sx={favoriteIcon} />
                ) : (
                  <FavoriteBorderIcon style={favoriteIcon} />
                )}
              </Button>

              {quantityButton ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    backgroundColor: "#5EC401",
                    color: "#fff",
                    borderRadius: "7px",
                  }}
                >
                  <IconButton
                    sx={buttonPlusMinus}
                    onClick={(e) => changeQuantity(e, 0)}
                  >
                    -
                  </IconButton>
                  {quantity}
                  <IconButton
                    sx={buttonPlusMinus}
                    onClick={(e) => changeQuantity(e, 1)}
                  >
                    +
                  </IconButton>
                </Box>
              ) : (
                <Button
                  sx={buttonProductCard}
                  onClick={(e) => {
                    if (
                      productDetails?.subCategoryId?.name?.includes("Loose")
                    ) {
                      handleOpen();
                    } else addToCart(e);
                  }}
                  disabled={
                    productDetails.stocklimit &&
                    productDetails.stocklimit > productDetails.stock
                  }
                >
                  {productDetails.stocklimit &&
                  productDetails.stocklimit > productDetails.stock ? (
                    <>Out of Stock</>
                  ) : (
                    <>
                      <Bag
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "14px",
                          height: "14px",
                          margin: "-3px 5px 0px 0px",
                        }}
                      />
                      Add
                    </>
                  )}
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Box>

      {/* </Link> */}
    </Box>
  );
};

export default WishlistProduct;
