import { Box, InputBase, Skeleton, styled, Typography } from "@mui/material";
//   import Skeleton from "@mui/material";

const mainCardDiv = {
  display: "flex",
  borderBottom: "2px solid #F0F0F0",
  marginTop: "10px",
  padding: "10px",
};

const InventoryInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    color: "#000000",
    marginTop: "10px",
  },
  "& .MuiInputBase-input": {
    color: "#808080",
    backgroundColor: "#ffffff",
    borderRadius: "3px",
    border: "1px solid #E0E0E0",
    fontSize: "12px",
    padding: "0px 5px",
  },
}));

const buttonPlusMinus = {
  padding: "8px 18px",
};

const cardTitle = {
  color: "#37474F",
  fontSize: "18px",
  fontWeight: "500",
  fontFamily: "Quicksand",
  width: "50px",
  backgroundColor: "grey",
};

const priceText = {
  fontWeight: "600",
  color: "rgba(55, 71, 79, 0.54);",
  textDecoration: "line-through",
  margin: "0",
  fontSize: "16px",
  fontFamily: "Quicksand",
};

const salePriceText = {
  color: "#F37A20",
  fontWeight: "600",
  margin: "0",
  fontSize: "22px",
  fontFamily: "Quicksand",
};

const buttonProductCard = {
  color: "#fff",
  backgroundColor: "#5EC401",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "10px 25px",
  margin: "0px 5px",
  textTransform: "capitalize",
  borderRadius: "7px",
  "&.MuiButtonBase-root:hover": {
    background: "#64cf00",
  },
};

const salePercentage = {
  fontFamily: "quicksand",
  color: "#fff",
  backgroundColor: "#F37A20",
  borderRadius: "50%",
  position: "absolute",
  padding: "5px 12px",
  fontSize: "14px",
  top: "0",
  left: "10px",
};

const CategoryProductCardShimmer = () => {
  return (
    <Box>
      <Box sx={mainCardDiv}>
        <Skeleton variant="rectangular" width={210} height={118}></Skeleton>
        <Box sx={{ flex: "50", padding: "0px 10px" }}>
          <Typography>
            <Skeleton
              variant="text"
              sx={{ fontSize: "16px", width: "100px" }}
            />
          </Typography>

          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box>
              <Skeleton
                variant="text"
                sx={{ fontSize: "16px", width: "100px" }}
              />
              <Skeleton variant="text" sx={{ fontSize: "22px" }} />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Skeleton variant="rounded" width={210} height={60} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CategoryProductCardShimmer;
