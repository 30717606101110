import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import server from "../../server";
import DashboardContext from "../Context/DashboardContext";
import AllCustomers from "./Layout/AllCustomers";
import CustomerTable from "./Layout/CustomerTable";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width:"100%",
  // width: 400,
  bgcolor: "#ffffff",
  border: "none",
  borderRadius: "12px",
  boxShadow: 24,
  p: 1,
  backdropfilter: "blur(4px)",
};

const Customers = () => {
  // console.log(State.getAllStates());
  const [id, setId] = useState(-1);
  const context = useContext(DashboardContext);
  const navigate = useNavigate();

  const { setLoading } = context;
  const [customers, setCustomers] = useState([]);

  const [rows, setRows] = useState([]);
  const [orirows, setOriRows] = useState([]);

  const [tot_users, setTot_users] = useState();
  const [active_users, setActive_users] = useState(0);
  const [bdos, setBdos] = useState([]);

  const getAllCustomer = async () => {
    try {
      const result = await axios.get(`${server}/users`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      // console.log({ "users": result.data })
      setCustomers(result.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("adminuser")) navigate("/");
    else {
      setLoading(true);

      getAllCustomer();
      setLoading(false);
    }
  }, []);

  useEffect(() => {}, [customers]);

  return (
    <div>
      <div className="order-head">
        <p className="order-title">Customers Summary</p>
      </div>
      <div className="customer-grid" style={{ width: "100%", display: "flex" }}>
        <div className="allcustomers" style={{ width: "100%" }}>
          <AllCustomers
            no_users={tot_users}
            active_users={active_users}
            customers={customers}
          />
        </div>
      </div>
      <div>
        <CustomerTable
          rows={customers}
          setRows={setCustomers}
          customers={customers}
          orirows={orirows}
          active_users={active_users}
          setActive_users={setActive_users}
        />
      </div>
      <div></div>
    </div>
  );
};

export default Customers;
