import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";

import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);
const Marketing = ({ orders, label }) => {
  // console.log(orders);
  let p = 0,
    c = 0,
    ip = 0;
  orders?.map((item) => {
    if (item.status.toUpperCase().includes("PENDING")) p++;
    else if (item.status.toLowerCase().includes("inprogress")) ip++;
    else if (
      item.status
        .toLowerCase()
        .includes(label == "Orders" ? "delivered" : "completed")
    )
      c++;
  });
  const data = {
    labels: [
      label == "Orders" ? "Delivered" : "Completed",
      "Pending",
      "In Progress",
    ],
    datasets: [
      {
        label: label,
        data: [c, p, ip],
        backgroundColor: [
          "rgb(85, 111, 241)",
          "rgb(85, 111, 241,0.5)",
          "#fddb7c",
        ],
        hoverOffset: 1,
        weight: 0.3,
      },
    ],
  };
  var options = {
    cutout: 150,
  };

  return (
    <div
      style={{
        width: "30rem",
        height: "30rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Doughnut options={options} data={data} />
    </div>
  );
};

export default Marketing;
