import { Button } from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import server from "../../server";
import DashboardContext from "../Context/DashboardContext";
import EmployeeModal from "./Layout/EmployeeModal";
import EmployeeTable from "./Layout/EmployeeTable";

const Employees = () => {
  const context = useContext(DashboardContext);
  const navigate = useNavigate();

  const { setLoading } = context;
  const [employees, setEmployees] = useState([]);
  const [open, setOpen] = useState(false);

  const [EmployeeInformation, setEmployeeInformation] = useState({
    name: "",
    mobile: "",
    password: "",
  });

  const createEmployee = async () => {
    try {
      const result = await axios.post(
        `${server}/employees`,
        {
          EmployeeInformation,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        },
      );

      setEmployees((prevCustomers) => [...prevCustomers, result.data]);
      setEmployeeInformation("");
      toast.success("Employee created successfully!");
    } catch (error) {
      // Check if the error response exists and has data
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        // Show the error message in a toast
        toast.error(error.response.data.message);
      } else {
        // Handle other errors (like network issues)
        toast.error("Something went wrong. Please try again.");
      }

      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await createEmployee();
    setEmployeeInformation("");
    handleClose();
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const getAllEmployees = async () => {
    try {
      const result = await axios.get(`${server}/employees`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      setEmployees(result.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("adminuser")) navigate("/");
    else {
      setLoading(true);
      getAllEmployees();
      setLoading(false);
    }
  }, []);

  return (
    <div>
      <div className="order-head">
        <p className="order-title">Employee Summary</p>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          sx={{
            marginBottom: "10px",
            marginRight: "10px",
          }}
          onClick={(e) => {
            e.preventDefault();
            handleOpen();
          }}
        >
          Create
        </Button>
      </div>

      <EmployeeModal
        open={open}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        EmployeeInformation={EmployeeInformation}
        setEmployeeInformation={setEmployeeInformation}
      />
      <div>
        <EmployeeTable
          rows={employees}
          setRows={setEmployees}
          customers={employees}
        />
      </div>
    </div>
  );
};

export default Employees;
