import { Box, Typography } from "@mui/material";
import { HashLoader } from "react-spinners";
import NotFound from "../../../../assets/product-not-found.png";

const NotFoundText = {
  fontWeight: "600",
  fontSize: "18px",
  fontFamily: "quicksand",
  textAlign: "center",
};

const ProductNotFound = ({ message, loading }) => {
  return (
    <Box>
      {loading ? (
        <div
          style={{
            width: "100vw",
            height: "80vh",
            backgroundColor: "rgba(255,255,255,1)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <HashLoader color="#5EC401" size={100} />
        </div>
      ) : (
        <>
          <Box
            sx={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={NotFound}
              alt="Product Not Found"
              className="product-not-found"
            />
          </Box>
          <Typography sx={NotFoundText}>{message}</Typography>
        </>
      )}
    </Box>
  );
};

export default ProductNotFound;
