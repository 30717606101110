import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { Box, Button, IconButton, Typography } from "@mui/material";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import server from "../../server";

const header = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px 20px 10px 20px",
  backgroundColor: "#fff",
};

const arrowStyle = {
  position: "absolute",
  left: "20px",
};
const CategoryTitle = {
  fontWeight: "600",
  fontFamily: "Quicksand",
};

const ThinkTitle = {
  fontWeight: "600",
  fontSize: "24px",
  fontFamily: "quicksand",
};

const reviewText = {
  color: "#868889",
  margin: "10px 0px",
  fontFamily: "poppins",
};

const ButtonCart = {
  background:
    "linear-gradient(91deg, rgba(174,220,129,1) 1.21%, rgba(108,197,29,1) 100.55%)",
  color: "#fff",
  textTransform: "none",
  padding: "10px 10px",
  fontSize: "18px",
  fontWeight: "600",
  width: "100%",
  fontFamily: "quicksand",
  "&.MuiButtonBase-root:hover": {
    background:
      "linear-gradient(91deg, rgba(166,213,120,1) 1.21%, rgba(102,198,16,1) 100.55%);",
  },
};

const WriteReviewPage = () => {
  const { prodid } = useParams();

  const navigate = useNavigate();
  //states
  const [newReview, setNewReview] = useState("");
  const [ratingval, setRating] = useState(5);

  useEffect(() => {
    if (!localStorage.getItem("user")) navigate("/");
  }, []);

  const submitReview = async (e) => {
    e.preventDefault();
    try {
      const result = await axios.post(
        `${server}/products/${prodid}/addReview`,
        {
          userId: localStorage.getItem("user"),
          rating: ratingval,
          description: newReview,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        },
      );
      // console.log(result)
      toast.success("Review Added", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      navigate(`/productdetail/${prodid}`);
    } catch (error) {
      //   console.log(error.message);
    }
  };

  useEffect(() => {
    console.log("rating", ratingval);
  }, [ratingval]);

  return (
    <Box>
      <ToastContainer />
      <Box sx={header}>
        <Box sx={arrowStyle}>
          <Link to={`/review/${prodid}`}>
            <IconButton>
              <ArrowBackRoundedIcon />
            </IconButton>
          </Link>
        </Box>
        <Typography variant="h6" sx={CategoryTitle}>
          Write Reviews
        </Typography>
      </Box>
      <Box sx={{ backgroundColor: "#F4F5F9", height: "80vh" }}>
        <Box sx={{ textAlign: "center", padding: "30px 0px 0px 0px" }}>
          <Typography sx={ThinkTitle}>What do you think ?</Typography>
          <Typography sx={reviewText}>
            please give your rating by clicking on <br></br> the stars below
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            margin: "20px 0px",
          }}
        >
          <Stack spacing={1}>
            <Rating
              name="half-rating"
              //   defaultValue={2.5}
              //changed precision to one for now
              value={ratingval}
              precision={1}
              onChange={(e) => setRating(e.target.value)}
              size="large"
            />
          </Stack>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            padding: "0px 20px",
            position: "relative",
          }}
        >
          <CreateOutlinedIcon
            style={{
              position: "absolute",
              left: "30px",
              top: "15px",
              color: "#868889",
            }}
          />
          <textarea
            className="review-textarea"
            placeholder="Tell us about your experience"
            onChange={(e) => setNewReview(e.target.value)}
          />
        </Box>
        <Box sx={{ padding: "10px 20px" }}>
          <Button sx={ButtonCart} onClick={(e) => submitReview(e)}>
            Add review
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default WriteReviewPage;
