import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD5F4rwEXm_CuN_JZInlYBQr3bEsgT3VDI",
  authDomain: "urban-bazaar-fc6dd.firebaseapp.com",
  projectId: "urban-bazaar-fc6dd",
  storageBucket: "urban-bazaar-fc6dd.appspot.com",
  messagingSenderId: "1070494720387",
  appId: "1:1070494720387:web:95d4bdb049311db6ee56b6",
  measurementId: "G-ZJ04MTS69P",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export default app;
