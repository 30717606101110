import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { Box, Button, IconButton, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import server from "../../server";
import ReturnOrderCard from "./Layout/ReturnOrderCard";

const header = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px",
  backgroundColor: "#fff",
  borderBottom: "1px solid #EAEAEA",
};

const arrowStyle = {
  position: "absolute",
  left: "20px",
};

const CategoryTitle = {
  fontWeight: "600",
  fontFamily: "Quicksand",
  fontSize: "14px",
};

const AllProductText = {
  fontWeight: "600",
  fontFamily: "Quicksand",
  padding: "8px 20px",
  borderBottom: "1px solid #EAEAEA",
};

const DeliverDiv = {
  padding: "10px 15px",
  borderTop: "8px solid #F2F2F2",
};

const DeliverText = {
  fontSize: "16px",
  fontFamily: "quicksand",
  fontWeight: "600",
};

const DeliveryLocation = {
  fontSize: "18px",
  fontWeight: "600",
  fontFamily: "quicksand",
};

const ChangeText = {
  fontSize: "20px",
  fontWeight: "700",
  fontFamily: "quicksand",
  color: "#5EC401",
};

const addressText = {
  padding: "0px 0px 0px 20px",
  fontSize: "17px",
  fontWeight: "500",
  fontFamily: "quicksand",
};

const NoteText = {
  fontSize: "15px",
  fontWeight: "600",
  fontFamily: "quicksand",
};

const TotalDivTotal = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "10px 0px",
};

const TotalTotal = {
  fontSize: "19px",
  fontWeight: "600",
  fontFamily: "quicksand",
};

const ButtonCart = {
  backgroundColor: "#5EC401",
  color: "#fff",
  textTransform: "none",
  padding: "10px 10px",
  fontSize: "18px",
  fontWeight: "600",
  width: "100%",
  fontFamily: "quicksand",
  "&.MuiButtonBase-root:hover": {
    background: "#64cf00",
  },
};

const ReturnOrder = () => {
  const { orderId } = useParams();

  const navigate = useNavigate();
  const location = useLocation();

  //states
  const [orderinfo, setOrderInfo] = useState([]);
  const [orderproductList, setorderproducts] = useState([]);
  const [returnAmount, setReturnAmount] = useState(0);
  const [currentAddress, setAddress] = useState("");
  const [message, setmessage] = useState("");
  const getorderDetails = async () => {
    const result = await axios.get(`${server}/orders/id/${orderId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    // console.log(result.data)
    setOrderInfo(result.data);
    setorderproducts(result.data.productList);
    const addressresult = await axios.get(
      `${server}/address/find/${result.data.deliveryAddressId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );
    // console.log(result.data[0])
    setAddress(addressresult.data[0]);

    // if(!localStorage.getItem('returnarr'))
    localStorage.setItem("returnarr", JSON.stringify([]));
  };

  useEffect(() => {
    if (!localStorage.getItem("user")) navigate("/");
    else getorderDetails();
    // getcurraddress()
  }, []);

  useEffect(() => {
    // console.log(returnarr)
  }, [returnAmount, currentAddress]);

  useEffect(() => {
    // console.log(returnarr)
    // getcurraddress()
  }, [orderinfo]);

  const calculateReturnAmountonselect = (
    status,
    price,
    quantity,
    prodid,
    variantvalue,
  ) => {
    // e.preventDefault()
    if (!price) return;
    // console.log(price)
    if (status) {
      if (variantvalue)
        setReturnAmount((current) => current + price * quantity);
      else setReturnAmount((current) => current + price * quantity);
      // setorderproducts()
      // if (!exists) {
      //add to array
      let returnarr = JSON.parse(localStorage.getItem("returnarr"));
      let temparr;
      if (variantvalue) temparr = [prodid, quantity, variantvalue];
      else temparr = [prodid, quantity];
      if (quantity != 0) returnarr.push(temparr);
      // returnarr.push(temparr)
      localStorage.setItem("returnarr", JSON.stringify(returnarr));
      // }
    } else {
      if (variantvalue)
        setReturnAmount((current) => current - price * quantity);
      else setReturnAmount((current) => current - price * quantity);
      // console.log(returnarr.length)
      let returnarr = JSON.parse(localStorage.getItem("returnarr"));
      const exists = returnarr.forEach((element, index) => {
        if (element[0] == prodid) {
          // meaning that this product is present in list now and now remove it
          returnarr.splice(index, 1);
          // console.log('spliced')
          return true;
        }
      });

      // console.log(exists)
      localStorage.setItem("returnarr", JSON.stringify(returnarr));
    }
  };

  const calculateonchange = (status, i, price, prodid, variantvalue) => {
    if (status) {
      if (i == 0) {
        // console.log('called')
        //meaning u decremented quantity
        if (variantvalue) setReturnAmount((current) => current - price);
        else setReturnAmount((current) => current - price);
        //change quantity in array
        let returnarr = JSON.parse(localStorage.getItem("returnarr"));

        // console.log(returnarr)
        const exists = returnarr.forEach((element, index) => {
          // console.log(element[0])
          if (element[0] == prodid) {
            // meaning that this product is present in list now
            if (element.length === 3) {
              element[1]--;
              if (element[1] == 0) returnarr.splice(index, 1);
            } else {
              element[1]--;
              if (element[1] == 0) returnarr.splice(index, 1);
            }

            // console.log('found')
          }
        });
        localStorage.setItem("returnarr", JSON.stringify(returnarr));
      } else {
        if (variantvalue) setReturnAmount((current) => current + price);
        else setReturnAmount((current) => current + price);

        let returnarr = JSON.parse(localStorage.getItem("returnarr"));
        const exists = returnarr.some((element) => {
          if (element[0] == prodid) {
            //     // meaning that this product is present in list now
            // console.log("found product " + prodid)
            element[1]++;
            return true;
          }
        });
        // console.log(exists)

        if (!exists) {
          // console.log("printing first" + exists)
          let temparr;
          if (variantvalue) temparr = [prodid, 1, variantvalue];
          else temparr = [prodid, 1];
          returnarr.push(temparr);
        }
        localStorage.setItem("returnarr", JSON.stringify(returnarr));
      }

      // console.log(returnarr)
    }
  };

  const placeorder = async (e) => {
    e.preventDefault();
    let productList = [];
    let returnarr = JSON.parse(localStorage.getItem("returnarr"));
    if (returnarr.length == 0) {
      toast.error("Please Select products first");
      return;
    }
    if (message.length === 0) {
      toast.error("Please state your reasons");
      return;
    }
    returnarr.forEach((element) => {
      let temparr;
      if (element.length === 3) {
        temparr = {
          productId: element[0],
          count: element[1],
          variant: element[2],
        };
      } else {
        temparr = {
          productId: element[0],
          count: element[1],
        };
      }

      productList.push(temparr);
    });
    // console.log(productList)

    try {
      const result = await axios.post(
        `${server}/returns`,
        {
          userId: localStorage.getItem("user"),
          orderId: orderId,
          productList: productList,
          returnAmount: returnAmount,
          pickUpAddress: currentAddress._id,
          message: message,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        },
      );
      localStorage.setItem("returnarr", JSON.stringify([]));

      //change order document in mongodb to make field returnPlaced to true
      const changeorder = await axios.put(
        `${server}/orders/update/${orderId}`,
        {
          returnPlaced: true,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        },
      );
      console.log(changeorder);
      //send notification
      const notif = await axios.post(
        `${server}/notifications/send`,
        {
          userId: localStorage.getItem("user"),
          returnId: result.data._id,
          message: "Return request has been made. Please wait for response ",
          type: "return",
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        },
      );

      navigate(`/orderplaced/1/${result.data._id}`, {
        state: { value: location?.state?.value },
      });
    } catch (error) {
      // navigate('/')
      console.log(error);
      navigate("/orderplaced/0");
    }
  };

  return (
    <Box sx={{ marginBottom: "100px" }}>
      <ToastContainer />
      <Box sx={header}>
        <Box sx={arrowStyle}>
          {/* <Link to="/client/orders"> */}
          <IconButton
            onClick={(e) => {
              e.preventDefault();
              navigate("/client/orders", {
                state: { value: location?.state?.value },
              });
            }}
          >
            <ArrowBackRoundedIcon />
          </IconButton>
          {/* </Link> */}
        </Box>
        <Typography variant="h6" sx={CategoryTitle}>
          Order #{orderinfo.orderId}
        </Typography>
      </Box>
      <Typography variant="subtitle1" sx={AllProductText}>
        Select the product you want to return!
      </Typography>
      {orderproductList &&
        orderproductList.map((currproduct) => {
          return (
            <ReturnOrderCard
              prodid={currproduct.productId}
              quantity={currproduct.itemCount}
              variant={currproduct.variant}
              calculate={calculateReturnAmountonselect}
              quantchangefunction={calculateonchange}
            />
          );
        })}

      <Box sx={DeliverDiv}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={DeliveryLocation}>Pickup Location</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "10px 0px",
          }}
        >
          <LocationOnOutlinedIcon
            style={{
              color: "#37474F",
              backgroundColor: "rgba(35, 108, 217, 0.121039)",
              padding: "10px",
              borderRadius: "50%",
            }}
          />

          <Typography sx={addressText}>{currentAddress.addressLine}</Typography>
        </Box>
      </Box>
      <Box sx={DeliverDiv}>
        <Typography sx={NoteText}>Whats the problem?</Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            margin: "10px 0px",
            position: "relative",
          }}
        >
          <textarea
            className="note-textarea"
            onChange={(e) => setmessage(e.target.value)}
            placeholder="Describe the problem!"
            style={{ border: "1px solid #ededed" }}
          />
        </Box>
      </Box>
      <Box sx={DeliverDiv}>
        <Typography sx={DeliverText}>
          Note: Pick-up will be scheduled in 2-3 days and you will be informed
          once confirmed!
        </Typography>
      </Box>
      <Box sx={DeliverDiv}>
        <Box sx={TotalDivTotal}>
          <Typography sx={TotalTotal}>Return Amount</Typography>
          <Typography sx={TotalTotal}>₹{returnAmount}</Typography>
        </Box>
      </Box>
      <Box sx={{ padding: "0px 10px" }}>
        {/* <Link to="/orderplaced"> */}

        <Button sx={ButtonCart} onClick={(e) => placeorder(e)}>
          Place Return Request
          <ArrowForwardRoundedIcon
            sx={{ position: "absolute", right: "20px" }}
          />
        </Button>
      </Box>
    </Box>
  );
};

export default ReturnOrder;
