import axios from "axios";
import { useState } from "react";
import server from "../../server";
import DashboardContext from "./DashboardContext";

const DashboardState = (props) => {
  const [loading, setLoading] = useState(false);
  const [crops, setCrops] = useState();
  const [brands, setBrands] = useState();
  const [customers, setCustomers] = useState();
  const [orders, setOrders] = useState();
  const [products, setProducts] = useState();
  const [posts, setPosts] = useState();
  const [goback, setGoBack] = useState(false);

  const [equipment, setEquipment] = useState();
  const [cropsSelected, setCropsSelected] = useState();
  const [brandsSelected, setBrandsSelected] = useState();
  const [equipmentsSelected, setEquipmentsSelected] = useState();
  const [refresh, setRefresh] = useState(false);
  const [tags, setTags] = useState();
  const [tagsSelected, setTagsSelected] = useState();

  const handleCropsEdit = (crops, newProduct, setNewProduct) => {
    let cropArr = [...newProduct.crops];
    const newArr = [];
    cropArr.forEach((item) => {
      let info = crops.filter((crop) => crop.id === item);
      newArr.push(info[0]);
    });

    setCropsSelected(newArr);
  };

  const handleBrandsEdit = (brands, newProduct, setNewProduct) => {
    let brandArr = [...newProduct.brands];
    const newArr = [];
    brandArr.forEach((item) => {
      let info = brands.filter((brand) => brand.id === item);
      newArr.push(info[0]);
    });

    setBrandsSelected(newArr);
  };

  const handleTagsEdit = (tags, newProduct, setNewProduct) => {
    let tagArr = [...newProduct.tags];
    const newArr = [];
    tagArr.forEach((item) => {
      let info = tags.filter((tag) => tag.id === item);
      newArr.push(info[0]);
    });

    setTagsSelected(newArr);
  };

  const handleEquipmentsEdit = (equipment, newProduct, setNewProduct) => {
    let equipmentsArr = [...newProduct.farm_equipments];
    const newArr = [];
    equipmentsArr.forEach((item) => {
      let info = equipment.filter((crop) => crop.id === item);
      newArr.push(info[0]);
    });

    setEquipmentsSelected(newArr);
  };

  const getEquipment = (isEdit, newProduct, setNewProduct) => {
    axios
      .get(`${server}/equipment/getequipment`, requestOptions)
      .then((result) => {
        if (isEdit) {
          handleEquipmentsEdit(result.data.results, newProduct, setNewProduct);
        }
        setEquipment(result.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getBrands = (isEdit, newProduct, setNewProduct) => {
    axios
      .get(`${server}/brands/getBrands`, requestOptions)
      .then((result) => {
        if (isEdit) {
          handleBrandsEdit(result.data.results, newProduct, setNewProduct);
        }

        setBrands(result.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getTags = (isEdit, newProduct, setNewProduct) => {
    axios
      .get(`${server}/tags/getTags`, requestOptions)
      .then((result) => {
        if (isEdit) {
          handleTagsEdit(result.data.results, newProduct, setNewProduct);
        }

        setTags(result.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCrops = (isEdit, newProduct, setNewProduct) => {
    axios
      .get(`${server}/crops/getcrops`, requestOptions)
      .then((res) => {
        // console.log(res.data);
        if (isEdit) {
          handleCropsEdit(res.data.results, newProduct, setNewProduct);
        }
        setCrops(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getCustomers = () => {
    axios
      .get(`${server}/users`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      })
      .then((res) => {
        // console.log(res.data);
        // if (isEdit) {
        //   handleCropsEdit(res.data.results, newProduct, setNewProduct);
        // }
        setCustomers(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getOrders = () => {
    axios
      .get(`${server}/orders`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      })
      .then((res) => {
        // console.log(res.data);
        // if (isEdit) {
        //   handleCropsEdit(res.data.results, newProduct, setNewProduct);
        // }
        setOrders(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [returns, setReturns] = useState();
  const getReturns = () => {
    axios
      .get(`${server}/returns`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      })
      .then((res) => {
        // console.log(res.data);
        // if (isEdit) {
        //   handleCropsEdit(res.data.results, newProduct, setNewProduct);
        // }
        setReturns(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getProducts = () => {
    axios
      .get(`${server}/admin/products`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      })
      .then((res) => {
        // console.log(res.data);
        // if (isEdit) {
        //   handleCropsEdit(res.data.results, newProduct, setNewProduct);
        // }
        setProducts(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getPosts = () => {
    axios
      .get(`${server}/posts/getPosts`, requestOptions)
      .then((res) => {
        // console.log(res.data);
        // if (isEdit) {
        //   handleCropsEdit(res.data.results, newProduct, setNewProduct);
        // }
        setPosts(res.data.posts);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const requestOptions = {
    headers: {
      method: "GET",
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  };

  const [s3, setS3] = useState();

  //   useEffect(() => {
  //     axios
  //       .get(`${server}/config`, requestOptions)
  //       .then((res) => {
  //         AWS.config.update(res.data);
  //         setS3(new AWS.S3());
  //       })
  //       .catch((err) => {
  //         // console.log(err);
  //       });
  //   }, []);

  //   useEffect(() => {
  //     s3 &&
  //       s3.listBuckets((err, data) => {
  //         if (err) {
  //           console.error("Error listing S3 buckets:", err.message);
  //         } else {
  //           console.log("AWS S3 is configured.");
  //         }
  //       });
  //   }, [s3]);

  return (
    <DashboardContext.Provider
      value={{
        setLoading,
        goback,
        setGoBack,
        loading,
        getBrands,
        brands,
        tags,
        setTags,
        getTags,
        getCrops,
        tagsSelected,
        crops,
        getEquipment,
        equipment,
        handleCropsEdit,
        cropsSelected,
        brandsSelected,
        equipmentsSelected,
        refresh,
        setRefresh,
        customers,
        getCustomers,
        orders,
        getOrders,
        getProducts,
        products,
        posts,
        getPosts,
        returns,
        getReturns,
        s3,
      }}
    >
      {props.children}
    </DashboardContext.Provider>
  );
};
export default DashboardState;
