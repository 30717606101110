const ProductImage = ({ productItem }) => {
  return (
    <div className="product-image">
      <img
        alt="pro-img"
        src={productItem?.image.split(", ")[0]}
        style={{ width: "120px", height: "130px" }}
      />
    </div>
  );
};

export default ProductImage;
