import axios from "axios";
// import { config } from "../../../constants/aws";
// import { NavBarContext } from "../Navbar/Navbar";
// import AWS from "aws-sdk";
import server from "../../../Components/server";

export default async function handleProfileImage({ images, setImages }) {
  //   const s3 = new AWS.S3();
  //   const { s3 } = useContext(NavBarContext);

  //   console.log(images);
  //   const length = images?.filter((item) => item != null)?.length;
  let compiledUrl = "";

  const file = images;
  console.log(file);

  // if (typeof file === "string") {
  //   if (i === length - 1) {
  //     compiledUrl += file;
  //   } else {
  //     compiledUrl += file + ", ";
  //   }

  //   console.log(compiledUrl);
  // }

  const fileName = file.name;

  // Configure the S3 upload parameters
  const params = {
    // Bucket: "farmore-assets",
    // Key: `${Date.now()}.${fileName}`,
    // ContentType: file.type,
    // Body: file,
  };

  try {
    // Upload the image to S3
    // const data = await s3.upload(params).promise();
    // const imageUrl = data.Location;
    // console.log(imageUrl);
    // compiledUrl = imageUrl;

    //   setImages(imageUrl);

    const response = await axios.get(`${server}/get-upload-url`, {
      params: { fileName, fileType: file.type },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });

    //   console.log("handleImageUpload", response);

    const uploadURL = response.data.uploadURL;
    const downloadURL = response.data.downloadURL.split("?")[0];

    console.log(uploadURL, downloadURL);

    // Upload the image to S3 using presigned URL
    await axios.put(uploadURL, file, {
      headers: {
        "Content-Type": file.type,
      },
    });

    return downloadURL;
  } catch (error) {
    console.error("Error uploading image:", error);
  }
}
