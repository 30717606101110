import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import { alpha, useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { visuallyHidden } from "@mui/utils";
import axios from "axios";
import jsPDF from "jspdf";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ProfileIcon from "../../../assets/Profile.svg";
import server from "../../server";
import DashboardContext from "../Context/DashboardContext";

const header = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "0px",
  //   marginBottom: "50px",
  // width: "100%"
  margin: "0 10px",
};

const headerlefttitle = {
  fontSize: "16px",
  fontWeight: "600",
  marginRight: "2px",
  // color: "black"
};

const headerleftcontent = {
  fontSize: "16px",
  fontWeight: "500",
  color: "black",
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function getComparator(order, orderBy) {
//   return order === "desc"
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function stableSort(array, comparator) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) {
//       return order;
//     }
//     return a[1] - b[1];
//   });
//   return stabilizedThis.map((el) => el[0]);
// }

const headCells = [
  {
    id: "productname",
    numeric: false,
    disablePadding: true,
    label: "Product Name",
  },
  {
    id: "productId",
    numeric: false,
    disablePadding: true,
    label: "rwId",
  },
  {
    id: "unitprice",
    numeric: true,
    disablePadding: false,
    label: "Unit Price",
  },
  {
    id: "qty",
    numeric: false,
    disablePadding: true,
    label: "Qty",
  },
  {
    id: "total",
    numeric: true,
    disablePadding: false,
    label: "Order Total",
  },
];

//this is the header of table basically showing names of the columns and the sort buttons for each
function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ borderTop: "1px solid #E1E2E9" }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "center" : "center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

//this is for search functionality in table
function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  const handlesearch = async (e) => {
    // const search_data=[];
    if (e.target.value != "") {
      // console.log(e.target.value);
      // console.log(props.orirows);
      const filteredRows = await props.orirows.filter((row) => {
        return row.first_name
          ?.toLowerCase()
          ?.includes(e.target.value.toLowerCase());
      });
      // setRows(filteredRows);
      // console.log(filteredRows);
      props.setRows(filteredRows);
    } else props.setRows(props.orirows);
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity,
            ),
        }),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div>
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Items: {props.rows.length}
          </Typography>
        )}
      </div>

      <div>
        <Button
          onClick={(e) => {
            e.preventDefault();
            // props.setLoading(true)
            props.print();
            // props.setLoading(false)
          }}
          sx={{
            textTransform: "none",
            color: "black",
            border: 1,
            marginRight: "10px",
          }}
        >
          Print Label
        </Button>
        <Button
          onClick={(e) => {
            e.preventDefault();
            // props.setLoading(true)
            props.printorder();
            // props.setLoading(false)
          }}
          sx={{
            textTransform: "none",
            color: "black",
            border: 1,
          }}
        >
          Print Return
        </Button>
      </div>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const AdminViewReturn = () => {
  // console.log(props.rows);
  //rows are basically your data
  const context = useContext(DashboardContext);

  const navigate = useNavigate();
  const { setLoading, setGoBack } = context;
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [active_users, setActive_users] = useState(0)
  const [orirows, setorirows] = useState([]);

  const { returnId } = useParams();

  //   //states
  const [orderdetails, setOrderDetails] = useState();
  const [prodList, setProdList] = useState();

  const [RWseries, setRWSeries] = useState("");

  //   //get order detail by order id
  //   //need user and address populated
  const getOrderDetails = async () => {
    setGoBack(true);
    const result = await axios.get(`${server}/admin/returns/${returnId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    // console.log(result.data.productList)
    setOrderDetails(result.data);
    setProdList(result.data.productList);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    // if (event.target.checked) {
    //   const newSelected = props.rows.map((n) => n.name);
    //   setSelected(newSelected);
    //   return;
    // }
    // setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - prodList.length) : 0;

  const handleChange = async (value) => {
    console.log(value);
    // api to change status
    try {
      const updatestatus = await axios.put(
        `${server}/admin/returns/update/${returnId}`,
        {
          status: value,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        },
      );
      // console.log(updatestatus)

      //send notificaton acc to status update
      let message;
      switch (value) {
        case "inprogress":
          message = "We are on our way to pick up the products";
          break;

        case "completed":
          message = "Requested products have been succesfully returned";
          break;

        case "cancelled":
          message = "Return request has been cancelled";
          break;
      }

      const notification = await axios.post(
        `${server}/notifications/send`,
        {
          userId: orderdetails?.userId?._id,
          returnId: orderdetails._id,
          message: message,
          type: "return",
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        },
      );

      getOrderDetails();
    } catch (error) {
      console.log(error);
    }
  };

  const print = async () => {
    setLoading(true);
    let report = new jsPDF("portrait", "pt", "a4");
    document.getElementById("printlabeldiv").style.display = "flex";
    await report.html(document.querySelector("#printlabeldiv")).then(() => {
      report.save("ReturnLabel" + orderdetails.returnId + ".pdf");
      // zip.file("order" + i + ".pdf", report.save("report.pdf"));
    });
    document.getElementById("printlabeldiv").style.display = "none";
    setLoading(false);
  };
  const printorder = async () => {
    setLoading(true);
    let report = new jsPDF("portrait", "pt", "a4");
    document.getElementById("printtablediv").style.display = "flex";
    await report.html(document.querySelector("#printtablediv")).then(() => {
      report.save("Return" + orderdetails.returnId + ".pdf");
      // zip.file("order" + i + ".pdf", report.save("report.pdf"));
    });
    document.getElementById("printtablediv").style.display = "none";

    setLoading(false);
  };

  const fetchSeries = async () => {
    const newseries = await axios.get(`${server}/admin/orders/get/series`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });

    setRWSeries(newseries.data.series);
  };

  useEffect(() => {
    fetchSeries();
    if (!localStorage.getItem("adminuser")) navigate("/");
    else getOrderDetails();
  }, []);

  useEffect(() => {
    console.log(orderdetails);
  }, [orirows, orderdetails, prodList]);

  const deliveryCharge = 75;

  return (
    <div>
      <Box sx={header}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              // justifyContent: "space-between",
              marginRight: "10px",
            }}
          >
            <Typography sx={headerlefttitle}>Return Number: </Typography>
            <Typography sx={headerleftcontent}>
              {" "}
              {orderdetails ? <> #{orderdetails.returnId}</> : <></>}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              // justifyContent: "space-between",
              marginRight: "10px",
            }}
          >
            <Typography sx={headerlefttitle}>Order ID: </Typography>
            <Typography sx={headerleftcontent}>
              {orderdetails ? (
                `#${
                  String(RWseries) +
                  String(orderdetails?.orderId?.orderId).padStart(6, "0")
                }`
              ) : (
                <></>
              )}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={headerlefttitle}>Return Date </Typography>
            <Typography sx={headerleftcontent}>
              {" "}
              {orderdetails ? (
                <> {orderdetails.createdAt.toString().substring(0, 10)}</>
              ) : null}
            </Typography>
          </Box>
        </Box>

        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "right",
              justifyContent: "space-between",
            }}
          >
            <FormControl>
              <InputLabel id="demo-simple-select-label">
                Change Status
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                sx={{ width: "15vw" }}
                // value={"pending"}
                label="Status"
                onChange={(e) => handleChange(e.target.value)}
              >
                <MenuItem value={"pending"}>Pending</MenuItem>
                <MenuItem value={"inprogress"}>In Progress</MenuItem>
                <MenuItem value={"completed"}>Completed</MenuItem>
                <MenuItem value={"cancelled"}>Cancelled</MenuItem>
                {/* <MenuItem value={"c"}>Delivered</MenuItem> */}
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Box>

      {orderdetails ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            height: "100%",
            padding: "10px",
            // marginBottom: "10vh",
            gap: 2,
          }}
        >
          {/* //1st box */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              backgroundColor: "white",
              borderRadius: "10px",
              padding: "10px",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <img src={ProfileIcon} alt="" />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography>{orderdetails.orderId?.recipientName}</Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "grey",
                        fontSize: "12px",
                      }}
                    >
                      Customer Since{" "}
                    </Typography>
                    <Typography
                      sx={{
                        color: "grey",
                        fontSize: "12px",
                      }}
                    >
                      {" "}
                      {orderdetails?.userId?.createdAt
                        ?.toString()
                        ?.substring(0, 10)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Typography
                sx={{
                  backgroundColor: "#FFF2E2",
                  borderRadius: "10px",
                  padding: "10px",
                  // width:"50%",
                  // height:"auto"
                }}
              >
                {orderdetails?.status}
              </Typography>
            </Box>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography sx={{ color: "grey" }}>Phone No</Typography>
                <Typography>
                  {orderdetails.orderId?.recipientPhoneNo}
                </Typography>
              </Box>
            </Box>
          </Box>

          {/* 2nd box */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              backgroundColor: "white",
              borderRadius: "10px",
              padding: "10px",
              height: "auto",
              width: "100%",
            }}
          >
            <Typography sx={{ color: "grey", marginBottom: "10px" }}>
              PickUp Address
            </Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Typography sx={{ color: "grey" }}>LocationType : </Typography>
              <Typography> {orderdetails?.orderId.locationType}</Typography>
            </Box>
            <Typography sx={{ color: "grey" }}>Address Line:</Typography>
            <Typography>{orderdetails?.orderId.addressLine}</Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Typography sx={{ color: "grey" }}>Pincode: </Typography>
              <Typography> {orderdetails?.orderId.pincode}</Typography>
            </Box>
          </Box>

          {/* 3rd box
           */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              // justifyContent: "space-between",
              // width: "30vw",
              backgroundColor: "white",
              borderRadius: "10px",
              padding: "10px",
              width: "100%",
            }}
          >
            <Typography sx={{ color: "grey", marginBottom: "10px" }}>
              Returning Reason{" "}
            </Typography>
            <Typography>{orderdetails.message}</Typography>
          </Box>
        </Box>
      ) : (
        // {/* basic details 3 box ended above */}
        <></>
      )}

      {prodList ? (
        <Box sx={{ display: "flex", margin: "0 10px" }}>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <EnhancedTableToolbar
              numSelected={selected.length}
              rows={prodList}
              setRows={setProdList}
              orirows={[]}
              print={print}
              printorder={printorder}
              setLoading={setLoading}
            />

            <TableContainer style={{ padding: "0px 20px" }}>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={prodList?.length}
                />
                <TableBody>
                  {(rowsPerPage > 0
                    ? prodList?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage,
                      )
                    : prodList
                  )?.map((row, index) => {
                    // const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    // if(row?.is_active=="true")
                    // props.setActive_users(props.active_users+1);

                    return (
                      <TableRow
                        hover
                        // onClick={(event) => handleClick(event, row.name)}
                        role="checkbox"
                        // aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.name}
                        // selected={isItemSelected}
                        sx={{ cursor: "pointer" }}
                        style={{
                          border: "1px solid transparent",
                        }}
                      >
                        <TableCell
                          align="center"
                          style={{
                            border: "1px solid transparent",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              {/* <Typography sx={{ color: "grey" }}>Product ID: </Typography> */}
                              {/* <Typography>  {row.productId._id.toString().substring(0, 6)}</Typography> */}
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              {/* <Typography sx={{ color: "grey" }}>Product Name: </Typography> */}
                              <Typography> {row.productId?.name}</Typography>
                            </Box>
                          </Box>
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{
                            border: "1px solid transparent",
                          }}
                        >
                          {row?.productId?.rwId}
                        </TableCell>

                        <TableCell
                          padding="none"
                          align="center"
                          style={{
                            border: "1px solid transparent",
                          }}
                        >
                          {row.productId?.price}
                        </TableCell>
                        <TableCell
                          padding="none"
                          align="center"
                          style={{
                            border: "1px solid transparent",
                          }}
                        >
                          {/* {row.count} */}
                          {row.variant ? (
                            row.variant >= 100 ? (
                              <>
                                {row.variant}gm x {row.count}
                              </>
                            ) : (
                              <>
                                {row.variant}kg x {row.count}
                              </>
                            )
                          ) : (
                            <>{row.count}pcs </>
                          )}
                        </TableCell>
                        <TableCell
                          padding="none"
                          align="center"
                          style={{
                            border: "1px solid transparent",
                          }}
                        >
                          {/* {row.count * row.productId.price} */}
                          {row.variant ? (
                            row.variant >= 100 ? (
                              <>
                                {" "}
                                {row.productId?.price *
                                  row.count *
                                  (row.variant / 1000)}
                              </>
                            ) : (
                              <>
                                {row.productId?.price * row.count * row.variant}
                              </>
                            )
                          ) : (
                            <> {row.productId?.price * row.count}</>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  <TableRow>
                    <TableCell colSpan={4} />
                    <TableCell padding="none" align="center">
                      Total :₹{orderdetails.returnAmount?.toFixed(2)}
                    </TableCell>
                  </TableRow>

                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 25]}
              component="div"
              count={10}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </Paper>
        </Box>
      ) : (
        <></>
      )}

      {/* //print label and print order div */}
      {orderdetails ? (
        <div
          id="printtablediv"
          style={{
            display: "none",
            marginBottom: "20px",
          }}
        >
          <table
            style={{
              border: "1px solid black",
              borderCollapse: "collapse",
              // height: "800px",
              width: "580px",
              marginLeft: "10px",
              marginTop: "10px",
            }}
          >
            <tr style={{ height: "10px" }}>
              <td
                colSpan={4}
                style={{
                  border: "1px solid black",
                  borderCollapse: "collapse",
                  padding: "10px",
                  fontSize: "12px",
                }}
              >
                <span style={{ fontWeight: 700 }}> RAJDHANI RETAIL </span>
                <br></br>
                GROUND FLOOR, PARINAY MANGAL KARYALAYA, MANIKBAUG, SINHAGAD RD
                PUNE-53 PH NO. - 8378909909 27BDWPG6317C1ZG
              </td>
              <td
                style={{
                  border: "1px solid black",
                  borderCollapse: "collapse",
                  padding: "10px",
                  fontSize: "12px",
                  verticalAlign: "top",
                }}
                colSpan={7}
              >
                Return Id. {orderdetails.returnId} <br></br>
                DATE {orderdetails.createdAt.toString().substring(0, 10)}
              </td>
            </tr>

            <tr style={{ height: "10px" }}>
              <td
                colSpan={3}
                style={{
                  border: "1px solid black",
                  borderCollapse: "collapse",
                  padding: "10px",
                  fontSize: "12px",
                  verticalAlign: "top",
                  //   width: "30%",
                  width: "100%",
                }}
              >
                Customer {orderdetails.orderId?.recipientName} <br></br>
                Mobile No. {orderdetails.orderId?.recipientPhoneNo}
              </td>
              <td
                colSpan={1}
                style={{
                  border: "1px solid black",
                  borderCollapse: "collapse",
                  padding: "10px",
                  fontSize: "12px",
                  verticalAlign: "top",
                }}
              >
                Message <br></br>
                {orderdetails.message}
              </td>
              <td
                style={{
                  border: "1px solid black",
                  borderCollapse: "collapse",
                  padding: "10px",
                  fontSize: "12px",
                  verticalAlign: "top",
                }}
                colSpan={7}
              >
                Delivery Address <br></br>
                {orderdetails.orderId.addressLine}
              </td>
            </tr>

            <tr style={{ height: "10px" }}>
              <th
                style={{
                  padding: "10px",
                  border: "1px solid black",
                  borderCollapse: "collapse",
                  fontSize: "12px",
                  verticalAlign: "top",
                  textAlign: "center",
                }}
              >
                Sr. No
              </th>
              <th
                style={{
                  padding: "10px",
                  border: "1px solid black",
                  borderCollapse: "collapse",
                  fontSize: "12px",
                  verticalAlign: "top",
                  textAlign: "center",
                }}
              >
                rwId
              </th>
              <th
                colSpan={3}
                style={{
                  padding: "10px",
                  border: "1px solid black",
                  borderCollapse: "collapse",
                  fontSize: "12px",
                  verticalAlign: "top",
                }}
              >
                {" "}
                Product Name
              </th>
              <th
                colSpan={2}
                style={{
                  padding: "10px",
                  border: "1px solid black",
                  borderCollapse: "collapse",
                  fontSize: "12px",
                  verticalAlign: "top",
                }}
              >
                Rate
              </th>
              <th
                colSpan={2}
                style={{
                  padding: "10px",
                  border: "1px solid black",
                  borderCollapse: "collapse",
                  fontSize: "12px",
                  verticalAlign: "top",
                }}
              >
                Quantity{" "}
              </th>
              <th
                colSpan={3}
                style={{
                  padding: "10px",
                  border: "1px solid black",
                  borderCollapse: "collapse",
                  fontSize: "12px",
                  verticalAlign: "top",
                }}
              >
                Total{" "}
              </th>
            </tr>

            {orderdetails &&
              orderdetails.productList.map((element, i) => {
                return (
                  <tr style={{ height: "20px" }}>
                    <td
                      style={{
                        padding: "10px",
                        borderRight: "1px solid black",
                        textAlign: "left",
                        fontSize: "12px",
                        verticalAlign: "top",
                        width: "10%",
                      }}
                    >
                      {i + 1}
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        borderRight: "1px solid black",
                        textAlign: "left",
                        fontSize: "12px",
                        verticalAlign: "top",
                        width: "10%",
                      }}
                    >
                      {element.productId?.rwId}
                    </td>
                    <td
                      colSpan={3}
                      style={{
                        padding: "10px",
                        borderRight: "1px solid black",
                        textAlign: "left",
                        fontSize: "12px",
                      }}
                    >
                      {" "}
                      {element.productId?.name}
                    </td>
                    <td
                      colSpan={2}
                      style={{
                        borderRight: "1px solid black",
                        textAlign: "center",
                        fontSize: "12px",
                      }}
                    >
                      ₹{element.productId?.price?.toFixed(2)}
                    </td>
                    <td
                      colSpan={2}
                      style={{
                        borderRight: "1px solid black",
                        textAlign: "center",
                        fontSize: "12px",
                      }}
                    >
                      {element.variant ? (
                        element.variant >= 100 ? (
                          <>
                            {element.variant}gm x {element.count}
                          </>
                        ) : (
                          <>
                            {element.variant} kg x {element.count}
                          </>
                        )
                      ) : (
                        <>{element.count}pcs </>
                      )}
                    </td>
                    <td
                      colSpan={3}
                      style={{
                        textAlign: "center",
                        fontSize: "12px",
                      }}
                    >
                      {" "}
                      {element.variant ? (
                        element.variant >= 100 ? (
                          <>
                            ₹
                            {(
                              element.productId?.price *
                              element.count *
                              (element.variant / 1000)
                            )?.toFixed(2)}
                          </>
                        ) : (
                          <>
                            ₹
                            {(
                              element.productId?.price *
                              element.count *
                              element.variant
                            )?.toFixed(2)}
                          </>
                        )
                      ) : (
                        <>
                          ₹
                          {(element.productId?.price * element.count)?.toFixed(
                            2,
                          )}
                        </>
                      )}
                    </td>
                  </tr>
                );
              })}

            {/* <tr>
              <td
                style={{
                  border: "1px solid black",
                  textAlign: "right",
                  padding: "10px",
                  fontSize: "12px",
                  verticalAlign: "top",
                }}
                colSpan={11}
              ></td>
            </tr> */}

            <tr>
              <td
                style={{
                  border: "1px solid black",
                  textAlign: "right",
                  padding: "10px",
                  fontSize: "12px",
                  verticalAlign: "top",
                  fontWeight: 700,
                }}
                colSpan={8}
              >
                {/* <div>Sub total</div>
                <div>
                  {orderdetails?.totalAmount - deliveryCharge < 2000 && (
                    <div>Delivery</div>
                  )}
                </div> */}
                <div>Total:</div>
              </td>
              <td
                style={{
                  border: "1px solid black",
                  textAlign: "right",
                  padding: "10px",
                  fontSize: "12px",
                  verticalAlign: "top",
                  fontWeight: 700,
                }}
                colSpan={11}
              >
                {/* <div>
                  {orderdetails?.totalAmount -
                    (orderdetails?.totalAmount - deliveryCharge < 2000
                      ? deliveryCharge
                      : 0)}
                </div>
                {orderdetails?.totalAmount - deliveryCharge < 2000 && (
                  <div>+ {deliveryCharge}</div>
                )} */}
                <div>
                  Rs.
                  {orderdetails?.returnAmount}
                </div>
              </td>
            </tr>
          </table>
        </div>
      ) : (
        <></>
      )}

      {orderdetails ? (
        <div
          id="printlabeldiv"
          style={{
            display: "none",
            // margin: "100px"
            marginLeft: "10px",
            marginTop: "10px",
          }}
        >
          <table
            style={{
              border: "1px solid black",
              borderCollapse: "collapse",
              width: "280px",
            }}
          >
            <tr
              style={{
                border: "1px solid black",
                borderCollapse: "collapse",
              }}
            >
              <td
                style={{
                  verticalAlign: "top",
                  textAlign: "left",
                  padding: "10px",
                  fontWeight: 700,
                  fontSize: "12px",
                }}
              >
                {" "}
                Customer : {orderdetails.orderId?.recipientName}
              </td>
            </tr>

            <tr
              style={{
                border: "1px solid black",
                borderCollapse: "collapse",
              }}
            >
              <td
                style={{
                  verticalAlign: "top",
                  textAlign: "left",
                  padding: "10px",
                  fontWeight: 700,
                  fontSize: "12px",
                }}
              >
                {" "}
                Mobile no. : {orderdetails.orderId?.recipientPhoneNo}
              </td>
            </tr>

            <tr
              style={{
                border: "1px solid black",
                borderCollapse: "collapse",
              }}
            >
              <td
                style={{
                  verticalAlign: "top",
                  textAlign: "left",
                  padding: "10px",
                  fontWeight: 700,
                  fontSize: "12px",
                }}
              >
                {" "}
                Return ID : {orderdetails.returnId}
              </td>
            </tr>

            <tr
              style={{
                border: "1px solid black",
                borderCollapse: "collapse",
                height: "193px",
              }}
            >
              <td
                style={{
                  verticalAlign: "top",
                  textAlign: "left",
                  padding: "10px",
                  fontWeight: 700,
                  fontSize: "12px",
                }}
              >
                {" "}
                Delivery Address : {orderdetails.orderId.addressLine}
              </td>
            </tr>
          </table>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default AdminViewReturn;
