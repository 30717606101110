import BagBlack from "../../../../assets/Bag-black.svg";

const Canceled = ({ orders }) => {
  let c = 0;
  orders?.map((item) => {
    if (item.status == "cancelled") c++;
  });

  return (
    <div className="abandoned-main">
      <div className="abandoned-div">
        <img alt="cart-img" src={BagBlack} className="cart-image" />
        {/* <select>
          <option>This Week</option>
          <option>This Month</option>
          <option>This Year</option>
        </select> */}
      </div>
      <div className="abandoned-body">
        <div className="abandoned-body-item">
          <p className="abandoned-body-title">Canceled</p>
          <p className="cart-percentage">{c}</p>
        </div>
        <div className="abandoned-body-item">
          <p className="abandoned-body-title">{orders?.length - c}</p>
          <p className="cart-percentage">0</p>
        </div>
        <div className="abandoned-body-item">
          <p className="abandoned-body-title">Damaged</p>
          <p className="cart-percentage">
            0 <span>+0.00%</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Canceled;
