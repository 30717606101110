import { Box, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { ReactComponent as Checked } from "../../../../assets/checked.svg";
import { ReactComponent as OrderShip } from "../../../../assets/order-ship.svg";
import server from "../../../server";

const CardMainDiv = {
  backgroundColor: "#fff",
  padding: "20px 0px 20px 20px",
  margin: "20px 0px",
};

const CardDiv = {
  display: "flex",
  alignItems: "center",
  background: "#fff",
  margin: "10px 0px 0px 0px",
};

const OrderID = {
  fontSize: "16px",
  fontWeight: "500",
  fontFamily: "Poppins",
};

const OrderDate = {
  fontSize: "13px",
  fontWeight: "400",
  fontFamily: "Poppins",
  color: "#868889",
};

const LineDiv = {
  position: "absolute",
  backgroundColor: "#EBEBEB",
  width: "2px",
  height: "90px",
  bottom: "-90px",
  left: "46%",
};

const TrackOrderCard = ({ orderId }) => {
  //states
  const [orderinfo, setOrderInfo] = useState();

  const getOrderDetails = async () => {
    const result = await axios.get(`${server}/orders/id/${orderId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    // console.log(result.data)
    if (result.data) setOrderInfo(result.data);
    else {
      // console.log("it is return order")
      const returnresult = await axios.get(`${server}/returns/${orderId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      // console.log(returnresult.data)
      setOrderInfo(returnresult.data);
    }
  };

  useEffect(() => {
    getOrderDetails();
  }, []);

  useEffect(() => {}, [orderinfo]);

  return (
    <Box sx={CardMainDiv}>
      {orderinfo ? (
        orderinfo?.returnId ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              background: "#fff",
              //   margin: "30px 0px",
            }}
          >
            <Box
              sx={{
                background: "#EBFFD7",
                padding: "10px",
                borderRadius: "50%",
                height: "50px",
                width: "50px",
                position: "relative",
              }}
            >
              <Checked
                style={{ display: "flex", margin: "0 auto", height: "100%" }}
              />
            </Box>

            <Box
              sx={{
                margin: "0px 0px 0px 20px",
                flexGrow: "1",
                position: "relative",
              }}
            >
              <Typography sx={OrderID}> Return Request</Typography>
              <Typography sx={OrderDate}>Generated Successfully</Typography>

              {/* <Box
                component="hr"
                sx={{
                  position: "absolute",
                  marginTop: "20px",
                  width: "100%",
                  border: "1px solid #EBEBEB",
                }}
              ></Box> */}
            </Box>
          </Box>
        ) : orderinfo.status !== "cancelled" ? (
          <></>
        ) : (
          //   <>
          //     <Box sx={CardDiv}>
          //       <Box
          //         sx={{
          //           background: "#EBFFD7",
          //           padding: "10px",
          //           borderRadius: "50%",
          //           height: "50px",
          //           width: "50px",
          //           position: "relative",
          //         }}
          //       >
          //         <OpenBox
          //           style={{ display: "flex", margin: "0 auto", height: "100%" }}
          //         />
          //         <Box sx={LineDiv}></Box>
          //       </Box>

          //       <Box
          //         sx={{
          //           margin: "0px 0px 0px 20px",
          //           flexGrow: "1",
          //           position: "relative",
          //         }}
          //       >
          //         <Typography sx={OrderID}>Order Placed</Typography>
          //         <Typography sx={OrderDate}>
          //           {orderinfo ? (
          //             <> {orderinfo.createdAt.toString().substring(0, 10)}</>
          //           ) : (
          //             <></>
          //           )}
          //         </Typography>
          //         <Box
          //           component="hr"
          //           sx={{
          //             position: "absolute",
          //             marginTop: "20px",
          //             width: "100%",
          //             border: "1px solid #EBEBEB",
          //           }}
          //         ></Box>
          //       </Box>
          //     </Box>
          //     <Box
          //       sx={{
          //         display: "flex",
          //         alignItems: "center",
          //         background: "#fff",
          //         margin: "30px 0px",
          //       }}
          //     >
          //       <Box
          //         sx={{
          //           background: "#EBFFD7",
          //           padding: "10px",
          //           borderRadius: "50%",
          //           height: "50px",
          //           width: "50px",
          //           position: "relative",
          //         }}
          //       >
          //         <Checked
          //           style={{ display: "flex", margin: "0 auto", height: "100%" }}
          //         />
          //         <Box sx={LineDiv}></Box>
          //       </Box>

          //       <Box
          //         sx={{
          //           margin: "0px 0px 0px 20px",
          //           flexGrow: "1",
          //           position: "relative",
          //         }}
          //       >
          //         <Typography sx={OrderID}>Order Confirmed</Typography>
          //         <Typography sx={OrderDate}>
          //           {orderinfo ? (
          //             orderinfo.status !== "pending" ? (
          //               <> Order Confirmed</>
          //             ) : (
          //               <> Not Yet</>
          //             )
          //           ) : (
          //             <></>
          //           )}
          //         </Typography>

          //         <Box
          //           component="hr"
          //           sx={{
          //             position: "absolute",
          //             marginTop: "20px",
          //             width: "100%",
          //             border: "1px solid #EBEBEB",
          //           }}
          //         ></Box>
          //       </Box>
          //     </Box>
          //     <Box
          //       sx={{
          //         display: "flex",
          //         alignItems: "center",
          //         background: "#fff",
          //         margin: "0px 0px 10px 0px",
          //       }}
          //     >
          //       <Box
          //         sx={{
          //           background: "#EBFFD7",
          //           padding: "10px",
          //           borderRadius: "50%",
          //           height: "50px",
          //           width: "50px",
          //           position: "relative",
          //         }}
          //       >
          //         <OrderShip
          //           style={{ display: "flex", margin: "0 auto", height: "100%" }}
          //         />
          //       </Box>

          //       <Box
          //         sx={{
          //           margin: "0px 0px 0px 20px",
          //           flexGrow: "1",
          //           position: "relative",
          //         }}
          //       >
          //         <Typography sx={OrderID}>Order Shipped</Typography>

          //         <Typography sx={OrderDate}>
          //           {orderinfo ? (
          //             orderinfo.status === "delivered" ||
          //             orderinfo.status === "completed" ? (
          //               <> Order has reached destination</>
          //             ) : (
          //               <>Arriving Soon</>
          //             )
          //           ) : (
          //             <></>
          //           )}
          //         </Typography>
          //       </Box>
          //     </Box>
          //   </>
          <></>
        )
      ) : (
        <></>
      )}
      {orderinfo ? (
        orderinfo.status === "cancelled" ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              background: "#fff",
              margin: "0px 0px 10px 0px",
            }}
          >
            <Box
              sx={{
                background: "#EBFFD7",
                padding: "10px",
                borderRadius: "50%",
                height: "50px",
                width: "50px",
                position: "relative",
              }}
            >
              <OrderShip
                style={{ display: "flex", margin: "0 auto", height: "100%" }}
              />
            </Box>

            <Box
              sx={{
                margin: "0px 0px 0px 20px",
                flexGrow: "1",
                position: "relative",
              }}
            >
              <Typography sx={OrderID}>Order Cancelled</Typography>

              <Typography sx={OrderDate}>Order has been cancelled</Typography>
            </Box>
          </Box>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
    </Box>
  );
};

export default TrackOrderCard;
