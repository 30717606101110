import OrderIcon from "../../../../assets/totalOrder.svg";

const TotalOrder = (props) => {
  let total = 0;
  props.orders?.map((item) => {
    total += item.order.total_cost;
  });
  return (
    <div className="abandoned-main">
      <div className="abandoned-div">
        <img alt="cart-img" src={OrderIcon} />
        {/* <select>
          <option>All-Time</option>
          <option>This Month</option>
          <option>This Year</option>
        </select> */}
      </div>
      <div className="abandoned-body">
        <div className="abandoned-body-item">
          <p className="total-order-text">Total Orders</p>
          <p className="total-order-price">₦{total}</p>
        </div>
      </div>
    </div>
  );
};

export default TotalOrder;
