const containerStyle = {
  fontFamily: "Arial, sans-serif",
  padding: "20px",
};

const headingStyle = {
  fontSize: "2em",
  marginBottom: "20px",
};

const subheadingStyle = {
  fontSize: "1.5em",
  marginTop: "20px",
};

const listStyle = {
  marginLeft: "20px",
};

const listItemStyle = {
  margin: "5px 0",
};
const TermsAndConditions = () => {
  // Inline style objects

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>Terms and Conditions</h1>
      <ul style={listStyle}>
        <li style={listItemStyle}>
          Orders placed after 5pm in the evening will be received and booked for
          the next day.
        </li>
        <li style={listItemStyle}>
          Delivery will be provided within 48 hours after the order is received.
        </li>
        <li style={listItemStyle}>Free Home Delivery</li>
      </ul>
      <div>
        <h2 style={subheadingStyle}>Modes of Payment</h2>
        <ol style={listStyle}>
          <li style={listItemStyle}>Cash on delivery</li>
          <li style={listItemStyle}>Credit card on delivery</li>
          <li style={listItemStyle}>Google Pay</li>
        </ol>
      </div>
      <div>
        <h2 style={subheadingStyle}>Returns and Exchanges</h2>
        <ul style={listStyle}>
          <li style={listItemStyle}>
            Easy and hassle-free returns and exchanges without any questions
            asked, within 7 days.
          </li>
          <li style={listItemStyle}>
            For returns and exchanges, a bill copy is compulsory.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TermsAndConditions;
