import { Navigate } from "react-router-dom";

function isMobileBrowser() {
  console.log(navigator.userAgent, navigator.vendor, window.opera);
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return (
    true ||
    /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
      userAgent,
    )
  );
}

const PrivateRoute = ({ children }) => {
  // const navigate = useNavigate()
  // const location = useLocation();

  return localStorage.getItem("user") &&
    !localStorage.getItem("adminuser") &&
    isMobileBrowser() ? (
    children
  ) : (
    <Navigate to="/login" />
    // <Navigate to={location.pathname} />
  );
};

export default PrivateRoute;
