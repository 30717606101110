import GenericFeedbackForm from "./GenericFeedbackForm";

// Define the specific form configuration for PostDeliveryFeedback
const postDeliveryFeedbackConfig = {
  title: "We Value Your Feedback!",
  campaign_id: "your-campaign-id", // Replace with actual campaign ID
  fields: [
    {
      type: "text",
      name: "deliveryExperience",
      label: "Delivery Experience",
      multiline: true,
      rows: 4,
    },
    // {
    //   type: "rating",
    //   name: "productQuality",
    //   label: "Rate the quality of the product",
    // },
    // {
    //   type: "rating",
    //   name: "customerService",
    //   label: "Rate the customer service",
    // },
    {
      type: "text",
      name: "additionalFeedback",
      label: "Additional feedback or comments",
      multiline: true,
      rows: 4,
    },
  ],
};

const PostDeliveryFeedback = () => {
  return <GenericFeedbackForm formConfig={postDeliveryFeedbackConfig} />;
};

export default PostDeliveryFeedback;
