import {
  Box,
  FormControl,
  InputBase,
  MenuItem,
  Skeleton,
  styled,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DefaultImage from "../../../../assets/defaultimg.jpg";
import server from "../../../server";

const mainCardDiv = {
  display: "flex",
  borderBottom: "2px solid #F0F0F0",
  marginTop: "10px",
  padding: "10px",
  alignItems: "center",
};

const InventoryInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    color: "#000000",
    marginTop: "10px",
  },
  "& .MuiInputBase-input": {
    color: "#808080",
    backgroundColor: "#ffffff",
    borderRadius: "3px",
    border: "1px solid #E0E0E0",
    fontSize: "12px",
    padding: "0px 5px",
  },
}));

const cardTitle = {
  color: "#37474F",
  fontSize: "16px",
  fontWeight: "500",
  fontFamily: "Poppins",
};

const priceText = {
  fontWeight: "600",
  color: "rgba(55, 71, 79, 0.54);",
  textDecoration: "line-through",
  margin: "0",
  fontSize: "16px",
  fontFamily: "Poppins",
};

const salePriceText = {
  color: "#F37A20",
  fontWeight: "600",
  margin: "0",
  fontSize: "22px",
  fontFamily: "Poppins",
};

const salePercentage = {
  fontFamily: "quicksand",
  color: "#fff",
  backgroundColor: "#F37A20",
  borderRadius: "30%",
  position: "absolute",
  padding: "5px",
  fontSize: "14px",
  top: "0",
  left: "0px",
};

const buttonPlusMinus = {
  padding: "8px 18px",
};

const ViewOrderCard = (props) => {
  //passing id of product/variant and the quantity in props as
  // productId quantity

  const [isLoading, setIsLoading] = useState(true);
  const [productinfo, setproductinfo] = useState([]);
  const [variants, setVariants] = useState();

  const fetchProductDetails = async () => {
    try {
      const result = await axios.get(`${server}/products/${props.productId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      console.log(result.data.product);
      setproductinfo(result.data.product);

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } catch (error) {
      if (error.response.data.message === "Product not found") {
        //get from variant collection
        // console.log(" it is a variant ")
        getVariants();
      }
    }
  };

  const getVariants = async () => {
    const result = await axios.get(
      `${server}/products/variant/${props.productId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );
    setVariants(result.data.variantinfo[0]);
    const productresult = await axios.get(
      `${server}/products/${result.data.variantinfo[0].productId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );
    setproductinfo(productresult.data.product);
    // console.log(result.data.variantinfo[0])
    // setQuantity(parseInt(props.proddetail))
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  useEffect(() => {
    fetchProductDetails();
  }, []);

  useEffect(() => {
    console.log({ productinfo: productinfo });
  }, [variants, productinfo, isLoading]);

  return variants ? (
    <Box>
      <Box sx={mainCardDiv}>
        <Box></Box>
        <Box
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {isLoading ? (
            <Skeleton variant="rectangular" width={130} height={118}></Skeleton>
          ) : (
            <Link
              to={`/productdetail/${props.productId}`}
              style={{ textDecoration: "none" }}
            >
              <img
                src={
                  productinfo.imageUrl === "noimage"
                    ? DefaultImage
                    : productinfo.imageUrl
                }
                alt="product-images"
                style={{
                  width: "130px",
                  maxHieght: "20vh",
                  // flex: "25",
                }}
              />
            </Link>
          )}

          <Typography sx={salePercentage}>
            Save ₹{parseInt(variants.mrpPrice - variants.price)}
          </Typography>
        </Box>

        <Box sx={{ flex: "50", padding: "0px 10px" }}>
          {isLoading ? (
            <Skeleton
              variant="text"
              sx={{ fontSize: "16px", width: "100px" }}
            />
          ) : (
            <Typography sx={cardTitle}>{productinfo.name}</Typography>
          )}
          <FormControl size="small" sx={{ width: "100%", margin: "10px 0px" }}>
            <MenuItem> {variants.properties[0].weight} </MenuItem>
          </FormControl>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography sx={priceText}>
                ₹{variants.mrpPrice?.toFixed(2)}
              </Typography>
              <Typography sx={salePriceText}>
                ₹{variants.price?.toFixed(2)}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#F1F1F1",
                  borderRadius: "7px",
                }}
              >
                Quantity: {props.quantity}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  ) : (
    <Box>
      <Box sx={mainCardDiv}>
        <Box></Box>
        <Box
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {isLoading ? (
            <Skeleton variant="rectangular" width={130} height={118}></Skeleton>
          ) : (
            <>
              <Link
                to={`/productdetail/${props.productId}`}
                style={{ textDecoration: "none" }}
              >
                <img
                  src={
                    productinfo.imageUrl === "noimage"
                      ? DefaultImage
                      : productinfo.imageUrl
                  }
                  alt="product-images"
                  style={{
                    maxWidth: "100px",
                    objectFit: "contain",
                  }}
                />
              </Link>

              <Typography sx={salePercentage}>
                Save ₹
                {parseInt(
                  productinfo.mrpPrice - (props.price || productinfo.price),
                )}
              </Typography>
            </>
          )}
        </Box>

        <Box
          sx={{
            flex: "50",
            padding: "0px 10px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={cardTitle}>{productinfo.name}</Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box>
              {props.variant ? (
                props.variant >= 100 ? (
                  <Typography>Weight : {props.variant}gm </Typography>
                ) : (
                  <Typography>Weight : {props.variant}kg </Typography>
                )
              ) : null}
              {props.variant ? (
                props.variant >= 100 ? (
                  <Typography sx={salePriceText}>
                    ₹
                    {(
                      (props.price || productinfo.price) *
                      (props.variant / 1000)
                    )?.toFixed(2)}
                  </Typography>
                ) : (
                  <Typography sx={salePriceText}>
                    ₹
                    {(
                      (props.price || productinfo.price) * props.variant
                    )?.toFixed(2)}
                  </Typography>
                )
              ) : (
                <>
                  {/* {productinfo.mrpPrice !== productinfo.price && (
                    <Typography sx={priceText}>
                      ₹{productinfo.mrpPrice?.toFixed(2)}
                    </Typography>
                  )} */}
                  <Typography sx={salePriceText}>
                    ₹{(props.price || productinfo.price)?.toFixed(2)}
                  </Typography>
                </>
              )}
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#F1F1F1",
                  borderRadius: "7px",
                  padding: "10px",
                }}
              >
                Qty: {props.quantity}
                {/* <IconButton sx={buttonPlusMinus}>+</IconButton> */}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ViewOrderCard;
