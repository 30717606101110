import User from "../../../../assets/User.svg";

const Rating = ({ products }) => {
  let danger = 0;
  products?.map((item) => {
    // if(item.status=="PUBLISH")
    if (item.stocklimit) {
      if (item.stock < item.stocklimit) danger++;
    }
  });

  return (
    <div className="abandoned-main">
      <div className="abandoned-div">
        <img alt="cart-img" src={User} className="cart-image" />
        {/* <select>
          <option>This Week</option>
          <option>This Month</option>
          <option>This Year</option>
        </select> */}
      </div>
      <div className="abandoned-body rating-card">
        <div className="abandoned-body-item rating-card">
          <div>
            <p className="abandoned-body-title">Low Stock Alert</p>
            <p className="cart-percentage">{danger}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rating;
