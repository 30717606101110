import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { Box, Button, IconButton, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import DefaultImage from "../../../assets/defaultimg.jpg";
import server from "../../server";
import ReviewCard from "../ProductDetails/Layout/ReviewCard";
import UserReviewCard from "./Layout/UserReviewCard";

const header = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px 20px 10px 20px",
  backgroundColor: "#fff",
};

const arrowStyle = {
  position: "absolute",
  left: "20px",
};

const CategoryTitle = {
  fontWeight: "600",
  fontFamily: "Quicksand",
};

const ProductDescTitle = {
  fontFamily: "poppins",
  fontSize: "14px",
  fontWeight: "500",
};

const ProductPrice = {
  color: "#ADADAD",
  fontWeight: "600",
  fontFamily: "quicksand",
  marginLeft: "5px",
  fontSize: "14px",
  textDecoration: "line-through",
};

const ProductSalePrice = {
  color: "#F37A20",
  fontSize: "20px",
  marginLeft: "10px",
};

const SalePercentageText = {
  backgroundColor: "#F37A20",
  color: "#fff",
  padding: "3px 10px",
  borderRadius: "3px",
  fontSize: "12px",
  fontWeight: "400",
  fontFamily: "quicksand",
};

const ButtonCart = {
  background:
    "linear-gradient(91deg, rgba(174,220,129,1) 1.21%, rgba(108,197,29,1) 100.55%)",
  color: "#fff",
  textTransform: "none",
  padding: "10px 10px",
  fontSize: "18px",
  fontWeight: "600",
  width: "100%",
  fontFamily: "quicksand",
  "&.MuiButtonBase-root:hover": {
    background:
      "linear-gradient(91deg, rgba(166,213,120,1) 1.21%, rgba(102,198,16,1) 100.55%);",
  },
};

const ReviewPage = () => {
  const { prodid } = useParams();
  const navigate = useNavigate();
  //states
  const [reviews, setReviews] = useState([]);
  const [products, setProducts] = useState([]);
  const [avgreview, setavgreview] = useState();

  const getReviews = async () => {
    try {
      const result = await axios.get(`${server}/products/${prodid}/reviews`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });

      // console.log(result)
      if (result !== null) {
        setReviews(result.data);
        let avg = 0;
        result.data.forEach((review) => {
          avg += review.rating;
        });
        setavgreview(parseFloat(avg / result.data.length).toFixed(0));
      }

      const proddetails = await axios.get(`${server}/products/${prodid}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      if (proddetails !== null) {
        setProducts(proddetails.data.product);
      }
    } catch (error) {
      //   console.log(error.message);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("user")) navigate("/");
    else getReviews();
  }, []);

  useEffect(() => {
    // console.log(products)
  }, [reviews, products, avgreview]);

  return (
    <Box sx={{ marginBottom: "90px" }}>
      <Box sx={header}>
        <Box sx={arrowStyle}>
          <Link to={`/productdetail/${prodid}`}>
            <IconButton>
              <ArrowBackRoundedIcon />
            </IconButton>
          </Link>
        </Box>
        <Typography variant="h6" sx={CategoryTitle}>
          Reviews
        </Typography>
      </Box>
      <Box sx={{ padding: "24px", backgroundColor: "#F4F5F9" }}>
        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "10px 5px",
              backgroundColor: "#fff",
              //   margin: "10px",
            }}
          >
            <img
              src={
                products.imageUrl === "noimage"
                  ? DefaultImage
                  : products.imageUrl
              }
              alt="product"
              style={{ width: "40px" }}
            />
            <Typography sx={ProductDescTitle}>{products.name}</Typography>
            <Box sx={{}}>
              {/* <Typography sx={SalePercentageText}>
                {parseFloat((products.price / products.mrpPrice) * 100).toFixed(
                  2,
                )}
                % OFF
              </Typography> */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                {products.mrpPrice !== products.price && (
                  <Typography variant="subtitle2" sx={ProductPrice}>
                    ₹{products.mrpPrice?.toFixed(2)}
                  </Typography>
                )}
                <Typography sx={ProductSalePrice} variant="h5">
                  ₹{products.price?.toFixed(2)}
                </Typography>
              </Box>
            </Box>
          </Box>
          <ReviewCard
            prodid={prodid}
            average={avgreview}
            total={reviews.length}
          />
          <Box>
            <Link to={`/writereview/${prodid}`}>
              <Button sx={ButtonCart}>Write a Review</Button>
            </Link>
          </Box>
        </Box>
        {reviews &&
          reviews.map((currreview, i) => {
            return <UserReviewCard name={currreview} />;
          })}
      </Box>
    </Box>
  );
};

export default ReviewPage;
