import { Box, Modal, Typography } from "@mui/material";
import { useState } from "react";
import Invalid from "../../../assets/image 13.svg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  height: "auto",
  bgcolor: "background.paper",
  outline: "none",
  display: "flex",
  flexDirection: "column",
  // border: '2px solid white',
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
};

const btnstyle = {};

const InvalidPincode = ({ modalstate }) => {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    modalstate(false);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <img src={Invalid} alt="" style={{ minHeight: "10vh" }} />
        </Box>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ textAlign: "center" }}
        >
          Sorry, we are unable to deliver to your place
        </Typography>
        <Typography
          id="modal-modal-description"
          sx={{ mt: 2, textAlign: "center" }}
        >
          You can place an order an pick it up yourself
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          {/* <Button sx={
                        {
                            backgroundColor: "#5EC401",
                            width: "auto",
                            color: "white",
                            marginBottom: "10px"
                        }
                    }>
                        Order Pick-up
                    </Button>

                    <Button sx={
                        {
                            width: "auto",
                            color: "black",
                            marginBottom: "10px"

                        }
                    }>
                        Back
                    </Button> */}
        </Box>
      </Box>
    </Modal>
  );
};

export default InvalidPincode;
