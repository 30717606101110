import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import JSZip from "jszip";
import PropTypes from "prop-types";
import { useContext, useEffect, useRef, useState } from "react";

import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import {
  alpha,
  Box,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import axios from "axios";
import server from "../../../server";

import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import { visuallyHidden } from "@mui/utils";
import { format } from "date-fns";
import { Link, useNavigate } from "react-router-dom";
import DashboardContext from "../../Context/DashboardContext";
// import Select from '@mui/material/Select';

// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function getComparator(order, orderBy) {
//   return order === "desc"
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function stableSort(array, comparator) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) {
//       return order;
//     }
//     return a[1] - b[1];
//   });
//   return stabilizedThis.map((el) => el[0]);
// }

const headCells = [
  {
    id: "Customer_details",
    numeric: false,
    disablePadding: true,
    label: "Customer details",
  },
  {
    id: "Shipping Address",
    numeric: true,
    disablePadding: false,
    label: "Shipping Address",
  },
  {
    id: "Created Date/Time",
    numeric: true,
    disablePadding: false,
    label: "Created Date/Time",
  },
  {
    id: "Special Message",
    numeric: true,
    disablePadding: false,
    label: "Special Message",
  },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Action",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "assignedTo",
    numeric: true,
    disablePadding: false,
    label: "assignedTo",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            padding={"normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  const ref = useRef();
  const [filterType, setFilterType] = useState(false);
  const [filterModalByDate, setFilterModalByDate] = useState(false);
  const handleOpenModalType = () => {
    setFilterType(!filterType);
  };

  function dataURLtoBlob(dataURL) {
    const byteString = atob(dataURL.split(",")[1]);
    const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }

    return new Blob([arrayBuffer], { type: mimeString });
  }

  const getdoc = async () => {
    let length = props.orderData.length;
    var zip = new JSZip();

    props.setLoading(true);
    for (let i = 0; i < length; i++) {
      let report = new jsPDF("portrait", "pt", "a4");
      await report
        .html(document.querySelector("#a" + i.toString()))
        .then((result) => {
          // report.save('report.pdf');
          var pdfDataUrl = report.output("datauristring");
          console.log(pdfDataUrl);
          zip.file(
            "Order" + props.orderData[i].orderId + "Label.pdf",
            dataURLtoBlob(pdfDataUrl),
          );

          // zip.file("order" + i + ".pdf", report.save("report.pdf"));
        });
    }

    zip.generateAsync({ type: "blob" }).then((content) => {
      // see FileSaver.js
      // console.log("sendin zip")
      saveAs(content, "labels.zip");
    });

    props.setLoading(false);
  };

  const getdocorders = async () => {
    let length = props.orderData.length;
    var zip = new JSZip();
    props.setLoading(true);

    for (let i = 0; i < length; i++) {
      let report = new jsPDF("portrait", "pt", "a4");
      await report
        .html(document.querySelector("#b" + i.toString()))
        .then((result) => {
          // report.save('report.pdf');
          var pdfDataUrl = report.output("datauristring");
          // console.log(pdfDataUrl)
          zip.file(
            "Order" + props.orderData[i].orderId + ".pdf",
            dataURLtoBlob(pdfDataUrl),
          );

          // zip.file("order" + i + ".pdf", report.save("report.pdf"));
        });
    }

    zip.generateAsync({ type: "blob" }).then((content) => {
      // see FileSaver.js
      console.log("sendin zip");
      saveAs(content, "orders.zip");
    });
    props.setLoading(false);
  };

  useEffect(() => {}, []);

  const handlModalFilterByDate = () => {
    setFilterModalByDate(!filterModalByDate);
  };

  const handlesearch = async (e) => {
    // const search_data=[];
    // console.log(props.oriorderData);
    if (e.target.value != "") {
      // console.log(e.target.value);
      // console.log(props.oriorderData);
      const filteredRows = await props.oriorderData.filter((row) => {
        return (
          row?.recipientName
            ?.toLowerCase()
            ?.includes(e.target.value.toLowerCase()) ||
          row?.orderId?.toString()?.includes(e.target.value)
        );
      });

      if (props.status !== "all") {
        const finalstatusrows = await filteredRows.filter((row) => {
          return row?.status === props.status;
        });
        // setRows(filteredRows);
        // console.log(filteredRows);
        props.setOrderData(finalstatusrows);
      } else {
        props.setOrderData(filteredRows);
      }
    } else {
      if (props.status !== "all") {
        const finalstatusrows = await props.oriorderData.filter((row) => {
          return row?.status === props.status;
        });
        props.setOrderData(finalstatusrows);
      } else {
        console.log("all");
        props.setOrderData(props.oriorderData);
      }
      // setRows(filteredRows);
      // console.log(filteredRows);
    }
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity,
            ),
        }),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div>
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{ flex: "1 1 100%" }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              Customer Orders
            </Typography>
          </Box>
        )}
      </div>

      <div>
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip>
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                style={{
                  width: "300px",
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment>
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                size="small"
                placeholder="Search by Customer Name/Order No."
                onChange={handlesearch}
              />
              <div style={{ display: "flex" }}>
                <button
                  onClick={async (e) => {
                    e.preventDefault();
                    getdoc();

                    // document.getElementById("label").style.display = "flex"
                    console.log("display back to normal");
                    // const pdf = await toPdf()
                    // document.getElementById("label").style.display = "none"
                    // console.log(pdf)
                  }}
                  className="table-head-buttons"
                >
                  Print All Labels
                </button>
                <button
                  onClick={async (e) => {
                    e.preventDefault();
                    getdocorders();
                  }}
                  className="table-head-buttons"
                >
                  Print All Orders
                </button>

                <div ref={ref} style={{ display: "none" }}>
                  {props.orderData &&
                    props.orderData.map((row, i) => {
                      return (
                        <table
                          id={"a" + i.toString()}
                          style={{
                            marginLeft: "10px",
                            marginTop: "10px",
                            border: "1px solid black",
                            borderCollapse: "collapse",
                            width: "280px",
                          }}
                        >
                          <tr
                            style={{
                              border: "1px solid black",
                              borderCollapse: "collapse",
                            }}
                          >
                            <td
                              style={{
                                verticalAlign: "top",
                                textAlign: "left",
                                padding: "10px",
                                fontWeight: 700,
                                fontSize: "12px",
                              }}
                            >
                              {" "}
                              Customer : {row.recipientName}
                            </td>
                          </tr>

                          <tr
                            style={{
                              border: "1px solid black",
                              borderCollapse: "collapse",
                            }}
                          >
                            <td
                              style={{
                                verticalAlign: "top",
                                textAlign: "left",
                                padding: "10px",
                                fontWeight: 700,
                                fontSize: "12px",
                              }}
                            >
                              {" "}
                              Mobile no. : {row.recipientPhoneNo}
                            </td>
                          </tr>

                          <tr
                            style={{
                              border: "1px solid black",
                              borderCollapse: "collapse",
                            }}
                          >
                            <td
                              style={{
                                verticalAlign: "top",
                                textAlign: "left",
                                padding: "10px",
                                fontWeight: 700,
                                fontSize: "12px",
                              }}
                            >
                              {" "}
                              Order ID :{" "}
                              {String(props.RWseries) +
                                String(row?.orderId).padStart(6, "0")}
                            </td>
                          </tr>

                          <tr
                            style={{
                              border: "1px solid black",
                              borderCollapse: "collapse",
                              height: "193px",
                            }}
                          >
                            <td
                              style={{
                                verticalAlign: "top",
                                textAlign: "left",
                                padding: "10px",
                                fontWeight: 700,
                                fontSize: "12px",
                              }}
                            >
                              {" "}
                              Delivery Address :{" "}
                              {row.addressLine ? (
                                <>{row.addressLine}</>
                              ) : (
                                <>No Address</>
                              )}
                            </td>
                          </tr>
                        </table>
                      );
                    })}

                  {props.orderData &&
                    props.orderData.map((row, i) => {
                      return (
                        <table
                          id={"b" + i.toString()}
                          style={{
                            border: "1px solid black",
                            borderCollapse: "collapse",
                            height: "800px",
                            width: "580px",
                            marginLeft: "10px",
                            marginTop: "10px",
                          }}
                        >
                          <tr
                            style={{
                              height: "10px",
                            }}
                          >
                            <td
                              colSpan={4}
                              style={{
                                border: "1px solid black",
                                borderCollapse: "collapse",
                                padding: "10px",
                                fontSize: "12px",
                              }}
                            >
                              <span
                                style={{
                                  fontWeight: 700,
                                }}
                              >
                                {" "}
                                RAJDHANI RETAIL
                              </span>{" "}
                              <br></br>
                              GROUND FLOOR, PARINAY MANGAL KARYALAYA, MANIKBAUG,
                              SINHAGAD RD PUNE-53 PH NO. - 8378909909
                            </td>
                            <td
                              style={{
                                border: "1px solid black",
                                borderCollapse: "collapse",
                                padding: "10px",
                                fontSize: "12px",
                                verticalAlign: "top",
                              }}
                              colSpan={7}
                            >
                              ORDER NO.{" "}
                              {String(props.RWseries) +
                                String(row?.orderId).padStart(6, "0")}{" "}
                              <br></br>
                              DATE {row.createdAt.toString().substring(0, 10)}
                            </td>
                          </tr>

                          <tr
                            style={{
                              height: "10px",
                            }}
                          >
                            <td
                              colSpan={3}
                              style={{
                                border: "1px solid black",
                                borderCollapse: "collapse",
                                padding: "10px",
                                fontSize: "12px",
                                verticalAlign: "top",
                                width: "30%",
                              }}
                            >
                              Customer {row.recipientName} <br></br>
                              Mobile No. {row?.userId?.recipientPhoneNo}
                            </td>
                            <td
                              colSpan={1}
                              style={{
                                border: "1px solid black",
                                borderCollapse: "collapse",
                                padding: "10px",
                                fontSize: "12px",
                                verticalAlign: "top",
                              }}
                            >
                              Message <br></br>
                              {row.message}
                            </td>
                            <td
                              style={{
                                border: "1px solid black",
                                borderCollapse: "collapse",
                                padding: "10px",
                                fontSize: "12px",
                                verticalAlign: "top",
                              }}
                              colSpan={7}
                            >
                              Delivery Address <br></br>
                              {row.addressLine ? (
                                <>{row.addressLine}</>
                              ) : (
                                <>No Address</>
                              )}
                            </td>
                          </tr>

                          <tr
                            style={{
                              height: "10px",
                            }}
                          >
                            <th
                              style={{
                                padding: "10px",
                                border: "1px solid black",
                                borderCollapse: "collapse",
                                fontSize: "12px",
                                verticalAlign: "top",
                                textAlign: "center",
                              }}
                            >
                              Sr. No
                            </th>
                            <th
                              style={{
                                padding: "10px",
                                border: "1px solid black",
                                borderCollapse: "collapse",
                                fontSize: "12px",
                                verticalAlign: "top",
                                textAlign: "center",
                              }}
                            >
                              rwId
                            </th>
                            <th
                              colSpan={3}
                              style={{
                                padding: "10px",
                                border: "1px solid black",
                                borderCollapse: "collapse",
                                fontSize: "12px",
                                verticalAlign: "top",
                              }}
                            >
                              {" "}
                              Product Name
                            </th>
                            <th
                              colSpan={2}
                              style={{
                                padding: "10px",
                                border: "1px solid black",
                                borderCollapse: "collapse",
                                fontSize: "12px",
                                verticalAlign: "top",
                              }}
                            >
                              Rate
                            </th>
                            <th
                              colSpan={2}
                              style={{
                                padding: "10px",
                                border: "1px solid black",
                                borderCollapse: "collapse",
                                fontSize: "12px",
                                verticalAlign: "top",
                              }}
                            >
                              Quantity{" "}
                            </th>
                            <th
                              colSpan={3}
                              style={{
                                padding: "10px",
                                border: "1px solid black",
                                borderCollapse: "collapse",
                                fontSize: "12px",
                                verticalAlign: "top",
                              }}
                            >
                              Total{" "}
                            </th>
                          </tr>

                          {row &&
                            row.productList.map((element, i) => {
                              return (
                                <tr
                                  style={{
                                    height: "20px",
                                  }}
                                >
                                  <td
                                    style={{
                                      padding: "10px",
                                      borderRight: "1px solid black",
                                      textAlign: "left",
                                      fontSize: "12px",
                                      verticalAlign: "top",
                                      width: "10%",
                                    }}
                                  >
                                    {i + 1}
                                  </td>
                                  <td
                                    style={{
                                      padding: "10px",
                                      borderRight: "1px solid black",
                                      textAlign: "left",
                                      fontSize: "12px",
                                      verticalAlign: "top",
                                      width: "10%",
                                    }}
                                  >
                                    {element?.productId?.rwId}
                                  </td>
                                  <td
                                    colSpan={3}
                                    style={{
                                      padding: "10px",
                                      borderRight: "1px solid black",
                                      textAlign: "left",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {" "}
                                    {element?.productId?.name}
                                  </td>
                                  <td
                                    colSpan={2}
                                    style={{
                                      borderRight: "1px solid black",
                                      textAlign: "center",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {" "}
                                    {element?.productId?.price}
                                  </td>
                                  <td
                                    colSpan={2}
                                    style={{
                                      borderRight: "1px solid black",
                                      textAlign: "center",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {element.variant ? (
                                      element.variant >= 100 ? (
                                        <>
                                          {" "}
                                          {element.variant}
                                          gm
                                        </>
                                      ) : (
                                        <>
                                          {element?.variant}
                                          kg
                                        </>
                                      )
                                    ) : (
                                      <>
                                        {element?.itemCount}
                                        pcs{" "}
                                      </>
                                    )}
                                  </td>
                                  <td
                                    colSpan={3}
                                    style={{
                                      textAlign: "center",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {" "}
                                    {element.variant ? (
                                      element.variant >= 100 ? (
                                        <>
                                          {" "}
                                          {element?.productId?.price *
                                            element?.itemCount *
                                            (element.variant / 1000)}
                                        </>
                                      ) : (
                                        <>
                                          {element?.productId?.price *
                                            element.itemCount *
                                            element.variant}
                                        </>
                                      )
                                    ) : (
                                      <>
                                        {" "}
                                        {element?.productId?.price *
                                          element.itemCount}
                                      </>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}

                          <tr>
                            <td
                              style={{
                                border: "1px solid black",
                                textAlign: "right",
                                padding: "10px",
                                fontSize: "12px",
                                verticalAlign: "top",
                              }}
                              colSpan={11}
                            ></td>
                          </tr>

                          <tr>
                            <td
                              style={{
                                border: "1px solid black",
                                textAlign: "right",
                                padding: "10px",
                                fontSize: "12px",
                                verticalAlign: "top",
                                fontWeight: 700,
                              }}
                              colSpan={11}
                            >
                              Total:₹
                              {row.totalAmount?.toFixed(2)}
                            </td>
                          </tr>
                        </table>
                      );
                    })}
                </div>
              </div>
            </div>
          </Tooltip>
        )}
      </div>
      {/* <div className="modaltype-div">
        <ModalType filterType={filterType} oriorderData={props.oriorderData} setOrderData={props.setOrderData} orderData={props.orderData} />
      </div>
      <div className="modalfilterbydate-div">
        <ModalFilterByDate filterModalByDate={filterModalByDate} />
      </div> */}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const OrderTable = ({ getOrder, orderstatus, functionchange }) => {
  const ref = useRef();
  const navigate = useNavigate();
  const [RWseries, setRWSeries] = useState("");
  const [order_type, setOrder_type] = useState("");
  // console.log(getOrder);
  const context = useContext(DashboardContext);
  const { setLoading } = context;
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [orderData, setOrderData] = useState();
  // setOrderData([...getOrder])
  const [oriorderData, setOriOrderData] = useState([...getOrder].reverse());
  // console.log(getOrder);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [products_data, setProducts_data] = useState([]);

  const requestOptions = {
    headers: {
      method: "GET",
      Authorization: `Bearer ${localStorage.getItem("access")}`,
    },
  };
  const [product, setProduct] = useState(0);
  const [users, setUsers] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    fetchSeries();
    fetchEmployees();
    // setOrderData([...getOrder])
    if (orderstatus !== "all")
      setOrderData(oriorderData.filter((item) => item.status === orderstatus));
    else setOrderData(oriorderData);
  }, []);

  useEffect(() => {
    // console.log("new")
    // console.log(orderData)
  }, [orderstatus, orderData]);

  const fetchSeries = async () => {
    const newseries = await axios.get(`${server}/admin/orders/get/series`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });

    setRWSeries(newseries.data.series);
  };

  // console.log(getOrder);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orderData?.length) : 0;

  const tableRef = useRef(null);
  const [Edit, setEdit] = useState({});
  const [trackingID, setTrackingID] = useState({});

  const handleEdit = (key, value) => {
    setEdit({ ...Edit, [key]: value });
  };
  const [status, setStatus] = useState({});

  const handleStatus = async (value, id, userId) => {
    // const token = `Bearer ${localStorage.getItem("access")}`
    // setLoading(true);
    const order_update = { status: value };
    // console.log(id);

    //api to change status
    try {
      const updatestatus = await axios.put(
        `${server}/orders/update/${id}`,
        {
          status: value,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        },
      );

      //send notificaton acc to status update
      let message;
      switch (value) {
        case "inprogress":
          message = "Order is Out For Delivery";
          break;

        case "delivered":
          message = "Order has reached you";
          break;

        case "cancelled":
          message = "Order has been cancelled";
          break;
      }

      const notification = await axios.post(
        `${server}/notifications/send`,
        {
          userId: userId,
          orderId: id,
          message: message,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        },
      );
      // console.log(updatestatus)
      functionchange();
    } catch (error) {
      console.log(error);
    }
  };

  const fetchEmployees = async () => {
    try {
      const result = await axios.get(`${server}/employees`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      console.log({ users: result.data });
      const activeEmployees = result.data.filter(
        (employee) => employee.isActive,
      );
      setEmployees(activeEmployees);
    } catch (error) {
      console.log(error);
    }
  };
  const handleChange = async (e, row) => {
    const employeeId = e.target.value;
    const orderId = row._id;
    if (employeeId) {
      try {
        const result = await axios.post(
          `${server}/employee-orders?employeeId=${employeeId}&orderId=${orderId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          },
        );
        functionchange();
        console.log("Created employee order is", result.data);
      } catch (error) {
        console.log("Error creating employee order", error);
      }
      console.log("Selected employee", selectedEmployee);
    }
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          RWseries={RWseries}
          product={product}
          setProduct={setProduct}
          products_data={products_data}
          tableRef={tableRef}
          orderData={orderData}
          setOrderData={setOrderData}
          oriorderData={oriorderData}
          order_type={order_type}
          status={orderstatus}
          setOrder_type={setOrder_type}
          setLoading={setLoading}
        />

        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            // aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            exportButton={true}
            ref={tableRef}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              ref={ref}
              order={order}
              orderBy={orderBy}
              // onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={orderData?.length}
            />
            <TableBody>
              {(rowsPerPage > 0
                ? orderData?.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage,
                  )
                : orderData
              )?.map((row, index) => {
                // handleEdit(index,false);
                const isItemSelected = isSelected(row.name);
                const labelId = `enhanced-table-checkbox-${index}`;
                {
                  return row ? (
                    <TableRow
                      hover
                      // onClick={(event) => {
                      //   event.preventDefault()
                      //   navigate(`/admin/vieworder/${row._id}`)
                      // }}
                      // role="checkbox"
                      // aria-checked={isItemSelected}

                      tabIndex={-1}
                      key={row.id}
                      // selected={isItemSelected}
                      sx={{ cursor: "pointer" }}
                      className={"row" + index}
                    >
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="normal"
                        sx={{ minWidth: "10rem" }}
                      >
                        {
                          // <GetUserDetail id={row.order.user} />
                          <Link
                            to={`/admin/vieworder/${row._id}`}
                            style={{
                              textDecoration: "none",
                              color: "black",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                fontSize: "14px",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                  }}
                                >
                                  User ID:
                                </Typography>
                                <Typography>
                                  {" "}
                                  {row?.userId?._id
                                    ?.toString()
                                    ?.substring(0, 6) ?? "null"}{" "}
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    color: "grey",
                                  }}
                                >
                                  User Name:
                                </Typography>
                                <Typography> {row.recipientName} </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    color: "grey",
                                  }}
                                >
                                  Phone No.:
                                </Typography>
                                <Typography>
                                  {" "}
                                  {row.recipientPhoneNo}{" "}
                                </Typography>
                              </Box>
                            </Box>
                          </Link>
                        }
                      </TableCell>
                      <TableCell align="left">
                        {!row.addressLine ? (
                          <>Address Deleted</>
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  color: "grey",
                                }}
                              >
                                Location Type:{" "}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                }}
                              >
                                {row.locationType}{" "}
                              </Typography>
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  color: "grey",
                                }}
                              >
                                Address Line:{" "}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                }}
                              >
                                {row.addressLine}{" "}
                              </Typography>
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  color: "grey",
                                }}
                              >
                                PinCode:{" "}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                }}
                              >
                                {row.pincode}{" "}
                              </Typography>
                            </Box>
                          </Box>
                        )}
                      </TableCell>

                      <TableCell align="left">
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                            }}
                          >
                            Date:{" "}
                            {format(
                              new Date(row.createdAt),
                              "dd MMMM yyyy - hh:mm a",
                            )}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                            }}
                          >
                            Order Total: ₹{row.totalAmount?.toFixed(2)}{" "}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                            }}
                          >
                            Order Id:{" "}
                            {String(RWseries) +
                              String(row?.orderId).padStart(6, "0")}{" "}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        {row.message ? (
                          <Typography
                            sx={{
                              fontSize: "14px",
                            }}
                          >
                            {row.message}{" "}
                          </Typography>
                        ) : (
                          <>None</>
                        )}
                      </TableCell>
                      <TableCell align="left">
                        <select
                          className="table-action-select"
                          defaultValue={row.status}
                          onChange={(e) => {
                            e.stopPropagation();
                            handleStatus(e.target.value, row._id, row?.userId);
                          }}
                        >
                          <option value={"pending"}>Pending</option>
                          <option value={"inprogress"}>InProgress</option>
                          <option value={"cancelled"}>Cancelled</option>
                          <option value={"delivered"}>Delivered</option>
                        </select>
                      </TableCell>
                      <TableCell>{row.status}</TableCell>
                      <TableCell>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          sx={{
                            minWidth: "10vw",
                            marginTop: "1px",
                            border: "none",
                            backgroundColor:
                              row.assignedTo === "Not Assigned"
                                ? "#FF4D4D"
                                : "#EFF1F9",
                          }}
                          value={
                            row.assignedTo === "Not Assigned" ||
                            row.assignedTo === "Deleted User"
                              ? row.assignedTo
                              : row.assignedTo
                          }
                          onChange={(e) => handleChange(e, row)}
                          defaultValue={0}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 224,
                              },
                            },
                          }}
                        >
                          {row.assignedTo === "Not Assigned" ? (
                            <MenuItem value="Not Assigned">
                              Not Assigned
                            </MenuItem>
                          ) : row.assignedTo === "Deleted User" ? (
                            <MenuItem value="Deleted User">
                              Deleted User
                            </MenuItem>
                          ) : (
                            <MenuItem value={row.assignedTo}>
                              {row.assignedTo}
                            </MenuItem>
                          )}

                          {employees.map((emp) => (
                            <MenuItem key={emp.id} value={emp._id}>
                              {emp.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <></>
                  );
                }
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25]}
          component="div"
          count={orderData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>
    </Box>
  );
};

export default OrderTable;
