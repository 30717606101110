// Inline styles for the component
const containerStyle = {
  fontFamily: "Arial, sans-serif",
  padding: "20px",
  lineHeight: "1.6",
  margin: "16px 0",
  marginBottom: 100,
};

const sectionStyle = {
  marginBottom: "20px",
};

const headingStyle = {
  fontSize: "1.5em",
  marginBottom: "10px",
};

const listStyle = {
  marginLeft: "20px",
};

const listItemStyle = {
  margin: "5px 0",
};

const About = () => {
  return (
    <div style={containerStyle}>
      <section style={sectionStyle}>
        <h1 style={headingStyle}>About Urban Bazaar</h1>
        <p>
          Urban Bazaar is a 1.5-year-old Pune based supermarket brand which
          operates in the Manik Baug area, on Sinhgad Road in Pune city. The
          brand (launched in January 2019) started as a 6000 sq.ft physical
          store comprising of two floors – one the food section and the other,
          the non-food section. After the success of its first store, the
          company Rajdhani Retail (which owns the brand Urban Bazaar), decided
          to launch its e-commerce platform to cater to the other parts of Pune.
        </p>
      </section>
      <section style={sectionStyle}>
        <h1 style={headingStyle}>What Does Urban Bazaar Offer?</h1>
        <p>
          Urban Bazaar offers a range of 5000+ products from various categories
          covering over 500+ brands, and daily discounts of 4% to 55% on all
          those products. Some of the brands that we offer are: Amul, Cadbury,
          Surf Excel, Tide, Gillette, Pantene, Head & Shoulders, Dove,
          Britannia, Parle, ITC, Nestle, Colgate, Haldirams, ACTII, Horlicks,
          Lays, Nivea, Patanjali, Saffola, Parachute; and many many more.
        </p>
      </section>
      <section style={sectionStyle}>
        <h1 style={headingStyle}>Delivery Options</h1>
        <ol style={listStyle}>
          <li style={listItemStyle}>
            Pick-up from store: Order online from the app and pick up your order
            from the Urban Bazaar store at Manik Baug, Sinhagad Road, Pune.
          </li>
          <li style={listItemStyle}>
            Home deliveries: Place your order online and get free delivery to
            your home or office within 48 hours. Modes of payment – Cash/Card on
            Delivery or Google Pay.
          </li>
        </ol>
      </section>
      <section style={sectionStyle}>
        <h1 style={headingStyle}>Our Key Category</h1>
        <ul style={listStyle}>
          <li style={listItemStyle}>
            Daily groceries and staples such as grains, rice, dals, masalas,
            spices, cooking oil, ghee & others at Market Yard’s price.
          </li>
        </ul>
      </section>
      <section style={sectionStyle}>
        <h1 style={headingStyle}>Other Categories</h1>
        <ul style={listStyle}>
          <li style={listItemStyle}>
            Food Products – biscuits, packed spices, tea, coffee, confectionery,
            bakery items.
          </li>
          <li style={listItemStyle}>
            Personal Care Products – skin care, hair care, baby care, and other
            personal utility and hygiene products.
          </li>
          <li style={listItemStyle}>
            Household Need Items – floor cleaners, cleaning tools, detergents,
            etc.
          </li>
          <li style={listItemStyle}>
            Plastic Section – bathroom accessories and kits.
          </li>
        </ul>
      </section>
      <section style={sectionStyle}>
        <h1 style={headingStyle}>Why Shop from Urban Bazaar?</h1>
        <p>
          Urban Bazaar’s USP is the superior quality of their grocery items
          (dals, rice, pulses, dry fruits), which are priced at least 5-7% lower
          than the average retail price. We are able to offer low prices and
          high-quality due to the strong procurement (buying) abilities of our
          parent company operating in the main food grains market of Pune. Our
          parent company, Delhi Foods Corporation, run by the 4th generation in
          this food grains industry, has been operating in Pune for the past 30
          years, catering to the hospitality and food retail sector.
        </p>
        <p>
          Along with our grocery items, we offer daily discounts (4% to 55%) on
          all branded items, and you can get all this from the comfort of your
          home.
        </p>
      </section>
      <section style={sectionStyle}>
        <h1 style={headingStyle}>How Do You Place Your Order?</h1>
        <p>
          All you have to do is download the app – Urban Bazaar Pune from the
          Google Play Store, browse through the app or search for products, add
          to cart, enter your name and address, place your order, and relax.
        </p>
        <p>
          Or you can call our customer care number{" "}
          <strong>
            <a href="tel:+918378909909">8378-909-909</a>
          </strong>{" "}
          and they will assist you.
        </p>
      </section>
    </div>
  );
};

export default About;
