import { Box, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { ReactComponent as CloseBox } from "../../../../assets/close-box.svg";
import server from "../../../server";

const CardMainDiv = {
  display: "flex",
  alignItems: "center",
  background: "#fff",
  padding: "20px",
};

const OrderID = {
  fontSize: "16px",
  fontWeight: "500",
  fontFamily: "Poppins",
  display: "flex",
  flexWrap: "wrap",
  overflow: "hidden",
};

const OrderDate = {
  fontSize: "13px",
  fontWeight: "400",
  fontFamily: "Poppins",
  color: "#868889",
};

const ItemText = {
  fontSize: "13px",
  fontWeight: "400",
  fontFamily: "Poppins",
};

const ItemCount = {
  fontSize: "13px",
  fontWeight: "700",
  fontFamily: "Poppins",
};

const ItemTextPrice = {
  fontSize: "13px",
  fontWeight: "400",
  fontFamily: "Poppins",
  margin: "0px 0px 0px 20px",
};

const OrderCard = ({ orderId }) => {
  //states
  const [orderinfo, setOrderInfo] = useState();
  const [RWseries, setSeries] = useState();

  const getOrderDetails = async () => {
    const seriesnumber = await axios.get(`${server}/admin/orders/get/series`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    setSeries(seriesnumber.data.series);
    const result = await axios.get(`${server}/orders/id/${orderId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    // console.log(result.data)

    if (result.data) setOrderInfo(result.data);
    else {
      console.log("it is return order");
      const returnresult = await axios.get(`${server}/returns/${orderId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      // console.log(returnresult.data)
      setOrderInfo(returnresult.data);
    }
  };

  useEffect(() => {
    getOrderDetails();
  }, []);

  useEffect(() => {}, [orderinfo]);

  return (
    <Box>
      <Box sx={CardMainDiv}>
        <Box
          sx={{
            background: "#EBFFD7",
            padding: "10px",
            borderRadius: "50%",
            height: "50px",
            width: "50px",
          }}
        >
          <CloseBox
            style={{
              display: "flex",
              margin: "0 auto",
              height: "100%",
            }}
          />
        </Box>
        <Box sx={{ margin: "0px 0px 0px 20px" }}>
          <Typography sx={OrderID}>
            Order #
            {orderinfo ? (
              orderinfo.returnId ? (
                <> {orderinfo.returnId}</>
              ) : (
                <>
                  {" "}
                  {String(RWseries) +
                    String(orderinfo.orderId).padStart(6, "0")}{" "}
                </>
              )
            ) : (
              <></>
            )}
          </Typography>
          <Typography sx={OrderDate}>
            Placed on{" "}
            {orderinfo ? (
              <> {orderinfo.createdAt.toString().substring(0, 10)}</>
            ) : (
              <></>
            )}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography sx={ItemText}>
              Items:
              <Box component="span" sx={ItemCount}>
                {orderinfo ? (
                  orderinfo.productList ? (
                    <> {orderinfo.productList.length}</>
                  ) : (
                    <> {orderinfo.variantList.length}</>
                  )
                ) : (
                  <></>
                )}
              </Box>
            </Typography>
            <Typography sx={ItemTextPrice}>
              Amount:{" "}
              <Box component="span" sx={ItemCount}>
                ₹
                {orderinfo ? (
                  orderinfo.totalAmount ? (
                    <>{orderinfo.totalAmount?.toFixed(2)}</>
                  ) : (
                    <> {orderinfo.returnAmount}</>
                  )
                ) : (
                  <></>
                )}
              </Box>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default OrderCard;
