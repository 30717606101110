import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import { alpha, useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { visuallyHidden } from "@mui/utils";
import axios from "axios";
import jsPDF from "jspdf";
import PropTypes from "prop-types";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProfileIcon from "../../../assets/Profile.svg";
import server from "../../server";
import DashboardContext from "../Context/DashboardContext";
const header = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "0px",
  margin: "0 10px",
  //   marginBottom: "50px",
  // width: "100%"
};

const headerlefttitle = {
  fontSize: "16px",
  fontWeight: "600",
  // color: "black"
};

const headerleftcontent = {
  fontSize: "16px",
  fontWeight: "500",
  color: "black",
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const headCells = [
  {
    id: "productname",
    numeric: false,
    disablePadding: true,
    label: "Product Name",
  },
  {
    id: "productId",
    numeric: true,
    disablePadding: false,
    label: "rwId",
  },
  {
    id: "unitprice",
    numeric: true,
    disablePadding: false,
    label: "Unit Price",
  },
  {
    id: "qty",
    numeric: false,
    disablePadding: true,
    label: "Qty",
  },
  {
    id: "total",
    numeric: true,
    disablePadding: false,
    label: "Order Total",
  },
];

//this is the header of table basically showing names of the columns and the sort buttons for each
function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ borderTop: "1px solid #E1E2E9" }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "center" : "center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

//this is for search functionality in table
function EnhancedTableToolbar(props) {
  const navigate = useNavigate();
  const { numSelected } = props;

  const handlesearch = async (e) => {
    // const search_data=[];
    if (e.target.value != "") {
      // console.log(e.target.value);
      // console.log(props.orirows);
      const filteredRows = await props.orirows.filter((row) => {
        return row.first_name
          ?.toLowerCase()
          ?.includes(e.target.value.toLowerCase());
      });
      // setRows(filteredRows);
      // console.log(filteredRows);
      props.setRows(filteredRows);
    } else props.setRows(props.orirows);
  };

  useEffect(() => {
    const fn = () => {
      try {
        document.getElementById("printtablediv").div.style.display = "block";
      } catch (e) {
        setTimeout(() => fn(), 100);
      }
    };
    fn();
  }, []);

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity,
            ),
        }),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div>
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Items: {props.rows.length}
          </Typography>
        )}
      </div>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          sx={{
            textTransform: "none",
            color: "black",
            border: 1,
            marginRight: "10px",
          }}
          onClick={async (e) => {
            e.preventDefault();

            // console.log('print');
            props.print();
          }}
        >
          Print Label
        </Button>{" "}
        <ReactToPrint
          content={() => props.orderTableRef.current}
          onBeforeGetContent={() => {
            props.orderTableRef.current.style.display = "block";
          }}
          trigger={() => {
            return (
              <Button
                sx={{
                  textTransform: "none",
                  color: "black",
                  border: 1,
                  marginRight: "10px",
                }}
              >
                Print Order
              </Button>
            );
          }}
          documentTitle={"Order-" + props?.orderdetails?.orderId + ".pdf"}
          onAfterPrint={() => {
            props.orderTableRef.current.style.display = "none";
          }}
        />
      </div>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const AdminViewOrder = () => {
  // console.log(props.rows);
  //rows are basically your data
  const context = useContext(DashboardContext);
  const { setLoading, setGoBack } = context;
  const [RWseries, setRWSeries] = useState("");

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [active_users, setActive_users] = useState(0)
  const [orirows, setorirows] = useState([]);

  const [isPrintingTable, setprintingTable] = useState(false);

  const { orderId } = useParams();

  //   //states
  const [orderdetails, setOrderDetails] = useState();
  const [prodList, setProdList] = useState();

  const navigate = useNavigate();

  const fetchSeries = async () => {
    const newseries = await axios.get(`${server}/admin/orders/get/series`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });

    setRWSeries(newseries.data.series);
  };

  //   //get order detail by order id
  //   //need user and address populated
  const getOrderDetails = async () => {
    setGoBack(true);
    const result = await axios.get(`${server}/admin/orders/${orderId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    // console.log(result.data.productList)
    setOrderDetails(result.data);
    setProdList(result.data.productList);
  };

  const print = async () => {
    setLoading(true);
    let report = new jsPDF("portrait", "pt", "a4");
    document.getElementById("orderlabel").style.display = "flex";
    await report.html(document.querySelector("#orderlabel")).then(() => {
      report.save("OrderLabel" + orderdetails.orderId + ".pdf");
      // zip.file("order" + i + ".pdf", report.save("report.pdf"));
    });
    document.getElementById("orderlabel").style.display = "none";
    setLoading(false);
  };

  const printorder = async () => {
    setLoading(true);

    // let report = new jsPDF("portrait", "pt", "a4");

    document.getElementById("printtablediv").style.display = "block";
    // await report.html(document.querySelector("#printtablediv")).then(() => {
    //   report.save("Order" + orderdetails.orderId + ".pdf");
    //   // zip.file("order" + i + ".pdf", report.save("report.pdf"));
    // });
    // document.getElementById("printtablediv").style.display = "none";
    setLoading(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - prodList.length) : 0;

  const handleChange = async (value) => {
    //api to change status
    try {
      const updatestatus = await axios.put(
        `${server}/orders/update/${orderId}`,
        {
          status: value,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        },
      );
      // console.log(updatestatus)

      //send notificaton acc to status update
      let message;
      switch (value) {
        case "inprogress":
          message = "Order is Out For Delivery";
          break;

        case "delivered":
          message = "Order has reached you";
          break;

        case "cancelled":
          message = "Order has been cancelled";
          break;
      }

      const notification = await axios.post(
        `${server}/notifications/send`,
        {
          userId: orderdetails?.userId?._id,
          orderId: orderdetails._id,
          message: message,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        },
      );

      getOrderDetails();
      toast.success("Status succesfully changed", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      // }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchSeries();

    if (!localStorage.getItem("adminuser")) navigate("/");
    else getOrderDetails();
  }, []);

  useEffect(() => {
    // console.log(isPrintingTable)
  }, [orirows, orderdetails, prodList, isPrintingTable]);

  const orderTableRef = useRef();

  const commonTdStyle = (i) => ({
    paddingBottom:
      (i + 1) % 30 === 0 && orderdetails?.productList?.length - i > 5
        ? "100px"
        : "8px",
    paddingTop:
      i !== 0 && (i + 1) % 30 === 1 && orderdetails?.productList?.length - i > 5
        ? "100px"
        : 0,
    paddigLeft: "8px",
    textAlign: "left", // Default textAlign
    fontSize: "12px", // Default fontSize
    fontWeight: "bold",
  });

  const CustomTD = ({ style, colSpan, children, index }) => (
    <td
      colSpan={colSpan}
      style={{
        ...commonTdStyle(index),
        ...(style ?? {}),
        borderRight: "1px solid black",
        verticalAlign: (style ?? {}).verticalAlign || "middle", // Default verticalAlign
        width: (style ?? {}).width || "10%", // Default width
      }}
    >
      {children}
    </td>
  );

  const minValueForFreeDelivery = 999;
  const deliveryCharge = 50;
  const deliveryChargeApplicable =
    orderdetails?.totalAmount < minValueForFreeDelivery;

  return (
    <div
      style={
        {
          // padding:"20px"
          // marginLeft: "100px"
        }
      }
    >
      <ToastContainer />
      <Box sx={header}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              marginRight: "10px",
              gap: 1,
            }}
          >
            <Typography sx={headerlefttitle}>Order Number: </Typography>
            <Typography sx={headerleftcontent}>
              {" "}
              {orderdetails ? (
                <>
                  {" "}
                  #
                  {String(RWseries) +
                    String(orderdetails?.orderId).padStart(6, "0")}
                </>
              ) : (
                <></>
              )}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              marginRight: "10px",
              gap: 1,
            }}
          >
            <Typography sx={headerlefttitle}>Order Date:</Typography>
            <Typography sx={headerleftcontent}>
              {" "}
              {orderdetails ? (
                <> {orderdetails.createdAt.toString().substring(0, 10)}</>
              ) : (
                <></>
              )}
            </Typography>
          </Box>
        </Box>

        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <FormControl>
              <InputLabel id="demo-simple-select-label">
                Change Status
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                sx={{ width: "15vw" }}
                // value={"pending"}
                label="Status"
                onChange={(e) => handleChange(e.target.value)}
              >
                <MenuItem value={"pending"}>Pending</MenuItem>
                <MenuItem value={"inprogress"}>In Progress</MenuItem>
                <MenuItem value={"delivered"}>Delivered</MenuItem>
                <MenuItem value={"cancelled"}>Cancelled</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Box>
      {orderdetails ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            height: "100%",
            padding: "10px",
            // marginBottom: "10vh",
            gap: 2,
          }}
        >
          {/* //1st box */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              backgroundColor: "white",
              borderRadius: "10px",
              padding: "10px",
              //   width: "30%",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <img src={ProfileIcon} alt="" />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "nowrap",
                    gap: 1,
                  }}
                >
                  <Typography>{orderdetails.recipientName}</Typography>
                  <div>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: "12px",
                        color: "grey",
                        display: "flex",
                      }}
                    >
                      Customer Since:{" "}
                      {orderdetails?.userId?.createdAt
                        ?.toString()
                        ?.substring(0, 10)}
                    </Typography>
                  </div>
                </Box>
              </Box>
              <Typography
                sx={{
                  backgroundColor: "#FFF2E2",
                  borderRadius: "10px",
                  padding: "8px",
                  height: "fit-content",
                  // height: "auto",
                }}
              >
                {orderdetails.status}
              </Typography>
            </Box>
            <Box>
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Typography>Phone No: </Typography>
                <Typography>{orderdetails.recipientPhoneNo}</Typography>
              </Box>
            </Box>
          </Box>

          {/* 2nd box */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              backgroundColor: "white",
              borderRadius: "10px",
              padding: "10px",
              height: "auto",
              //   width: "30%",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                color: "grey",
              }}
            >
              Shipping Address
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Typography
                sx={{
                  color: "grey",
                }}
              >
                LocationType :{" "}
              </Typography>
              <Typography>{orderdetails.locationType}</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              <Typography
                sx={{
                  color: "grey",
                }}
              >
                Address Line{" "}
              </Typography>
              <Typography>{orderdetails.addressLine}</Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Typography
                sx={{
                  color: "grey",
                }}
              >
                Pincode:{" "}
              </Typography>
              <Typography>{orderdetails.pincode}</Typography>
            </Box>
          </Box>

          {/* 3rd box
           */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              // justifyContent: "space-between",
              //   width: "30%",
              backgroundColor: "white",
              borderRadius: "10px",
              padding: "10px",
              width: "100%",
            }}
          >
            <Typography>Special Message </Typography>
            <Typography>{orderdetails.message}</Typography>
          </Box>
        </Box>
      ) : (
        // {/* basic details 3 box ended above */}
        <></>
      )}
      {prodList ? (
        <Box sx={{ display: "flex", margin: "0 10px" }}>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <EnhancedTableToolbar
              numSelected={selected.length}
              rows={prodList}
              setRows={setProdList}
              orirows={[]}
              setisprintingtable={setprintingTable}
              print={print}
              printorder={printorder}
              RWSeries={RWseries}
              orderTableRef={orderTableRef}
              orderdetails={orderdetails}
            />
            <TableContainer style={{ padding: "0px 20px" }}>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  // onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={prodList?.length}
                />
                <TableBody>
                  {(rowsPerPage > 0
                    ? prodList?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage,
                      )
                    : prodList
                  )?.map((row, index) => {
                    // const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    // if(row?.is_active=="true")
                    // props.setActive_users(props.active_users+1);

                    return (
                      <TableRow
                        hover
                        // onClick={(event) => handleClick(event, row.name)}
                        role="checkbox"
                        // aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.name}
                        // selected={isItemSelected}
                        sx={{ cursor: "pointer" }}
                        style={{
                          border: "1px solid transparent",
                        }}
                      >
                        <TableCell
                          align="center"
                          style={{
                            border: "1px solid transparent",
                          }}
                        >
                          {row.productId.name}
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{
                            border: "1px solid transparent",
                          }}
                        >
                          {row.productId.rwId}
                        </TableCell>

                        <TableCell
                          padding="none"
                          align="center"
                          style={{
                            border: "1px solid transparent",
                          }}
                        >
                          {row?.price || row.productId.price}
                        </TableCell>
                        <TableCell
                          padding="none"
                          align="center"
                          style={{
                            border: "1px solid transparent",
                          }}
                        >
                          {/* {row.itemCount} */}
                          {row.variant ? (
                            row.variant >= 100 ? (
                              <>
                                {row.variant}gm x {row.itemCount}
                              </>
                            ) : (
                              <>
                                {row.variant}kg x {row.itemCount}
                              </>
                            )
                          ) : (
                            <>{row.itemCount}pcs </>
                          )}
                        </TableCell>
                        <TableCell
                          padding="none"
                          align="center"
                          style={{
                            border: "1px solid transparent",
                          }}
                        >
                          {/* {row.itemCount * row.productId.price} */}
                          {row.variant ? (
                            row.variant >= 100 ? (
                              <>
                                {" "}
                                ₹
                                {(
                                  (row?.price || row.productId.price) *
                                  //row.productId.price *
                                  row.itemCount *
                                  (row.variant / 1000)
                                ).toFixed(1)}
                              </>
                            ) : (
                              <>
                                ₹
                                {(
                                  (row?.price || row.productId.price) *
                                  //row.productId.price *
                                  row.itemCount *
                                  row.variant
                                )?.toFixed(2)}
                              </>
                            )
                          ) : (
                            <>
                              ₹
                              {(row?.price || row.productId.price) *
                                //row.productId.price
                                row.itemCount}
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}

                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell padding="10px" align="center">
                      Total:₹{orderdetails.totalAmount?.toFixed(2)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 25]}
              component="div"
              count={prodList?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </Paper>
        </Box>
      ) : (
        <></>
      )}

      <div
        id="printtablediv"
        style={{
          display: "none",
          // marginTop: "40px",
          // // marginTop:"100px"
          padding: 20,
          // height: "600px",
          // width: "540px",
          // marginLeft: "10px",
          margin: "20px 10px",
        }}
        ref={orderTableRef}
      >
        <table
          style={{
            border: "1px solid black",
            borderCollapse: "collapse",
          }}
        >
          <tr style={{ height: "16px", marginTop: 10 }}>
            <td
              colSpan={4}
              style={{
                border: "1px solid black",
                borderCollapse: "collapse",
                padding: "4px",
                fontSize: "16px",
                verticalAlign: "top",
              }}
            >
              <span style={{ fontWeight: 700 }}>
                {" "}
                {orderdetails?.recipientName +
                  " " +
                  orderdetails?.recipientPhoneNo}{" "}
              </span>
              <br></br>
              {orderdetails?.addressLine + " " + orderdetails?.pincode}{" "}
            </td>
            <td
              style={{
                border: "1px solid black",
                borderCollapse: "collapse",
                padding: "4px",
                fontSize: "16px",
                verticalAlign: "top",
              }}
              colSpan={7}
            >
              ORDER NO.{" "}
              {String(RWseries) +
                String(orderdetails?.orderId).padStart(6, "0")}{" "}
              <br></br>
              DATE {orderdetails?.createdAt.toString().substring(0, 10)}
            </td>
          </tr>

          <tr style={{ height: "10px" }}>
            {/* <td
              colSpan={1}
              style={{
                border: "1px solid black",
                borderCollapse: "collapse",
                padding: "10px",
                fontSize: "12px",
                verticalAlign: "top",
                width: "30%",
              }}
            >
              Customer {orderdetails?.recipientName} <br></br>
              Mobile No. {orderdetails?.recipientPhoneNo}
            </td> */}
            <td
              colSpan={3}
              style={{
                // border: "1px solid black",
                // borderCollapse: "collapse",
                padding: "4px",
                fontSize: "16px",
                verticalAlign: "top",
                width: "100%",
              }}
            >
              Message <br></br>
              {orderdetails?.message}
            </td>
            {/* <td
              style={{
                border: "1px solid black",
                borderCollapse: "collapse",
                padding: "10px",
                fontSize: "12px",
                verticalAlign: "top",
              }}
              colSpan={7}
            >
              Delivery Address <br></br>
              {orderdetails?.addressLine}
            </td> */}
          </tr>

          <tr style={{ height: "10px" }}>
            <th
              style={{
                padding: "10px",
                border: "1px solid black",
                borderCollapse: "collapse",
                fontSize: "12px",
                verticalAlign: "top",
                textAlign: "center",
              }}
            >
              Sr. No
            </th>
            <th
              style={{
                padding: "10px",
                border: "1px solid black",
                borderCollapse: "collapse",
                fontSize: "12px",
                verticalAlign: "top",
                textAlign: "center",
              }}
            >
              rwId
            </th>
            <th
              colSpan={3}
              style={{
                padding: "10px",
                border: "1px solid black",
                borderCollapse: "collapse",
                fontSize: "12px",
                verticalAlign: "top",
                textAlign: "left",
              }}
            >
              {" "}
              Product Name
            </th>
            <th
              colSpan={2}
              style={{
                padding: "10px",
                border: "1px solid black",
                borderCollapse: "collapse",
                fontSize: "12px",
                verticalAlign: "top",
              }}
            >
              Rate
            </th>
            <th
              colSpan={2}
              style={{
                padding: "10px",
                border: "1px solid black",
                borderCollapse: "collapse",
                fontSize: "12px",
                verticalAlign: "top",
              }}
            >
              Quantity{" "}
            </th>
            <th
              colSpan={3}
              style={{
                padding: "10px",
                border: "1px solid black",
                borderCollapse: "collapse",
                fontSize: "12px",
                verticalAlign: "top",
                textAlign: "left",
              }}
            >
              Total{" "}
            </th>
          </tr>

          {orderdetails &&
            orderdetails.productList.map((element, i) => {
              return (
                <tr style={{ height: "20px" }} key={i}>
                  <CustomTD
                    index={i}
                    style={{ width: "5%", textAlign: "center" }}
                  >
                    {i + 1}
                  </CustomTD>
                  <CustomTD index={i} style={{ textAlign: "center" }}>
                    {element.productId.rwId}
                  </CustomTD>
                  <CustomTD index={i} colSpan={3}>
                    <span style={{ marginLeft: 16 }}>
                      {element.productId.name}
                    </span>
                  </CustomTD>
                  <CustomTD
                    index={i}
                    colSpan={2}
                    style={{ textAlign: "center" }}
                  >
                    {element?.price || element.productId.price}
                  </CustomTD>
                  <CustomTD
                    index={i}
                    colSpan={2}
                    style={{ textAlign: "center" }}
                  >
                    {element.variant ? (
                      element.variant >= 100 ? (
                        <>
                          {element.variant}gm x {element.itemCount}
                        </>
                      ) : (
                        <>
                          {element.variant}kg x {element.itemCount}
                        </>
                      )
                    ) : (
                      <>{element.itemCount}pcs</>
                    )}
                  </CustomTD>
                  <CustomTD
                    index={i}
                    colSpan={3}
                    style={{ textAlign: "center" }}
                  >
                    {element.variant ? (
                      element.variant >= 100 ? (
                        <>
                          {(element?.price || element.productId.price) *
                            element.itemCount *
                            (element.variant / 1000)}
                        </>
                      ) : (
                        <>
                          {(element?.price || element.productId.price) *
                            element.itemCount *
                            element.variant}
                        </>
                      )
                    ) : (
                      <>
                        {" "}
                        {(element?.price || element.productId.price) *
                          element.itemCount}
                      </>
                    )}
                  </CustomTD>
                </tr>
              );
            })}

          {/* <tr>
            <td
              style={{
                border: "1px solid black",
                textAlign: "right",
                padding: "10px",
                fontSize: "12px",
                verticalAlign: "top",
              }}
              colSpan={11}
            ></td>
          </tr> */}

          <tr>
            <td
              style={{
                border: "1px solid black",
                textAlign: "right",
                padding: "10px",
                fontSize: "12px",
                verticalAlign: "top",
                fontWeight: 700,
              }}
              colSpan={8}
            >
              <div>Sub total</div>
              <div>{deliveryChargeApplicable && <div>Delivery</div>}</div>
              <div>Total:</div>
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "right",
                padding: "10px",
                fontSize: "12px",
                verticalAlign: "top",
                fontWeight: 700,
              }}
              colSpan={11}
            >
              <div>{orderdetails?.totalAmount?.toFixed(2)}</div>
              {deliveryChargeApplicable && <div>+ {deliveryCharge}</div>}
              <div>
                Rs.
                {(
                  orderdetails?.totalAmount +
                  (deliveryChargeApplicable ? deliveryCharge : 0)
                )?.toFixed(2)}
              </div>
            </td>
          </tr>
          {/* {deliveryChargeApplicable ? deliveryCharge : 0} */}
          {/* {orderdetails?.totalAmount < 2000 && (
            <tr>
              <td
                style={{
                  border: "1px solid black",
                  textAlign: "right",
                  // padding: "10px",
                  fontSize: "12px",
                  verticalAlign: "top",
                  fontWeight: 700,
                }}
                colSpan={11}
              ></td>
            </tr>
          )}
          <tr>
            <td
              style={{
                border: "1px solid black",
                textAlign: "right",
                // padding: "10px",
                fontSize: "12px",
                verticalAlign: "top",
                fontWeight: 700,
              }}
              colSpan={11}
            ></td>
          </tr> */}
        </table>
      </div>
      <div
        id="orderlabel"
        style={{
          display: "none",
          // margin: "100px"
          marginLeft: "10px",
          marginTop: "10px",
        }}
      >
        <table
          style={{
            border: "1px solid black",
            borderCollapse: "collapse",
            width: "280px",
          }}
        >
          <tr
            style={{
              border: "1px solid black",
              borderCollapse: "collapse",
            }}
          >
            <td
              style={{
                verticalAlign: "top",
                textAlign: "left",
                padding: "10px",
                fontWeight: 700,
                fontSize: "12px",
              }}
            >
              {" "}
              Customer : {orderdetails?.recipientName}
            </td>
          </tr>

          <tr
            style={{
              border: "1px solid black",
              borderCollapse: "collapse",
            }}
          >
            <td
              style={{
                verticalAlign: "top",
                textAlign: "left",
                padding: "10px",
                fontWeight: 700,
                fontSize: "12px",
              }}
            >
              {" "}
              Mobile no. : {orderdetails?.recipientPhoneNo}
            </td>
          </tr>

          <tr
            style={{
              border: "1px solid black",
              borderCollapse: "collapse",
            }}
          >
            <td
              style={{
                verticalAlign: "top",
                textAlign: "left",
                padding: "10px",
                fontWeight: 700,
                fontSize: "12px",
              }}
            >
              {" "}
              Order ID :{" "}
              {String(RWseries) +
                String(orderdetails?.orderId).padStart(6, "0")}
            </td>
          </tr>

          <tr
            style={{
              border: "1px solid black",
              borderCollapse: "collapse",
              height: "193px",
            }}
          >
            <td
              style={{
                verticalAlign: "top",
                textAlign: "left",
                padding: "10px",
                fontWeight: 700,
                fontSize: "12px",
              }}
            >
              {" "}
              Delivery Address : {orderdetails?.addressLine}
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
};

export default AdminViewOrder;
