// import React from "react";
import Profile from "../../../../assets/Profile.svg";

const CustomerDetail = (props) => {
  const data = props.data;
  // console.log(data);
  return (
    <div className="abandoned-main">
      <div className="abandoned-div">
        <div className="customer-info">
          <img alt="cart-img" src={Profile} className="profile-bg" />
          <div className="customer-name-order">
            <p className="customer-name">
              {data.first_name + " " + data.last_name}
            </p>
            <p className="last-order-text">
              {new Date(data.updated_at).toISOString().split("T")[0]}
            </p>
          </div>
        </div>
        <p className="customer-status">
          {data.is_active ? <p>Active</p> : <p>Inactive</p>}
        </p>
      </div>
      <div className="abandoned-body">
        <div className="abandoned-body-item">
          <p className="total-order-text">Phone</p>
          <p className="address-text">{data.phone}</p>
        </div>
      </div>
    </div>
  );
};

export default CustomerDetail;
