import { Box, Paper, Skeleton } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const ButtonBanner = {
  backgroundColor: "#FFD480",
  borderRadius: "3px",
  color: "#fff",
  padding: "3px 10px",
  textTransform: "lowercase",
  fontSize: "16px",
};

const TitleText = {
  fontSize: "28px",
  fontWeight: "700",
  color: "#253D4E",
  margin: "10px 0px",
  fontFamily: "Quicksand",
};
const BannerDesc = {
  fontSize: "20px",
  fontWeight: "500",
  color: "#838383",
  margin: "5px 0px",
  fontFamily: "Quicksand",
  maxWidth: " 50vw",
};

function Item(props) {
  // console.log(props.item)

  const [isLoaded, setIsLoaded] = useState(false);
  const navigate = useNavigate();

  //   useEffect(() => {
  //     // console.log("isLoaded", isLoaded);
  //     props.setLoadedArr([...props.loadedArr, props.key]);
  //   }, [isLoaded]);
  return (
    <Box
      style={{ height: "100%", maxHeight: "60vw" }}
      onClick={(e) => {
        e.preventDefault();
        if (props.item.categoryId)
          navigate(`/subcategory/${props.item.categoryId._id}`);
        else if (props.item.productId)
          navigate(`/productdetail/${props.item.productId._id}`);
        else if (props.item.subcategoryId)
          navigate(
            `/categoryproducts/${props.item.subcategoryId.name}/${props.item.subcategoryId._id}/${props.item.subcategoryId.categoryId}`,
          );
      }}
    >
      <Paper className="carousel-Main">
        {/* <Button sx={ButtonBanner}>Free delivery</Button> */}
        {/* <Typography variant="h4" sx={TitleText}> */}
        {/* {props.item.name} */}
        {/* </Typography> */}
        {/* <Typography sx={BannerDesc}>{props.item.description}</Typography> */}
        <div
          style={{
            position: "relative",
            width: "100%",
            paddingTop: "56.25%", // 16:9 aspect ratio (9 / 16 * 100%)
          }}
        >
          {
            <img
              src={props.item.image}
              // alt="carousel_img"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                // width: "100%",
                height: "100%",
                objectFit: "cover", // Ensure the image covers the container
                width: "100vw",
                height: "56.3vw",
              }}
              loading="eager"
              onLoad={() => {
                setIsLoaded(true);
              }}
            />
          }
          {!isLoaded && (
            <Skeleton
              style={{
                width: "100%",
                height: "56.3vw",
              }}
            />
          )}
        </div>
      </Paper>
    </Box>
  );
}

export default Item;
