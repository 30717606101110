import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { Box, IconButton } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import server from "../../server";
import ProductDesc from "./Layout/ProductDesc";

const header = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "20px 20px 10px 20px",
};

const ProductDetails = () => {
  const { prodid } = useParams();
  const navigate = useNavigate();

  const location = useLocation();
  const [changeurl, seturl] = useState(prodid);
  const [rerender, setrerender] = useState(false);
  const [category, setcategory] = useState();
  // const [productinfo , setproductinfo]

  const getproduct = async () => {
    const result = await axios.get(`${server}/products/${prodid}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    setcategory(result.data.product.categoryId);
  };

  useEffect(() => {
    if (!localStorage.getItem("user")) navigate("/");
    else getproduct();
  }, []);
  useEffect(() => {}, [rerender, category, prodid]);
  useEffect(() => {
    // console.log("url changed to " + prodid)
    seturl(prodid);
  }, [prodid]);

  return (
    <Box>
      <Box sx={header}>
        <Box>
          {
            location.state ? (
              <Link
                to={`/categoryproducts/${location.state.name}/${location.state.subid}/${location.state.id}`}
              >
                <IconButton>
                  <ArrowBackRoundedIcon />
                </IconButton>
              </Link>
            ) : (
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/category");
                  // : <Link to="/category">
                }}
              >
                <ArrowBackRoundedIcon />
              </IconButton>
            )
            // </Link>
          }
        </Box>
      </Box>
      <ProductDesc productid={changeurl} rerender={setrerender} />
    </Box>
  );
};

export default ProductDetails;
