import Box from "@mui/material/Box";
import { useContext, useEffect, useState } from "react";
import KrishiVideoTable from "./Layout/KrishiVideoTable";
// import Plus from "../../../assets/plus.svg";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import axios from "axios";
import server from "../../server";
import DashboardContext from "../Context/DashboardContext";
import handleImageUpload from "../Inventory/Utils/HandleImageUpload";
// import { set } from "date-fns";
import { toast } from "react-toastify";

const KrishiVideos = () => {
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(-1);
  const context = useContext(DashboardContext);
  const { setLoading } = context;

  const handleClose = () => setOpen(false);
  const [checked, setChecked] = useState(false);
  const [checkVideo, setCheckVideo] = useState(false);

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [personName, setPersonName] = useState([]);
  const [cropsid, setCropsid] = useState([]);
  const [post_update_data, setPost_update_data] = useState({});
  const [post_data, setPost_data] = useState({
    title: "",
    description: "test",
    content_type: "",
    location: "",
    share_link: "",
    video: "",
    tags: [],
    icon: "",
    likes: 0,
    dislikes: 0,
    visibility: "PUBLIC",
    brand_id: 1,
    user: localStorage.getItem("id"),
    crops: [],
  });
  const handleOpen = (i) => {
    setOpen(true);

    setId(i);
    refreshdata();
    console.log(post_data);
  };
  const handleChange = (event) => {
    setChecked(event.target.checked);
    setPost_data({ ...post_data, content_type: "POST" });
    setCheckVideo(false);
  };
  const handleChangeVideo = (event) => {
    setCheckVideo(event.target.checked);
    setPost_data({ ...post_data, content_type: "VIDEO" });
    setChecked(false);
  };
  const refreshdata = () => {
    for (const key in post_data) post_data[key] = "";
    let post = post_data;
    post = { ...post, crops: [] };
    post = { ...post, tags: [] };
    post = { ...post, description: "test" };
    post = { ...post, likes: 0 };
    post = { ...post, dislikes: 0 };
    post = { ...post, brand_id: 1 };
    post = { ...post, visibility: "PUBLIC" };
    post = { ...post, user: localStorage.getItem("id") };
    setPersonName([]);
    setCropsid([]);
    setPost_data({ ...post });
    setChecked(false);
    setCheckVideo(false);

    // setAdd_data({...add_data,"role":"SHIPPING"})
  };
  const handleInput = (key, value) => {
    setPost_data({ ...post_data, [key]: value });
  };
  const [brands, setBrands] = useState([]);
  const [crops, setCrops] = useState([]);
  const [tags, setTags] = useState([]);
  // var brands=[];
  // var crops=[];

  useEffect(() => {
    const requestOptions = {
      headers: {
        method: "GET",
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    };
    axios
      .get(`${server}/crops/getCrops`, requestOptions)
      .then((res) => {
        // console.log(res.data.results);
        const crops_data = res.data.results;
        var crop_ids = [];
        crops_data?.map((item) => {
          crop_ids.push({ id: item.id, name: item.name });
          // console.log(crops);
        });
        setCrops(...crops, crop_ids);
      })
      .catch((err) => console.log(err));

    axios
      .get(`${server}/tags/getTags`, requestOptions)
      .then((res) => {
        const tags_data = res.data.results;
        // console.log(brands_data);
        console.log(tags_data);
        setTags(tags_data);
      })
      .catch((err) => console.log(err));

    // console.log(brands);

    // console.log("hi");
    axios
      .get(`${server}/brands/getBrands`, requestOptions)
      .then((res) => {
        const brands_data = res.data.results;
        // console.log(brands_data);
        console.log(brands_data);
        setBrands(brands_data);
      })
      .catch((err) => console.log(err));

    // console.log(brands);

    // console.log("hi");
  }, []);

  useEffect(() => {
    // console.log(id);
    if (id >= 0) {
      const requestOptions = {
        headers: {
          method: "GET",
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      };
      axios
        .get(`${server}/posts/getPosts`, requestOptions)
        .then((res) => {
          const posts = res.data.posts;
          const post_id_data = posts?.filter((item) => {
            return item.id == id;
          });
          console.log(post_id_data[0]);
          const post = post_data;
          for (const key in post_id_data[0]) {
            if (post[key] !== undefined) post[key] = post_id_data[0][key];
          }
          // console.log(post);
          setPost_data({ ...post });
          setPost_update_data({ ...post });
          // setPost_data({...post_id_data[0]});
          setPersonName([...post_id_data[0]?.tags]);
          setCropsid([...post_id_data[0]?.crops]);
          if (post_id_data[0]?.content_type == "POST") {
            setChecked(true);
            setCheckVideo(false);
          }
          if (post_id_data[0]?.content_type == "VIDEO") {
            setCheckVideo(true);
            setChecked(false);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [id]);
  // console.log(cropsid);

  const handlesubmit = () => {
    setLoading(true);
    console.log(post_data);
    console.log(post_update_data);
    const token = `Bearer ${localStorage.getItem("access")}`;

    if (id >= 0) {
      let post_update = {};
      for (const key in post_data) {
        if (post_update_data[key] != post_data[key])
          post_update = { ...post_update, [key]: post_data[key] };
      }
      if (Object.keys(post_update).length) {
        console.log(post_update);
        axios
          .patch(`${server}/posts/updatePost/${id}`, { post_update, token })
          .then((res) => {
            console.log(res);
            toast.success("Post Updated!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            handleClose();
          })
          .catch((err) => {
            console.log(err);
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          });
      }
    } else {
      axios
        .post(`${server}/posts/createPost`, { post_data, token })
        .then((res) => {
          console.log(res);
          toast.success("Post Published!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          handleClose();
        })
        .catch((err) => {
          toast.error("Something Went Wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          console.log(err);
        });
    }
    setLoading(false);
  };
  const theme = useTheme();

  const handleChange2 = (event) => {
    const {
      target: { value },
    } = event;
    // console.log(event.target);
    setPersonName(typeof value === "string" ? value.split(",") : value);
  };
  const handleChange3 = (event) => {
    const {
      target: { value },
    } = event;
    // console.log(event.target);
    setCropsid(typeof value === "string" ? value.split(",") : value);
  };
  useEffect(() => {
    // console.log(personName);
    handleInput("tags", personName);
  }, [personName]);
  useEffect(() => {
    // console.log(personName);
    handleInput("crops", cropsid);
  }, [cropsid]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 550,
    bgcolor: "#ffffff",
    border: "none",
    borderRadius: "12px",
    boxShadow: 24,
    backdropfilter: "blur(4px)",
    height: "80vh",
  };
  const [images, setImages] = useState([]);
  const [icons, setIcons] = useState([]);

  const handleupload = () => {
    const imginput = document.getElementById("post_img");
    imginput.click();
    const geturl = (e) => {
      const file = e.target.files[0];
      if (!file) return;
      setImages([file]);
    };
    imginput.addEventListener("change", geturl);
  };
  useEffect(() => {
    handleImageUpload({ images: images, setImages: setImages })
      .then((res) => {
        const img_url = res;
        // console.log(img_url);
        setPost_data({ ...post_data, video: img_url });
        // setPost_data({...post_data,"share_url":img_url})
        // document.querySelector(".post_img").src=img_url;
      })
      .catch((err) => console.log(err));

    // console.log(post_data);
  }, [images]);
  const handleupload1 = () => {
    const imginput = document.getElementById("post_icon");
    imginput.click();
    const geturl = (e) => {
      const file = e.target.files[0];
      if (!file) return;
      setIcons([file]);
    };
    imginput.addEventListener("change", geturl);
  };
  useEffect(() => {
    handleImageUpload({ images: icons, setImages: setIcons })
      .then((res) => {
        const img_url = res;
        // console.log(img_url);
        setPost_data({ ...post_data, icon: img_url });
        // setPost_data({...post_data,"share_url":img_url})
        // document.querySelector(".post_img").src=img_url;
      })
      .catch((err) => console.log(err));

    // console.log(post_data);
  }, [icons]);

  return (
    <div>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} style={{ overflowY: "scroll" }}>
            <div className="modal-head krishi-modal-head">
              <p className="modal-title">Create New Post</p>
              <button className="close-button-krishi" onClick={handleClose}>
                X
              </button>
            </div>
            <Box sx={{ p: 2 }}>
              <Box sx={{ display: "flex" }}>
                <Box>
                  <p className="Customer-info">Sharing Link</p>
                  <input
                    placeholder="Enter Sharing Link"
                    className="product-input"
                    style={{ width: "350px", margin: "5px 0px" }}
                    value={post_data?.share_link}
                    onChange={(e) => handleInput("share_link", e.target.value)}
                  />
                  <p className="Customer-info">Location</p>
                  <input
                    placeholder="Enter Location"
                    className="product-input"
                    style={{ width: "350px", margin: "5px 0px" }}
                    value={post_data?.location}
                    onChange={(e) => handleInput("location", e.target.value)}
                  />
                  <br></br>
                  <FormControl sx={{ m: 1, width: 300 }}>
                    <InputLabel id="demo-multiple-name-label">Tags</InputLabel>
                    <Select
                      labelId="demo-multiple-name-label"
                      id="demo-multiple-name"
                      multiple
                      value={personName}
                      onChange={handleChange2}
                      input={<OutlinedInput label="Name" />}
                      MenuProps={MenuProps}
                    >
                      {tags?.map((item) => (
                        <MenuItem
                          key={item.id}
                          value={item.id}
                          style={getStyles(item.name, personName, theme)}
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {/* <p>Brand</p>
                  <p className="product-input">Farmore</p> */}
                  <FormControl sx={{ m: 1, width: 300 }}>
                    <InputLabel id="demo-multiple-name-label">
                      Brands
                    </InputLabel>

                    <Select
                      className="product-select1"
                      placeholder="Brand id"
                      labelId="demo-name-label"
                      id="demo-name"
                      value={post_data?.brand_id}
                      onChange={(e) => handleInput("brand_id", e.target.value)}
                    >
                      {brands?.map((item, i) => {
                        return (
                          <MenuItem key={i + 1} value={item.id}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ m: 1, width: 300 }}>
                    <InputLabel id="demo-multiple-name-label">Crops</InputLabel>
                    <Select
                      labelId="demo-multiple-name-label"
                      id="demo-multiple-name"
                      multiple
                      value={cropsid}
                      onChange={handleChange3}
                      input={<OutlinedInput label="Name" />}
                      MenuProps={MenuProps}
                    >
                      {crops?.map((item) => (
                        <MenuItem
                          key={item.id}
                          value={item.id}
                          style={getStyles(item.name, personName, theme)}
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box>
                  {post_data?.icon != "" ? (
                    <img
                      style={{ width: "3rem", height: "3rem" }}
                      src={post_data?.icon}
                      className="post_icon"
                    />
                  ) : (
                    <></>
                  )}
                  <button
                    className="upload-image-button"
                    onClick={handleupload1}
                  >
                    <input type="file" accept="image/*" hidden id="post_icon" />
                    Upload Logo
                  </button>{" "}
                </Box>
              </Box>

              <Box>
                <p className="Customer-info">Select Content Type</p>
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ display: "flex", mr: 4, mt: 2, mb: 2 }}>
                    <p
                      style={{ margin: "0px 10px 0px 0px" }}
                      className="Customer-info"
                    >
                      Post
                    </p>
                    {id >= 0 ? (
                      <>
                        <input
                          checked={checked}
                          // onChange={handleChange}
                          disabled
                          type="checkbox"
                          value="checked"
                        />
                      </>
                    ) : (
                      <>
                        <input
                          checked={checked}
                          onChange={handleChange}
                          type="checkbox"
                          value="checked"
                        />
                      </>
                    )}
                  </Box>
                  <Box sx={{ display: "flex", mr: 2, mt: 2, mb: 2 }}>
                    <p
                      style={{ margin: "0px 10px 0px 0px" }}
                      className="Customer-info"
                    >
                      Video
                    </p>
                    {id >= 0 ? (
                      <>
                        <input
                          checked={checkVideo}
                          onChange={handleChangeVideo}
                          type="checkbox"
                          disabled
                          value="checked"
                        />
                      </>
                    ) : (
                      <>
                        <input
                          checked={checkVideo}
                          onChange={handleChangeVideo}
                          type="checkbox"
                          value="checked"
                        />
                      </>
                    )}
                  </Box>
                </Box>
              </Box>

              {checked ? (
                <div>
                  <p
                    style={{ margin: "0px 0px 10px 0px" }}
                    className="Customer-info"
                  >
                    Upload Image
                  </p>
                  {post_data?.video != "" &&
                  post_data?.content_type == "POST" ? (
                    <img
                      style={{ width: "3rem", height: "3rem" }}
                      src={post_data?.video}
                      className="post_img"
                    />
                  ) : (
                    <></>
                  )}

                  <button
                    className="upload-image-button"
                    onClick={handleupload}
                  >
                    <input type="file" accept="image/*" hidden id="post_img" />
                    Upload image
                  </button>

                  <p
                    style={{ margin: "0px 0px 0px 0px" }}
                    className="Customer-info"
                  >
                    Title
                  </p>
                  <input
                    placeholder="Enter Title"
                    className="product-input"
                    style={{ width: "350px", margin: "5px 0px" }}
                    value={post_data?.title}
                    onChange={(e) => handleInput("title", e.target.value)}
                  />
                  <p
                    style={{ margin: "0px 0px 0px 0px" }}
                    className="Customer-info"
                  >
                    Description
                  </p>
                  <textarea
                    className="product-input"
                    style={{
                      width: "350px",
                      height: "100px",
                      margin: "5px 0px",
                    }}
                    value={post_data?.description}
                    onChange={(e) => handleInput("description", e.target.value)}
                  />
                </div>
              ) : (
                ""
              )}
              {checkVideo ? (
                <div>
                  <p
                    style={{ margin: "0px 0px 0px 0px" }}
                    className="Customer-info"
                  >
                    Video Link
                  </p>
                  <input
                    placeholder="Enter Youtube Video Link"
                    className="product-input"
                    style={{ width: "350px", margin: "5px 0px" }}
                    value={post_data?.video}
                    onChange={(e) => {
                      handleInput("video", e.target.value);
                    }}
                  />

                  <p
                    style={{ margin: "0px 0px 0px 0px" }}
                    className="Customer-info"
                  >
                    Title
                  </p>
                  <input
                    placeholder="Enter Title"
                    className="product-input"
                    style={{ width: "350px", margin: "5px 0px" }}
                    value={post_data?.title}
                    onChange={(e) => handleInput("title", e.target.value)}
                  />
                  <p
                    style={{ margin: "0px 0px 0px 0px" }}
                    className="Customer-info"
                  >
                    Description
                  </p>
                  <textarea
                    className="product-input"
                    style={{
                      width: "350px",
                      height: "100px",
                      margin: "5px 0px",
                    }}
                    value={post_data?.description}
                    onChange={(e) => handleInput("description", e.target.value)}
                  />
                </div>
              ) : (
                ""
              )}

              {/* <Box sx={{ display: "flex", justifyContent: "end" }}>
              <ToggleButtonGroup
      color="primary"
      value={alignment}
      exclusive
      onChange={handleChange1}
      aria-label="Platform"
      sx={{ display: "flex", justifyContent: "start",alignItems:"end" ,width:"100%"}}
    >
      <ToggleButton onChange={(e)=>setLanguage(e.target.value)} value="English">English</ToggleButton>
      <ToggleButton onChange={(e)=>setLanguage(e.target.value)} value="Marathi">Marathi</ToggleButton>
      <ToggleButton onChange={(e)=>setLanguage(e.target.value)} value="Hindi">Hindi</ToggleButton>
    </ToggleButtonGroup>
                // 
              </Box> */}

              <button
                className="upload-image-button"
                style={{ width: "15rem" }}
                onClick={handlesubmit}
              >
                {id >= 0 ? <>Save and Update</> : <>Save and Publish</>}
              </button>
            </Box>
          </Box>
        </Modal>
      </div>
      <KrishiVideoTable
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
      />
    </div>
  );
};

export default KrishiVideos;
