import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import {
  alpha,
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import axios from "axios";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import server from "../../../server";
// import axios from "axios";
import { Delete } from "@mui/icons-material";
import { toast } from "react-toastify";
import DashboardContext from "../../Context/DashboardContext";
import DeleteConfirmModal from "./DeleteConfirmModal";

import DefaultImage from "../../../../assets/defaultimg.jpg";

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "Sr.No",
    numeric: true,
    disablePadding: false,
    label: "Sr.No",
  },
  {
    id: "image",
    numeric: false,
    disablePadding: true,
    label: "Image",
  },
  {
    id: "productName",
    numeric: true,
    disablePadding: false,
    label: "Assigned Category/ Product",
  },
  // {
  //     id: "Message",
  //     numeric: true,
  //     disablePadding: false,
  //     label: "Message",
  // },
  {
    id: "Title",
    numeric: true,
    disablePadding: false,
    label: "Title",
  },
  // {
  //     id: "Discount",
  //     numeric: true,
  //     disablePadding: false,
  //     label: "Discount",
  // },
  {
    id: "edit",
    numeric: true,
    disablePadding: false,
    label: "Edit",
  },
  {
    id: "Active",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ borderTop: "1px solid #E1E2E9" }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity,
            ),
        }),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div>
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Banners
          </Typography>
        )}
      </div>

      <div></div>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const BannerTable = ({
  offers,
  setoldbanner,
  setedit,
  setopen,
  setImageFile,
  setdefaultSubcat,
  setdefaultcat,
  setdefaultprod,
  setActive,
  getOffers,
}) => {
  const context = useContext(DashboardContext);
  const { setLoading } = context;
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [crops, setCrops] = useState([]);
  const [equipment, setEquipment] = useState([]);
  const [brand, setBrand] = useState([]);
  const [brandOptions, setBrandOptions] = useState([] || "");
  const [cropFilter, setCropFilter] = useState();
  const [iProducts, setIProducts] = useState();

  const theme = useTheme();
  const [personName, setPersonName] = useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value,
    );
  };

  //   console.log(products);

  const requestOptions = {
    headers: {
      method: "GET",
      Authorization: `Bearer ${localStorage.getItem("access")}`,
    },
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - offers?.length) : 0;

  const discount = (row) => {
    let per = ((row?.base_price - row?.price) / row?.base_price) * 100;
    return per.toFixed(2);
  };

  useEffect(() => {
    // console.log("offers changed");
    setIProducts(offers);
  }, [offers]);

  const [status, setStatus] = useState({});

  // useEffect(() => {
  //     console.log(iProducts)
  // }, [iProducts])

  const handleStatus = async (value, id) => {
    // const token = `Bearer ${localStorage.getItem("access")}`
    setLoading(true);
    // const newProduct = { "status": value }

    // navigate("/content")
    //api to change status
    console.log(value);
    try {
      const updatestatus = await axios.post(
        `${server}/banner/edit`,
        {
          active: value,
          bannerid: id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        },
      );
      // console.log(updatestatus)
      // functionchange()
      setLoading(false);
    } catch (error) {
      console.log(error);
    }

    getOffers();
  };

  const [openConfirm, setOpenConfirm] = useState(false);

  const deleteBanner = async (id) => {
    setOpenConfirm(false);
    setLoading(true);
    try {
      const updatestatus = await axios.delete(`${server}/banner/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      // console.log(updatestatus)
      // functionchange()
      toast.success("Banner deleted successsfully");
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.success(`Error deleting banner ${error.message}`);
    }

    getOffers();
  };

  const [deleteID, setDeleteID] = useState("");

  return (
    <div className="order-table-main">
      <Box sx={{ width: "100%" }}>
        <DeleteConfirmModal
          openConfirm={openConfirm}
          closeConfirm={() => setOpenConfirm(false)}
          deleteBanner={() => deleteBanner(deleteID)}
        />
        <Paper sx={{ width: "100%", mb: 2 }}>
          <EnhancedTableToolbar
            numSelected={selected.length}
            offers={offers}
            setIProducts={setIProducts}
          />

          <TableContainer style={{ padding: "0px 20px" }}>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                // onSelectAllClick={handleSelectAllClick}
                // onRequestSort={handleRequestSort}
                rowCount={iProducts?.length}
              />
              <TableBody>
                {/* .filter((crop) => crop.crops[0] === cropFilter) */}
                {(rowsPerPage > 0
                  ? iProducts?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage,
                    )
                  : iProducts
                )?.map((row, index) => {
                  // const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  //  console.log(row?.image.split(",")[0]);
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                      sx={{ cursor: "pointer" }}
                      style={{
                        border: "1px solid transparent",
                      }}
                    >
                      <TableCell
                        align="left"
                        style={{
                          border: "1px solid transparent",
                        }}
                      >
                        {index + 1}
                      </TableCell>

                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        style={{
                          border: "1px solid transparent",
                        }}
                      >
                        <img
                          alt="productImg"
                          src={
                            row.image === "noimage" ? DefaultImage : row.image
                          }
                          style={{ width: "50px" }}
                        />
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          border: "1px solid transparent",
                        }}
                      >
                        {![
                          row.categoryId,
                          row.productId,
                          row.subcategoryId,
                        ].some((reference) => reference != null) ? (
                          <p style={{ color: "red" }}>
                            Banner data corrupted. Set to inactive/delete
                          </p>
                        ) : row?.categoryId ? (
                          <>
                            {" "}
                            {row.categoryId?.name}
                            (Category)
                          </>
                        ) : row.productId ? (
                          <>
                            {row.productId?.name}
                            (Product){" "}
                          </>
                        ) : (
                          <>
                            {row.subcategoryId?.name}
                            (SubCategory)
                          </>
                        )}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          border: "1px solid transparent",
                        }}
                      >
                        {row.title}
                      </TableCell>

                      <TableCell
                        padding="none"
                        align="center"
                        style={{
                          border: "1px solid transparent",
                          display: "flex",
                          padding: 8,
                          alignItems: "end",
                        }}
                      >
                        {/* <Link to="/editproduct" state={{ id: row.id, data: row, isEdit: true }}> */}
                        <button
                          className="edit-button"
                          onClick={(e) => {
                            // e.preventDefault()
                            console.log("clicked");
                            setImageFile(row.image);
                            setActive(row.active);
                            setoldbanner(row);
                            if (row.categoryId)
                              setdefaultcat(row.categoryId._id);
                            else if (row.productId)
                              setdefaultprod(row.productId._id);
                            else if (row.subcategoryId)
                              setdefaultSubcat(row.subcategoryId._id);
                            else
                              toast.error(
                                "Banner data corrupted! Set to inactive/delete",
                              );
                            // setopen(true)
                            setedit(true);
                          }}
                        >
                          Edit
                        </button>
                        <Button
                          onClick={() => {
                            setDeleteID(row._id);
                            setOpenConfirm(true);
                          }}
                        >
                          <Delete />
                        </Button>
                        {/* </Link> */}
                      </TableCell>

                      <TableCell
                        style={{
                          border: "1px solid transparent",
                        }}
                      >
                        <select
                          className="status-published"
                          value={row.active ? "true" : "false"}
                          onChange={(e) => {
                            e.preventDefault();
                            handleStatus(e.target.value, row._id);
                          }}
                        >
                          <option value={"true"}>Active</option>
                          <option value={"false"}>Inactive</option>
                        </select>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 25]}
            component="div"
            count={iProducts?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </Paper>
      </Box>
    </div>
  );
};

export default BannerTable;
