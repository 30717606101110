import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import {
  Box,
  Button,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import CategoryCarousel from "./Layout/CategoryCarousel";

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import CategoryProductCard from "./Layout/CategoryProductCard";

import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import server from "../../server";
import ProductNotFound from "../Search/Layout/ProductNotFound";

import { ReactComponent as SearchIcon } from "../../../assets/search.svg";

const CategoryTitle = {
  fontWeight: 600,
  fontFamily: "poppins",
  fontSize: "20px",
  padding: "10px 20px",
};

const PriceRangeText = {
  fontSize: "18px",
  fontWeight: "600",
  fontFamily: "quicksand",
  color: "#37474F",
};

const ButtonCart = {
  backgroundColor: "#5EC401",
  color: "#fff",
  textTransform: "none",
  padding: "10px",
  fontSize: "18px",
  fontWeight: "600",
  width: "100%",
  fontFamily: "quicksand",
  borderRadius: "8px",
  margin: "20px 0px 0px 0px",
  "&.MuiButtonBase-root:hover": {
    background: "#5EC401",
  },
  position: "relative",
};

const outlineButton = {
  backgroundColor: "#F2F3F2",
  color: "#53B175",
  textTransform: "none",
  padding: "4px",
  fontSize: "14px",
  fontWeight: "600",
  width: "100%",
  fontFamily: "quicksand",
  borderRadius: "14px",
  // margin: "20px 0px 0px 0px",
  "&.MuiButtonBase-root:hover": {
    background: "#F2F3F2",
  },
  position: "relative",
  border: "3px solid #53B175",
};

const checkAll = {
  fontSize: "12px",
  fontWeight: "600",
  fontFamily: "quicksand",
  backgroundColor: "rgba(54, 179, 126, 0.14)",
  borderRadius: "12px",
  padding: "3px 8px",
  color: "#5EC401",
};

const categoryItems = {
  fontSize: "16px",
  fontWeight: "500",
  fontFamily: "quicksand",
  color: "#5EC401",
};

const header = {
  display: "flex",
  alignItems: "center",
  position: "relative",
  justifyContent: "flex-around",
  // width:"100vw",
  overflow: "hidden",
  padding: "10px",
  paddingRight: "4px",
  gap: "10px",
};

const headerLeft = {
  width: "100%",
  display: "flex",
  justifyContent: "center",
  marginLeft: "-24px",
};

const CategoryProducts = (props) => {
  const navigate = useNavigate();

  const { categoryId } = useParams(); //this is category id
  const { catid } = useParams(); //this is actually sub category id
  const { name } = useParams(); //this is sub category name
  // console.log(catid)
  const [products, setProducts] = useState([]);
  const [topname, settopname] = useState(name);
  const [loading, setLoading] = useState(false);

  const fetchproducts = async () => {
    setLoading(true);
    try {
      const result = await axios.get(
        `${server}/products/bySubCategoryId/${catid}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        },
      );
      console.log(result.data);
      setProducts(result.data.filter((item) => item.published == true));
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  function getbybrand(data, brandname) {
    setProducts([]);
    setProducts(data);
    settopname(brandname);
  }

  useEffect(() => {
    if (!localStorage.getItem("user")) navigate("/");
    else fetchproducts();
  }, []);

  useEffect(() => {}, [products]);

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  //states for sidebar
  const [min, setmin] = useState(null);
  const [max, setmax] = useState(null);

  const toggleDrawer = (anchor, open) => (event) => {
    // if (event.type === "keydown") {
    //   return;
    // }

    setState({ ...state, [anchor]: open });
  };

  const setFilter = async (e) => {
    e.preventDefault();
    // console.log(checkedarr)
    setshowfilter([]);
    setshowmin(false);
    setshowmax(false);
    //reset

    //to avoid blank apply button clicks

    // let cat = false;

    // checkedarr.forEach( (element) =>{
    //   if(element[0] ==="true"){
    //     cat= true
    //   }
    // })
    // if(cat != true){ alert('please select filter')
    // return}
    // setProducts([])
    // let setuparr = []
    // let setup = 0
    // let catnames = []
    // // console.log(catnames)
    // if (catnames.length > 0) {
    //   setshowfilter(catnames)
    //   // console.log(catnames)
    // }

    if (min != null) {
      // console.log(min + "hello")
      //sort the state array
      // console.log(products)
      const myData = [...products].sort((a, b) => (a.price > b.price ? 1 : -1));
      // console.log(myData)
      setProducts(myData);
      setmin(null);
    } else setshowmin(false);

    if (max != null) {
      // console.log(max)
      // setProducts(products.filter(item => item.price < max))
      const myData = [...products].sort((a, b) => (a.price > b.price ? -1 : 1));
      // console.log(myData)
      setProducts(myData);

      setmax(null);
    }

    // console.log(checkedarr)
    showappliedfilters();
  };

  const showappliedfilters = () => {
    if (min != null) setshowmin(true);
    if (max != null) setshowmax(true);
  };
  let checkedarr = [];
  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 300,
        position: "relative",
      }}
      role="presentation"
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "10px 0px",
          padding: "0px 20px",
        }}
      >
        <Typography sx={PriceRangeText}>Price Range</Typography>
        <IconButton onClick={toggleDrawer(anchor, false)}>
          <CloseRoundedIcon />
        </IconButton>
      </Box>

      {/* <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          // alignItems: "center",
          justifyContent: "space-between",
          margin: "10px 0px",
          padding: "0px 20px",
        }}
      > */}
      {/* <Box sx={
          {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: "20px",
            borderTop: "2px solid #F0F0F0",
            borderBottom: "2px solid #F0F0F0",
            padding: "20px",


          }
        }>
          <Typography sx={categoryItems}>Low to High</Typography>
          <Checkbox
            // checked = { checked[i] === "true" ? true : false}
            onClick={(e) => {
              if (e.target.checked) {
                setmin(true)
              }
              else {
                setmin(null)
              }
            }}

            icon={<CircleOutlinedIcon />}
            checkedIcon={<CheckCircleIcon />
            }
          />
        </Box> */}

      <FormControl sx={{ width: "100%" }}>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          sx={{ width: "100%" }}
          // defaultValue="0"
          name="radio-buttons-group"
          onChange={(e) => {
            // console.log(e.target.value)
            // setchoice(e.target.value)
            if (e.target.value === "1") {
              // console.log("high to low")
              setmax(true);
              setmin(null);
            }
            if (e.target.value === "2") {
              // console.log("low to high")
              setmax(null);
              setmin(true);
            }
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              padding: "10px",
              borderTop: "2px solid #F0F0F0",
              borderBottom: "2px solid #F0F0F0",
            }}
          >
            <Typography>High to Low</Typography>
            <FormControlLabel
              value={1}
              control={
                <Radio
                  sx={{
                    "&, &.Mui-checked": {
                      color: "rgba(94, 196, 1, 1)",
                    },
                  }}
                />
              }
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              padding: "10px",
              // borderTop: "2px solid #F0F0F0",
              borderBottom: "2px solid #F0F0F0",
            }}
          >
            <Typography>Low to High</Typography>

            <FormControlLabel
              value={2}
              control={
                <Radio
                  sx={{
                    "&, &.Mui-checked": {
                      color: "rgba(94, 196, 1, 1)",
                    },
                  }}
                />
              }
            />
          </Box>
        </RadioGroup>
      </FormControl>

      {/* <Box sx={
          {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            borderTop: "2px solid #F0F0F0",
            borderBottom: "2px solid #F0F0F0",
            padding: "20px",


          }
        }>
          <Typography sx={categoryItems}>High to Low</Typography>
          <Checkbox
            onClick={(e) => {
              if (e.target.checked) {
                setmax(true)
              }
              else {
                setmax(null)
              }
            }}
            icon={<CircleOutlinedIcon />}
            checkedIcon={<CheckCircleIcon />
            }
          />
        </Box> */}
      {/* </Box> */}

      <Box
        sx={{
          padding: "0px 10px",
          position: "absolute",
          bottom: "-150px",
          width: "93%",
        }}
      >
        <Link>
          <Button
            sx={ButtonCart}
            onClick={(e) => {
              setFilter(e);
              setState({ ...state, ["left"]: false });
            }}
          >
            Apply
          </Button>
        </Link>
      </Box>
    </Box>
  );

  const [categories, setcategories] = useState([]);
  const [showmin, setshowmin] = useState(false);
  const [showmax, setshowmax] = useState(false);
  const [showfilter, setshowfilter] = useState();

  const [tempQuery, setTempQuery] = useState("");

  const InputPropsStyle = {
    sx: {
      fieldset: {
        borderRadius: 0,
        borderWidth: 0,
      },
      "&:focus-within fieldset, &:focus-visible fieldset": {
        border: "none !important",
      },
      input: {
        "&::placeholder": {
          color: "rgba(55, 71, 79, 0.72) !important",
          fontSize: "16px",
          padding: "0px 0px 0px 10px",
          mixBlendMode: "normal",
          opacity: "0.87",
          fontWeight: "500",
          fontFamily: "quicksand",
        },
      },
    },
    endAdornment: (
      <InputAdornment position="end">
        <IconButton
          onClick={() =>
            navigate("/search", { state: { navQueryState: tempQuery } })
          }
        >
          <SearchIcon />
        </IconButton>
      </InputAdornment>
    ),
  };

  return (
    <Box
      sx={{
        marginBottom: "100px",
        // overflow: "hidden"
      }}
    >
      <Box sx={header}>
        <IconButton
          onClick={(e) => {
            e.preventDefault();
            // navigate(-1)
            navigate(`/subcategory/${categoryId}`);
          }}
        >
          <ArrowBackRoundedIcon />
        </IconButton>
        <Box sx={headerLeft}>
          {/* <Link to="/category"> */}

          {/* </Link> */}

          <Typography variant="h6" sx={CategoryTitle}>
            {topname}
          </Typography>
          {/* </Box>
        <FilterListRoundedIcon sx = { { width: "20px"}}/>
      </Box> */}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          marginLeft: "50px",
          // justifyContent: "space-between"
        }}
      >
        {showmin ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px 10px",
              backgroundColor: "#F0F1F2",
              height: "fit-content",
              width: "auto",
              marginBottom: "10px",
              borderRadius: "8px",
            }}
          >
            low to high
          </Box>
        ) : (
          <></>
        )}
        {showmax ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
              justifyContent: "center",
              padding: "10px 10px",
              backgroundColor: "#F0F1F2",
              height: "fit-content",
              width: "auto",
              borderRadius: "8px",
            }}
          >
            high to low
          </Box>
        ) : (
          <></>
        )}

        {showmin || showmax ? (
          <Box
            onClick={(e) => {
              e.preventDefault();
              // setProducts(originalProducts)
              setshowmin(false);
              setshowmax(false);
              fetchproducts();
            }}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "10px",
              marginRight: "10px",
              marginLeft: "10px",
              padding: "10px 10px",
              backgroundColor: "#F0F1F2",
              height: "fit-content",
              width: "auto",
              borderRadius: "8px",
            }}
          >
            X
          </Box>
        ) : (
          <></>
        )}
      </Box>
      <CategoryCarousel
        bybrand={getbybrand}
        categoryid={categoryId}
        subcatid={catid}
        key={categoryId}
      />{" "}
      <Box sx={{ px: 1, display: "flex", gap: "8px" }}>
        <FormGroup>
          <TextField
            size="small"
            placeholder="Search"
            onChange={(e) => setTempQuery(e.target.value)}
            onKeyDown={(e) =>
              e.key === "Enter" &&
              navigate("/search", { state: { navQueryState: tempQuery } })
            }
            sx={{
              backgroundColor: "#F0F1F2",
              borderRadius: "8px 8px 0px 0px",
              width: "100%",
              margin: "10px 20px 10px 0px",
              borderBottom: "1px solid #5EC401",
            }}
            InputProps={InputPropsStyle}
            InputLabelProps={{
              sx: {
                color: "#5EC401",
                textTransform: "capitalize",
              },
            }}
          />
        </FormGroup>
        {["left"].map((anchor) => (
          <Fragment key={anchor}>
            <IconButton onClick={toggleDrawer(anchor, true)}>
              <Button sx={outlineButton}>Filter</Button>
            </IconButton>
            <Drawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
            >
              {list(anchor)}
            </Drawer>
          </Fragment>
        ))}
      </Box>
      {products.length === 0 ? (
        <ProductNotFound
          message={"No Products under selected Criteria"}
          loading={loading}
        />
      ) : (
        products &&
        products.map((currproduct, i) => {
          return currproduct.published &&
            currproduct.stocklimit &&
            currproduct.stocklimit < currproduct.stock ? (
            <CategoryProductCard
              prodid={currproduct._id}
              key={i}
              name={name}
              productDetails={currproduct}
            />
          ) : null;
          // <></>
          // {
          /* {i} */
          // }
        })
      )}
    </Box>
  );
};

export default CategoryProducts;
