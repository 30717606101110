import ShoppingBasketOutlinedIcon from "@mui/icons-material/ShoppingBasketOutlined";
import { Box, IconButton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const EditButton = { backgroundColor: "#F37A20", margin: "5px 5px" };

const OrderTitle = {
  fontSize: "14px",
  color: "#37474F",
  fontFamily: "Poppins",
  fontWeight: "600",
};

const ShippingStatusDeliver = {
  color: "#5EC401",
  fontSize: "14px",
  fontFamily: "Poppins",
  fontWeight: "500",
};

const OrderDate = {
  fontSize: "13px",
  fontWeight: "400",
  fontFamily: "Poppins",
  color: "#868889",
};

const salePriceText = {
  color: "#F37A20",
  fontWeight: "500",
  fontSize: "22px",
  fontFamily: "Poppins",
  textAlign: "end",
};

const buttonProductCard = {
  color: "#fff",
  backgroundColor: "#5EC401",
  display: "flex",
  alignItems: "center",
  height: "40px",
  justifyContent: "center",
  padding: "2px 10px",
  textTransform: "capitalize",
  borderRadius: "7px",
  "&.MuiButtonBase-root:hover": {
    background: "#64cf00",
  },
};
const ReturnCard = (props) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "1px solid #E1E1E1",
        paddingBottom: "20px",
        marginTop: "10px",
      }}
      onClick={(e) => {
        e.preventDefault();
        navigate(`/returnorder/view/${props.returndetails._id}`, {
          state: { value: props.value },
        });
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <IconButton sx={EditButton}>
          <ShoppingBasketOutlinedIcon sx={{ color: "#fff" }} />
        </IconButton>
        <Box sx={{ margin: "0px 0px 0px 10px" }}>
          <Typography sx={OrderTitle}>
            ReturnId #{props.returndetails.returnId}
          </Typography>
          <Typography sx={ShippingStatusDeliver}>
            {props.returndetails.status}
          </Typography>
          <Typography sx={OrderDate}>
            {props.returndetails.createdAt.toString().substring(0, 10)}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Typography sx={salePriceText}>
          ₹{props.returndetails.returnAmount?.toFixed(2)}
        </Typography>

        {/* <Button
          sx={buttonProductCard}
          onClick={(e) => {
            e.preventDefault();
            navigate(`/returnorder/view/${props.returndetails._id}`, {
              state: { value: props.value },
            });
          }}
        >
          View Details
        </Button> */}
      </Box>
    </Box>
  );
};

export default ReturnCard;
