export const subcatsToDisplay_16_9 = {
  0: {
    main: "6543c14bf7e3ae87941acb33",
    below: [
      "6543c2edf7e3ae87941ae4ca",
      "6543c1a1f7e3ae87941ad044",
      "6543c1b1f7e3ae87941ad13e",
    ],
  },
  1: {
    main: "65ccd7f0eca383e82c933496",
    below: [
      "65ccd7f1eca383e82c9334a8",
      "65ccd7f0eca383e82c933498",
      "65ccd7f0eca383e82c93349a",
    ],
  },
  2: {
    main: "6543b8a483cf462f5ff4c5a7",
    below: [
      "6543c24af7e3ae87941adad2",
      "6543c254f7e3ae87941adb7e",
      "6543b8a583cf462f5ff4c5b6",
    ],
  },
  3: {
    main: "6543c18bf7e3ae87941acefe",
    below: [
      "6543c16ef7e3ae87941acd3b",
      "6543c167f7e3ae87941accc9",
      "6543c311f7e3ae87941ae712",
    ],
  },
  4: {
    main: "6543c261f7e3ae87941adc48",
    below: [
      "6543c159f7e3ae87941acc03",
      "6543b8a583cf462f5ff4c5b1",
      "6543b8a483cf462f5ff4c5ac",
    ],
  },
  5: {
    main: "6543c2dbf7e3ae87941ae3a5",
    below: [
      "6543c1b4f7e3ae87941ad16c",
      "6543c265f7e3ae87941adc77",
      "6543c25ff7e3ae87941adc27",
    ],
  },
  6: {
    main: "6543c185f7e3ae87941ace9b",
    below: [
      "6543c1caf7e3ae87941ad2d3",
      "6543c2d4f7e3ae87941ae332",
      "6543c30ef7e3ae87941ae6e1",
    ],
  },
  7: {
    main: "6543c292f7e3ae87941adf28",
    below: [
      "6543c1e6f7e3ae87941ad488",
      "6543c305f7e3ae87941ae64f",
      "6543c30af7e3ae87941ae6a1",
    ],
  },
  8: {
    main: "6543c23af7e3ae87941ad9d2",
    below: [
      "6543c2a4f7e3ae87941ae034",
      "6543c2b6f7e3ae87941ae16a",
      "6543c30af7e3ae87941ae6a1",
    ],
  },
  // 9: { main: "", below: ["", "", ""] },
};

export const fileNames_16_9 = [
  "Bakery Products.png",
  "bath.png",
  "Cleaning.png",
  "Cooking.png",
  "Healthcare.png",
  "Noodles,_Pasta,_Vermicelli.png",
  "Oral_care.png",
  "Pooja_Needst.png",
  "ready_to_eat.png",
  "Spices.png",
];
