import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
// import CategoryCarousel from "./Layout/CategoryCarousel";

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import CategoryProductCard from "./Layout/CategoryProductCard";

import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import server from "../../server";
import ProductNotFound from "../Search/Layout/ProductNotFound";

import { ArrowBackRounded } from "@mui/icons-material";
import { ReactComponent as SearchIcon } from "../../../assets/search.svg";

const CategoryTitle = {
  fontWeight: 600,
  fontFamily: "poppins",
  fontSize: "20px",
  padding: "10px 20px",
};

const PriceRangeText = {
  fontSize: "18px",
  fontWeight: "600",
  fontFamily: "quicksand",
  color: "#37474F",
};

const ButtonCart = {
  backgroundColor: "#5EC401",
  color: "#fff",
  textTransform: "none",
  padding: "10px",
  fontSize: "18px",
  fontWeight: "600",
  width: "100%",
  fontFamily: "quicksand",
  borderRadius: "8px",
  margin: "20px 0px 0px 0px",
  "&.MuiButtonBase-root:hover": {
    background: "#5EC401",
  },
  position: "relative",
};

const header = {
  display: "flex",
  alignItems: "center",
  position: "relative",
  justifyContent: "space-between",
  // width:"100vw",
  overflow: "hidden",
  padding: "10px 20px",
};

const headerLeft = {
  display: "center",
  alignItems: "center",
};

const RecentOrders = (props) => {
  const navigate = useNavigate();

  // const { categoryId } = useParams(); //this is category id
  // const { catid } = useParams(); //this is actually sub category id
  const { name: topname } = useParams(); //this is sub category name
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchproducts = async () => {
    // console.log({ loading });
    setLoading(true);
    try {
      const result = await axios.get(`${server}/orders/recent`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      console.log(result.data);
      setProducts(result?.data.products.map((p) => p?.product || p));
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  // function getbybrand(data, brandname) {
  //   setProducts([]);
  //   setProducts(data);
  //   settopname(brandname);
  // }

  useEffect(() => {
    if (!localStorage.getItem("user")) navigate("/");
    else fetchproducts();
  }, []);

  // useEffect(() => {}, [products]);

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  //states for sidebar
  const [min, setmin] = useState(null);
  const [max, setmax] = useState(null);

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown") {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const setFilter = async (e) => {
    e.preventDefault();
    // console.log(checkedarr)
    setshowfilter([]);
    setshowmin(false);
    setshowmax(false);

    if (min != null) {
      const myData = [...products].sort((a, b) => (a.price > b.price ? 1 : -1));
      setProducts(myData);
      setmin(null);
    } else setshowmin(false);

    if (max != null) {
      const myData = [...products].sort((a, b) => (a.price > b.price ? -1 : 1));
      setProducts(myData);

      setmax(null);
    }

    showappliedfilters();
  };

  const showappliedfilters = () => {
    if (min != null) setshowmin(true);
    if (max != null) setshowmax(true);
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 300,
        position: "relative",
      }}
      role="presentation"
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "10px 0px",
          padding: "0px 20px",
        }}
      >
        <Typography sx={PriceRangeText}>Price Range</Typography>
        <IconButton onClick={toggleDrawer(anchor, false)}>
          <CloseRoundedIcon />
        </IconButton>
      </Box>

      <FormControl sx={{ width: "100%" }}>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          sx={{ width: "100%" }}
          // defaultValue="0"
          name="radio-buttons-group"
          onChange={(e) => {
            if (e.target.value === "1") {
              setmax(true);
              setmin(null);
            }
            if (e.target.value === "2") {
              setmax(null);
              setmin(true);
            }
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              padding: "10px",
              borderTop: "2px solid #F0F0F0",
              borderBottom: "2px solid #F0F0F0",
            }}
          >
            <Typography>High to Low</Typography>
            <FormControlLabel
              value={1}
              control={
                <Radio
                  sx={{
                    "&, &.Mui-checked": {
                      color: "rgba(94, 196, 1, 1)",
                    },
                  }}
                />
              }
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              padding: "10px",
              // borderTop: "2px solid #F0F0F0",
              borderBottom: "2px solid #F0F0F0",
            }}
          >
            <Typography>Low to High</Typography>

            <FormControlLabel
              value={2}
              control={
                <Radio
                  sx={{
                    "&, &.Mui-checked": {
                      color: "rgba(94, 196, 1, 1)",
                    },
                  }}
                />
              }
            />
          </Box>
        </RadioGroup>
      </FormControl>

      <Box
        sx={{
          padding: "0px 10px",
          position: "absolute",
          bottom: "-150px",
          width: "93%",
        }}
      >
        <Link>
          <Button sx={ButtonCart} onClick={(e) => setFilter(e)}>
            Apply
          </Button>
        </Link>
      </Box>
    </Box>
  );

  const [categories, setcategories] = useState([]);
  const [showmin, setshowmin] = useState(false);
  const [showmax, setshowmax] = useState(false);
  const [showfilter, setshowfilter] = useState();

  const [tempQuery, setTempQuery] = useState("");

  const InputPropsStyle = {
    sx: {
      fieldset: {
        borderRadius: 0,
        borderWidth: 0,
      },
      "&:focus-within fieldset, &:focus-visible fieldset": {
        border: "none !important",
      },
      input: {
        "&::placeholder": {
          color: "rgba(55, 71, 79, 0.72) !important",
          fontSize: "16px",
          padding: "0px 0px 0px 10px",
          mixBlendMode: "normal",
          opacity: "0.87",
          fontWeight: "500",
          fontFamily: "quicksand",
        },
      },
    },
    endAdornment: (
      <InputAdornment position="end">
        <IconButton
          onClick={() =>
            navigate("/search", { state: { navQueryState: tempQuery } })
          }
        >
          <SearchIcon />
        </IconButton>
      </InputAdornment>
    ),
  };

  return (
    <Box
      sx={{
        marginBottom: "100px",
      }}
    >
      <Box sx={header}>
        <Box sx={headerLeft}>
          <IconButton
            onClick={(e) => {
              e.preventDefault();
              navigate(-1);
            }}
          >
            <ArrowBackRounded />
          </IconButton>

          <Typography variant="h6" sx={CategoryTitle}>
            {topname}
          </Typography>
        </Box>

        {/* {["left"].map((anchor) => (
          <Fragment key={anchor}>
            <IconButton onClick={toggleDrawer(anchor, true)}>
              <Button sx={ButtonCart}>Filter</Button>
            </IconButton>
            <Drawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
            >
              {list(anchor)}
            </Drawer>
          </Fragment>
        ))} */}
      </Box>
      {/* <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          marginLeft: "50px",
        }}
      >
        {showmin ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px 10px",
              backgroundColor: "#F0F1F2",
              height: "fit-content",
              width: "auto",
              marginBottom: "10px",
              borderRadius: "8px",
            }}
          >
            low to high
          </Box>
        ) : (
          <></>
        )}
        {showmax ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
              justifyContent: "center",
              padding: "10px 10px",
              backgroundColor: "#F0F1F2",
              height: "fit-content",
              width: "auto",
              borderRadius: "8px",
            }}
          >
            high to low
          </Box>
        ) : (
          <></>
        )}

        {showmin || showmax ? (
          <Box
            onClick={(e) => {
              e.preventDefault();
              setshowmin(false);
              setshowmax(false);
              fetchproducts();
            }}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "10px",
              marginRight: "10px",
              marginLeft: "10px",
              padding: "10px 10px",
              backgroundColor: "#F0F1F2",
              height: "fit-content",
              width: "auto",
              borderRadius: "8px",
            }}
          >
            X
          </Box>
        ) : (
          <></>
        )}
      </Box> */}
      {/* <CategoryCarousel
        bybrand={getbybrand}
        categoryid={categoryId}
        subcatid={catid}
        key={categoryId}
      />{" "} */}
      {/* <Box sx={{ px: 1 }}>
        <FormGroup>
          <TextField
            size="small"
            placeholder="Search"
            onChange={(e) => setTempQuery(e.target.value)}
            onKeyDown={(e) =>
              e.key === "Enter" &&
              navigate("/search", { state: { navQueryState: tempQuery } })
            }
            sx={{
              backgroundColor: "#F0F1F2",
              borderRadius: "8px 8px 0px 0px",
              width: "100%",
              margin: "10px 20px 10px 0px",
              borderBottom: "1px solid #5EC401",
            }}
            InputProps={InputPropsStyle}
            InputLabelProps={{
              sx: {
                color: "#5EC401",
                textTransform: "capitalize",
              },
            }}
          />
        </FormGroup>
      </Box> */}
      {products.length === 0 ? (
        <ProductNotFound
          message={"No Products under selected Criteria"}
          loading={loading}
        />
      ) : (
        products &&
        products.map((currproduct, i) => {
          return currproduct.published &&
            currproduct.stocklimit &&
            currproduct.stocklimit < currproduct.stock ? (
            <CategoryProductCard
              prodid={currproduct._id}
              key={i}
              name={name}
              productDetails={currproduct}
            />
          ) : null;
        })
      )}
    </Box>
  );
};

export default RecentOrders;
