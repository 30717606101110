const AllOrders = (props) => {
  // const [active, setActive] = useState(0)
  const orders = props.orders;
  // console.log(orders);
  let d = 0; //delivered
  let c = 0; //cancelled
  let i = 0; //inprogress
  orders?.map((item) => {
    if (item.status == "delivered") d++;
    if (item.status == "completed") d++;

    if (item.status == "cancelled") c++;
    if (item.status == "inprogress") i++;
  });
  // setActive(c);
  return (
    <div className="abandoned-main">
      {/* <div className="abandoned-div"> */}
      {/* <img alt="cart-img" src={BagBlack} className="cart-image" /> */}
      {/* <select>
          <option>This Week</option>
          <option>This Month</option>
          <option>This Year</option>
        </select> */}
      {/* </div> */}
      <div className="abandoned-body">
        <div className="abandoned-body-item">
          <p className="abandoned-body-title">All {props.type}</p>
          <p className="cart-percentage">{orders?.length}</p>
        </div>
        <div className="abandoned-body-item">
          <p className="abandoned-body-title">Pending</p>
          <p className="cart-percentage">{orders?.length - (d + i + c)}</p>
        </div>
        <div className="abandoned-body-item">
          <p className="abandoned-body-title">In Progress</p>
          <p className="cart-percentage">{i}</p>
        </div>
        <div className="abandoned-body-item">
          <p className="abandoned-body-title">
            {props.type == "Orders" ? "Delivered" : "Completed"}
          </p>
          <p className="cart-percentage">
            {c}
            {/* <span>+0.00%</span> */}
          </p>
        </div>
        <div className="abandoned-body-item">
          <p className="abandoned-body-title">Cancelled</p>
          <p className="cart-percentage">{c}</p>
        </div>
      </div>
    </div>
  );
};

export default AllOrders;
