import CustomerAddress from "../Customers/Layout/CustomerAddress";
import CustomerDetail from "../Customers/Layout/CustomerDetail";
import AllOrders from "../Dashboard/Layout/AllOrders";
import TotalOrder from "../Inventory/Layout/TotalOrder";
// import CustomerViewTable from "../Customers/Layout/CustomerViewTable";
import DealerTable from "./Layout/DealerTable";
const ViewUserNetwork = () => {
  return (
    <div>
      <div>
        <div className="customer-order-main-div">
          <div className="customer-detail-card">
            <CustomerDetail />
          </div>
          <div className="customer-address-card">
            <CustomerAddress />
          </div>
          <div className="cutomer-order-card">
            <TotalOrder />
          </div>
        </div>
        <div className="customer-order-main-div">
          <div className="customer-detail-card">
            <AllOrders />
          </div>
        </div>
        <DealerTable />
      </div>
    </div>
  );
};

export default ViewUserNetwork;
