import { Box, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { ReactComponent as Star } from "../../../../assets/Star.svg";
import server from "../../../server";

const CardMain = {
  backgroundColor: "#fff",
  padding: "10px 20px",
  margin: "10px 0px",
};

const CardHeader = {
  display: "flex",
  borderBottom: "1px solid #EBEBEB",
  paddingBottom: "20px",
  paddingTop: "5px",
};

const userName = {
  fontWeight: "600",
  fontFamily: "quicksand",
  lineHeight: "25px",
};

const ReviewTime = {
  fontWeight: "400",
  lineHeight: "15px",
  fontSize: "12px",
  color: "#868889",
};

const CardBody = {
  display: "flex",
  padding: "20px 0px 10px 0px",
};

const ReviewText = {
  color: "#868889",
  fontSize: "14px",
  margin: "0px 0px 10px 0px",
};

const UserReviewCard = (props) => {
  const arr = Array(props.name.rating).fill(0);

  //states
  const [user, setUser] = useState([]);
  const [duration, setduration] = useState();
  const getusermakingreview = async () => {
    try {
      const userdata = await axios.get(`${server}/users/${props.name.userId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      // console.log(userdata)
      setduration(
        new Date().getDate() - new Date(props.name.createdAt).getDate(),
      );
      setUser(userdata.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // console.log(props.name)
    getusermakingreview();
  }, []);

  useEffect(() => {
    // console.log(props.name)
    // getusermakingreview()
  }, [user, duration]);

  return (
    <Box sx={CardMain}>
      <Box sx={CardHeader}>
        <img
          src={user.imageUrl}
          alt="UserImage"
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "80%",
          }}
        />
        <Box sx={{ margin: "0px 10px" }}>
          <Typography variant="h6" sx={userName}>
            {user.name}
          </Typography>
          <Typography sx={ReviewTime}>
            {duration > 0 ? <>{duration} days ago</> : <>Today</>}
          </Typography>
        </Box>
      </Box>
      <Box sx={CardBody}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography sx={{ color: "#000", fontSize: "15px" }}>
            {props.name.rating}
          </Typography>

          {arr.map((curr, i) => {
            return (
              <Star
                style={{
                  color: "#FFC107",
                  margin: "0px 0px 0px 10px",
                  width: "17px",
                  height: "17px",
                }}
              />
            );
          })}
        </Box>
      </Box>
      <Typography sx={ReviewText}>{props.name.description}</Typography>
    </Box>
  );
};

export default UserReviewCard;
