import {
  Box,
  Button,
  Container,
  InputAdornment,
  Rating,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import server from "../../server";

import EditIcon from "@mui/icons-material/Edit";
const RatingInput = {
  "& .MuiOutlinedInput-root": {
    backgroundColor: "white",
    borderRadius: "4px",
    "& textarea": {
      boxShadow: "none", // Removes the outer border
      padding: "10px",
    },
  },
  "& .MuiInputLabel-root": {
    color: "black",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "black",
  },
  "& .MuiInputAdornment-root": {
    position: "absolute",
    top: 10,
    right: 8,
    left: 6,
  },
};

const GenericFeedbackForm = ({ formConfig }) => {
  // Initialize state based on the form configuration
  const [feedbackData, setFeedbackData] = useState({
    rating: 0, // General rating, if needed
    comment: "",
    answers: {
      length: formConfig.fields.length,
      ...formConfig.fields.reduce((acc, field, index) => {
        acc[index] = {
          // Default rating values by type
          answer_text: field.type === "rating" ? 0 : "",
        };
        return acc;
      }, {}),
    },
  });

  // Handle Rating Change for the default overall rating
  const handleOverallRatingChange = (event, newValue) => {
    setFeedbackData((prevData) => ({
      ...prevData,
      rating: newValue,
    }));
  };

  // Handle Rating Change for rating fields
  const handleRatingChange = (index, newValue) => {
    setFeedbackData((prevData) => ({
      ...prevData,
      answers: {
        ...prevData.answers,
        [index]: {
          ...prevData.answers[index],
          answer_text: newValue, // Set the rating value
        },
      },
    }));
  };

  // Handle Text Change for text fields
  const handleTextChange = (index, event) => {
    setFeedbackData((prevData) => ({
      ...prevData,
      answers: {
        ...prevData.answers,
        [index]: {
          ...prevData.answers[index],
          answer_text: event.target.value,
        },
      },
    }));
  };

  // Handle Text Change for comments
  const handleCommentChange = (event) => {
    setFeedbackData((prevData) => ({
      ...prevData,
      comment: event.target.value,
    }));
  };

  const handleSubmit = () => {
    axios
      .post(
        `${server}/user/feedback`,
        {
          campaign_id: formConfig.campaign_id,
          rating: feedbackData.rating,
          comment: feedbackData.comment,
          answers: feedbackData.answers,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        },
      )
      .then((response) => {
        alert("Feedback submitted successfully!");
      })
      .catch((error) => {
        console.error("Error submitting feedback:", error);
      });
  };

  return (
    <Container maxWidth="md" sx={{ paddingBottom: 10 }}>
      <Box sx={{ textAlign: "center", padding: "20px" }}>
        <Typography variant="h4" gutterBottom>
          {formConfig.title}
        </Typography>

        <Box sx={{ marginY: "20px" }}>
          <Typography variant="h6" gutterBottom>
            Rate your overall experience by clicking one of the stars below
          </Typography>
          <Rating
            name="overallRating"
            value={feedbackData.rating}
            onChange={handleOverallRatingChange}
            size="large"
          />
        </Box>

        {formConfig.fields.map((field, index) => (
          <Box key={field.name} sx={{ marginY: "20px" }}>
            <Typography variant="h6" gutterBottom>
              {field.label}
            </Typography>
            {field.type === "rating" ? (
              <Rating
                name={field.name}
                value={feedbackData.answers[index]?.answer_text || 0}
                onChange={(event, newValue) =>
                  handleRatingChange(index, newValue)
                }
                size="large"
              />
            ) : (
              <TextField
                // label={field.label}
                multiline={field.multiline}
                rows={field.rows}
                variant="outlined"
                fullWidth
                value={feedbackData.answers[index]?.answer_text || ""}
                onChange={(event) => handleTextChange(index, event)}
                sx={RatingInput}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      sx={{ marginRight: "90%", marginY: "8px" }}
                    >
                      <EditIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </Box>
        ))}

        <Box sx={{ marginY: "20px" }}>
          <Typography variant="h6" gutterBottom>
            Additional feedback or comments:
          </Typography>
          <TextField
            // label="Additional feedback or comments"
            multiline
            rows={4}
            // variant="outlined"
            fullWidth
            value={feedbackData.comment}
            onChange={handleCommentChange}
            sx={RatingInput}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  sx={{ marginRight: "90%", marginTop: "8px" }}
                >
                  <EditIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          sx={{ textTransform: "none" }}
        >
          Submit
        </Button>
      </Box>
    </Container>
  );
};

export default GenericFeedbackForm;
