import { Backdrop, CircularProgress } from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import server from "../../server";
import DashboardContext from "../Context/DashboardContext";
import ImageUpload from "./Layout/ImageUpload";
import NewProductForm from "./Layout/NewProductForm";

const NewProduct = () => {
  const context = useContext(DashboardContext);
  const location = useLocation();
  const navigate = useNavigate();
  const data = location?.state?.data || {};
  // console.log(data);
  // console.log(data.id);
  const id = location.state?.id || -1;
  const isEdit = location?.state.isEdit;
  // console.log(data);
  const { setLoading, setGoBack } = context;
  const [newProduct, setNewProduct] = useState({
    name: data?.name || "",
    price: data?.price || "",
    mrpPrice: data?.mrpPrice || "",
    categoryId: data?.categoryId?._id || null,
    subcategoryId: data?.subCategoryId?._id || null,
    imageUrl: data?.imageUrl || [],
    published: data?.published || "",
    buyinglimit: data?.buyinglimit || 0,
    stocklimit: data?.stocklimit || 0,
    stock: data?.stock || 0,
  });
  // console.log(newProduct);
  const [add_data, setAdd_data] = useState({
    address: "",
    state: "",
    city: "",
    pincode: "",
  });

  const [newproductname, setname] = useState();
  const [newproductcategory, setcategory] = useState();
  const [newproductsubcategory, setsubcategory] = useState();
  const [newproductmrpPrice, setmrpPrice] = useState();
  const [newproductprice, setprice] = useState();
  const [newproductstock, setstock] = useState();
  const [newproductdesc, setdesc] = useState();
  const [newproductbuyinglimit, setbuyinglimit] = useState();
  const [newproductstocklimit, setstocklimit] = useState();

  const [categories, setCategories] = useState();
  const [published, setpublished] = useState(data?.published || false);
  const [catnames, setCatNames] = useState();
  const [subcatnames, setSubCatNames] = useState();
  const [subcategories, setSubCategories] = useState();
  const [newproductlabelcode, setlabelcode] = useState("");
  const [weight, setweight] = useState();
  const [barcodes, setBarcodes] = useState(data?.barcode); // Stores all entered barcodes
  const [fetchProductLoading, setFetchProductLoading] = useState(false);

  const getcatandsubcat = async () => {
    setGoBack(true);
    const result = await axios.get(`${server}/category`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    // console.log(result.data)
    setCategories(result.data);
    let arr = [];
    result.data.forEach((element, i) => {
      arr.push(element.name);
      if (i === result.data.length - 1) {
        // console.log(arr)
        setCatNames(arr);
      }
    });

    const subresult = await axios.get(`${server}/subcategories`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    // console.log(result.data)
    setSubCategories(subresult.data);
    let temparr = [];
    subresult.data.forEach((element, i) => {
      temparr.push(element.name);
      if (i === subresult.data.length - 1) {
        // console.log(arr)
        setSubCatNames(temparr);
      }
    });

    // console.log(data.imageUrl)
    // setImageFile(data.imageUrl)
    // if (data !== {}) {

    // }
  };

  const [add_id, setAdd_id] = useState(0);
  const [imageFile, setImageFile] = useState(data?.imageUrl || "noimage");
  const [fetchImageFile, setFetchImageFile] = useState("");

  const addProduct = async () => {
    const imageUrl = imageFile === "noimage" ? fetchImageFile : imageFile;
    //api
    const prod = await axios.post(
      `${server}/products/create`,
      {
        rwId: "1234",
        brandId: "6492a3fd5de817a989714919",
        categoryId: categories[newproductcategory]._id,
        subCategoryId: subcategories[newproductsubcategory]._id,
        name: newproductname,
        imageUrl: imageUrl,
        price: newproductprice,
        mrpPrice: newproductmrpPrice,
        stock: newproductstock,
        sales: 0,
        description: newproductdesc,
        buyinglimit: newproductbuyinglimit,
        stocklimit: newproductstocklimit,
        published: published,
        weight: weight,
        labelcode: newproductlabelcode,
        barcode: barcodes,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );

    navigate("/inventory");
  };

  const UpdateProduct = async () => {
    //api
    // alert(     imageFile
    //   )
    console.log(imageFile);
    console.log(newproductstocklimit);
    const imageUrl = imageFile === "noimage" ? fetchImageFile : imageFile;
    const prod = await axios.put(
      `${server}/products/update/${data._id}`,
      {
        // "name": newproductname,
        // "price": newproductprice,
        // "mrpPrice": newproductmrpPrice,
        // "categoryId": categories[newproductcategory]._id,
        published: published,
        // "subCategoryId": subcategories[newproductsubcategory]._id,
        imageUrl: imageUrl,
        // "stock": newproductstock,
        buyinglimit: newproductbuyinglimit,
        stocklimit: newproductstocklimit,
        barcode: barcodes,
        labelcode: newproductlabelcode,
        weight: weight,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );
    navigate("/inventory");
  };

  useEffect(() => {
    // console.log(published)
  }, [
    setname,
    setcategory,
    setsubcategory,
    setmrpPrice,
    setprice,
    setstock,
    setdesc,
    published,
  ]);

  useEffect(() => {
    getcatandsubcat();
  }, []);
  useEffect(() => {}, [categories, subcategories, catnames, subcatnames]);
  useEffect(() => {}, [imageFile]);

  const handleFetchImage = async () => {
    if (!isEdit) {
      try {
        setFetchProductLoading(true);
        const result = await axios.get(
          `${server}/product/admin/name/${newproductname}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          },
        );
        setFetchImageFile(result?.data?.imageUrl[0]);
        setFetchProductLoading(false);
      } catch (error) {
        toast.error(error);
        setFetchProductLoading(false);
      }
    } else {
      try {
        setFetchProductLoading(true);
        const result = await axios.get(
          `${server}/product/admin/name/${data?.name}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          },
        );
        setFetchImageFile(result?.data?.imageUrl[0]);
        setFetchProductLoading(false);
      } catch (error) {
        toast.error(error);
        setFetchProductLoading(false);
      }
    }
  };

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: "1201" }}
        open={fetchProductLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="newProduct-head">
        {isEdit && <p className="newProduct-title">Edit Inventory Item</p>}
        {!isEdit && (
          <p className="newProduct-title">New Product Registration</p>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button
            className="save-button"
            disabled={isEdit ? !data?.name || imageFile !== "noimage" : null}
            variant="contained"
            color="primary"
            sx={{ marginRight: "10px" }}
            onClick={(e) => {
              e.preventDefault();
              handleFetchImage();
            }}
          >
            Fetch Image
          </button>
          {isEdit && (
            <button className="save-button" onClick={UpdateProduct}>
              Update Product
            </button>
          )}
          {!isEdit && (
            <button
              className="save-button"
              onClick={(e) => {
                e.preventDefault();
                addProduct();
              }}
            >
              Save & Publish
            </button>
          )}
        </div>
      </div>
      <div className="new-product-flex">
        <NewProductForm
          newProduct={newProduct}
          oldProduct={data}
          setname={setname}
          newproductcategory={newproductcategory} //this is passed to make sub disabled when no cat selected
          setcategory={setcategory}
          setsubcategory={setsubcategory}
          setmrpPrice={setmrpPrice}
          setprice={setprice}
          setstock={setstock}
          setdesc={setdesc}
          setpublished={setpublished}
          setbuyinglimit={setbuyinglimit}
          setstocklimit={setstocklimit}
          setNewProduct={setNewProduct}
          isEdit={isEdit}
          add_data={add_data}
          setAdd_data={setAdd_data}
          addfunction={addProduct}
          setweight={setweight}
          setlabelcode={setlabelcode}
          barcodes={barcodes}
          setBarcodes={setBarcodes}
        />
        <ImageUpload
          newProduct={newProduct}
          setNewProduct={setNewProduct}
          setImageFile={(imgUrl) => {
            setImageFile(imgUrl);
            setFetchImageFile(null);
            imgUrl !== "noimage"
              ? toast.success("Image uploaded successfully")
              : toast.success("Image deleted successfully");
          }}
          imagesSave={
            fetchImageFile !== ""
              ? fetchImageFile
              : typeof newProduct?.imageUrl === "string"
                ? newProduct?.imageUrl
                : ""
          }
          isEdit={isEdit}
        />
      </div>
    </div>
  );
};

export default NewProduct;
