import { Box, Grid, Skeleton, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import server from "../../../server";
import CategoryCard from "./CategoryCard";
const ViewCategoryText = {
  padding: "10px",
  backgroundColor: "#fff",
  fontWeight: "600",
  fontFamily: "Quicksand",
  fontSize: "18px",
};

const StarDiv = {
  display: "flex",
  flexDirection: "row",
  // alignItems: "center",
  // margin: "5px 0px",
};

const StarDivText = {
  color: "#ADADAD",
  textAlign: "center",
  fontSize: "12px",
  display: "flex",
  // flexWrap: "wrap",
  // alignItems: "center",
  justifyContent: "space-between",
  margin: "2px",
};

const buttonProductCard = {
  color: "#fff",
  backgroundColor: "#5EC401",
  // display: "flex",
  // flexDirection: "column",
  // alignItems: "center",
  // justifyContent: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  maxWidth: "90%",
  height: "auto",
  padding: "10px 35px",
  positon: "absolute",
  bottom: "0",
  margin: "20px 5px",
  // marginTop: "100px",
  textTransform: "capitalize",
  borderRadius: "7px",
  "&.MuiButtonBase-root:hover": {
    background: "#64cf00",
  },
};
const priceText = {
  fontWeight: "600",
  color: "rgba(55, 71, 79, 0.54);",
  textDecoration: "line-through",
  margin: "0",
  fontSize: "12px",
  paddingLeft: "2vh",
  fontFamily: "Quicksand",
};

const salePriceText = {
  color: "#F37A20",
  fontWeight: "600",
  margin: "10",
  paddingLeft: "2vh",
  fontSize: "22px",
  fontFamily: "Quicksand",
};
const cardTitle = {
  color: "#37474F",
  fontSize: "16px",
  fontWeight: "500",
  width: "40%",
  fontFamily: "Quicksand",
  padding: "20px",
  display: "flex",
  flexWrap: "wrap",
};

const TrendingDiv = {
  // backgroundColor: "white",
  // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
  borderRadius: "5px",
  // minHeight: "40vh",
  // minHeight: "44vw",
  minWidth: "44vw",
  display: "flex",
  // alignItems: "space-between",
  justifyContent: "space-between",
  flexDirection: "column",
  padding: "-10px",
};

const salePercentage = {
  fontFamily: "quicksand",
  color: "#fff",
  backgroundColor: "#F37A20",
  borderRadius: "50%",
  position: "relative",
  zIndex: "1",
  padding: "0px 12px",
  fontSize: "14px",
  top: "0",
  left: "10px",
  width: "fit-content",
  height: "fit-content",
};

const categoryText = {
  textAlign: "center",
  color: "black",
  fontFamily: "Poppins",
  fontWeight: 500,
  fontSize: "13px",
  alignSelf: "center",
  lineHeight: 1.2,
  height: "45px",
};

const TrendingNowBox = {
  backgroundColor: "#F2F2F2",
  // flexGrow: 2,
  // width: "104%",
  display: "flex",
  flexDirection: "column",
  padding: 0,
  // paddingBottom: "10px",
  // height:"1%"
  boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.25)",
  borderRadius: "3px",
};

const TrendingNowBoxGrid = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  overflowX: "scroll",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  padding: "12px 0",
  paddingLeft: 2,

  // minHeight: "45vh",
};

const TrendingNow = ({ openSectionData, toLoad, setToLoad, index }) => {
  const navigate = useNavigate();
  const [trendingdetails, setTrendingDetails] = useState([]);
  const [stars, setStars] = useState(Array(5).fill(0));
  const [rating, setRating] = useState([]);
  // const [stars, setStars] = useState([])
  const arr = [
    "6492ba35b8e4c1e66bd58ff1",
    "64ab046dd3e515a3f41bb567",
    "64919111c55b9dcf28c39354",
  ];

  const getTrendingProducts = async () => {
    // const trendingresults = await axios.get(`${process.env.REACT_APP_BASEURL}/topreviewProducts`)
    setTrendingDetails(openSectionData);
  };

  const RedirecToOffer = async (currproduct) => {
    try {
      const result = await axios.get(`${server}/products/${currproduct}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      // console.log(result.data)
      //go to prod detail page
      navigate(`/productdetail/${result.data.product._id}`, {
        state: {
          name: result.data.product.categoryId.name,
          id: result.data.product.categoryId._id,
        },
      });
    } catch (error) {
      // console.log(error.data)
      // const result = await axios.get(`${}`)
      navigate(`/subcategory/${currproduct}`);
    }
  };

  const startLoad = () => {
    if (Boolean(trendingdetails?.length)) return;
    getTrendingProducts();
    !toLoad.includes(index + 1) &&
      setTimeout(() => setToLoad([...toLoad, index + 1]), 4000);
  };

  useEffect(() => {
    toLoad.includes(index) && !Boolean(trendingdetails?.length) && startLoad();
  }, [toLoad, openSectionData]);

  //   useEffect(() => {

  //     // getreviews()
  //   }, [openSectionData]);

  //   useEffect(() => {
  //     console.log("trendingdetails", trendingdetails);
  //   }, [trendingdetails]);

  return toLoad.includes(index - 1) && !toLoad.includes(index) ? (
    <>
      <Grid container spacing={2} sx={TrendingNowBoxGrid}>
        {[1, 2, 3].map((i) => (
          <Grid item key={i}>
            <Box sx={TrendingDiv}>
              <Skeleton
                style={{
                  width: "40vw",
                  height: "40vw",
                }}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  ) : (
    <Box sx={TrendingNowBox}>
      <Typography variant="subtitle1" sx={ViewCategoryText}>
        Trending Now
      </Typography>
      {!Boolean(trendingdetails?.length) ? null : (
        <Grid
          container
          spacing={2}
          sx={TrendingNowBoxGrid}
          // wrap="nowrap" // --> add this line to disable wrap
        >
          {/* //dont undo anymore */}
          {trendingdetails.map((currcategory, i) => (
            <Grid item key={i}>
              {/* {console.log(currcategory)} */}
              <Box
                sx={TrendingDiv}

                // onClick={(e) => {
                // 	e.preventDefault();
                // 	RedirecToOffer(currproduct);
                // }}
              >
                <CategoryCard
                  categoryname={currcategory.name}
                  categoryid={currcategory._id}
                  categoryimage={currcategory.imageUrl}
                  propid={currcategory.categoryId}
                />
              </Box>
              <Typography variant="subtitle1" sx={categoryText}>
                {currcategory.name}
              </Typography>
            </Grid>
          ))}

          {/* </Box> */}
        </Grid>
      )}
    </Box>
  );
};

export default TrendingNow;
